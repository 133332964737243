import AuthCodeForm from "./AuthCodeForm";
import SecretAndQrCode from "./SecretAndQrCode";
import React from 'react';

const EnableTotpForm = () => (
    <>
        <SecretAndQrCode />
        <AuthCodeForm />
    </>
);

export default EnableTotpForm;