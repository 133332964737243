/* eslint-disable no-underscore-dangle,no-plusplus,prefer-destructuring */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { push } from 'connected-react-router';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { constants, env } from '@config';
import { FormInput, Button, A, useTranslate } from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import { app, loginForm, auth } from '@redux';
import { PasswordInput } from './components';
import { CaptchaItem, TotpCodeField } from '@shared';
import { compose } from 'recompose';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
	input: {
		marginTop: '24px',
	},
	inputStyle: {
		border: '1px solid lightgray',
		height: '68px',
		'& input': {
			'&:-webkit-autofill': {
				transition: 'background-color 0.1s ease-in-out 0s',
				'-webkit-box-shadow': `0 0 0 1000px ${theme.palette.customColors.googleAutoInputFill} inset`,
				'-webkit-text-fill-color': theme.palette.text.primary,
			},
		},
	},
	forgotPassword: {
		...theme.customstyles.body,
		color: theme.palette.text.primary,
		textAlign: 'right',
		marginTop: '8px',
	},
	formError: {
		...theme.customstyles.body,
		color: theme.palette.common.white,
		backgroundColor: theme.palette.error.main,
		borderRadius: '4px',
		padding: '6px 18px',
		textAlign: 'left',
		marginTop: '15px',

	},
	formErrorShake: {
		'-webkit-animation': 'shake .5s linear',
	},
	'@keyframes shake': {
		'8%, 41%': {
			'-webkit-transform': 'translateX(-10px)',
		},
		'25%, 58%': {
			'-webkit-transform': 'translateX(10px)',
		},
		'75%': {
			'-webkit-transform': 'translateX(-5px)',
		},
		'92%': {
			'-webkit-transform': 'translateX(5px)',
		},
		'0%, 100%': {
			'-webkit-transform': 'translateX(0)',
		},
	},
	submitButton: {
		marginTop: '24px',
	},
	newAccount: {
		...theme.customstyles.body,
		color: theme.palette.text.primary,
		textAlign: 'center',
		marginTop: '40px',
		marginBottom: '40px',
	},
	loader: {
		color: theme.palette.primary.main,
	}
}));

const useActions = () => {
	const dispatch = useDispatch();
	return {
		setCurrentPage: page => dispatch(push(page)),
		checkAuth: () => dispatch(auth.actions.checkAuth.request()),
	};
};

const LoginFormLocal = (props) => {
	// const [autoFillsCount, setAutoFillsCount] = useState(0);
	const classes = useStyles(props);
	const actions = useActions();
	const language = useSelector(app.selectors.language);
	const isTryingToLogIn = useSelector(auth.selectors.isTryingToLogIn);
	const translate = useTranslate();
	// const autoFillCompleted = () => setAutoFillsCount(autoFillsCount + 1);

	useEffect(() => { actions.checkAuth() }, []);

	const {
		handleSubmit,
		pristine,
		invalid,
		error,
		submitting,
		captchaIsRequired,
		twoFactorIsRequired
	} = props;

	let anketaUrl = env.AnketaSiteUrl.endsWith('/') ? env.AnketaSiteUrl : `${env.AnketaSiteUrl}/`;
	if (env.allowedLangs) {
		anketaUrl += `${language}/`;
	}

	if (isTryingToLogIn)
	{
		return <CircularProgress className={classes.loader} size={58} />;
	}

	return (
		<form
			onSubmit={handleSubmit(loginForm.actions.login)}
			/* ref={this.formRef} */
			autoComplete="off"
			className={classNames({
				[classes.formErrorShake]: error,
			})}
		>
			<div className={classes.input}>
				<Field
					component={FormInput}
					name="username"
					autoComplete="off"
					placeholder={translate('login_form_login', 'Login')}
					className={classes.inputStyle}
					showLabel={false}
				/>
			</div>
			<div className={classes.input} >
				<Field
					component={PasswordInput}
					name="password"
					placeholder={translate('login_form_password', 'Password')}
					autoComplete="off"
					className={classes.inputStyle}
					showLabel={false}
				/>
			</div>
			{[constants.LIME_INT, constants.LIME_NSR, constants.LIME_LME, constants.FIRM_J2TX].some(x => x === env.firm) &&
				<div className={classes.forgotPassword}>
					<A href="#" onClick={() => actions.setCurrentPage('/reset')}><R id="forgot_password" defaultValue="Forgot password?" /></A>
				</div>
			}
			{captchaIsRequired && 
				<div className={classes.input} >
					<Field
						component={CaptchaItem}
						name="captcha"
						className={classes.inputStyle}
					/>
				</div>}
			{twoFactorIsRequired &&
				<div className={classes.input}>
					<p>
						<R id="shared_totp_input_title" defaultValue="Enter the 6-digit code from the code-generating app" />
					</p>
					<TotpCodeField
						name="securityCode"
						className={classes.inputStyle}
						showLabel={false}
					/>
				</div>}
			{!error?.length ||
				<div className={classes.formError}>
					{error.map((errorItem, index) => <div key={index}>{errorItem.message}</div>)}
				</div>
			}
			<div className={classes.submitButton}>
				<Button
					fullWidth
					type="submit"
					variant="contained"
					color="primary"
					disabled={pristine || invalid || submitting}
				>
					{submitting ?
						<CircularProgress
							style={{ color: '#ffffff' }}
							size={18}
						/>
						: <R id="login_form_sign_in" defaultValue="Sign In" />
					}
				</Button>
			</div>
			<div className={classes.newAccount}>
				<A target="_blank" href={anketaUrl}><R id="open_new_account" defaultValue="Open New Account" /></A>
			</div>
		</form>
	);
};

LoginFormLocal.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
};

const form = reduxForm({
	form: 'loginForm',
	validate: (values, { captchaIsRequired, twoFactorIsRequired }) => {
		const errors = {};
		if (!values.username) {
			errors.username = 'required';
		}
		if (!values.password) {
			errors.password = 'required';
		}
		if (captchaIsRequired && !values.captcha) {
			errors.captcha = 'required';
		}
		return errors;
	},
})(LoginFormLocal);

const mapStateToProps = state => ({
	captchaIsRequired: loginForm.selectors.captchaIsRequired(state),
	twoFactorIsRequired: loginForm.selectors.twoFactorIsRequired(state),
});

export default compose(
	connect(mapStateToProps, null),
)(form);


