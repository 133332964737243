import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import InputFormControl from './InputFormControl';

const styles = theme => ({
	root: {
		padding: '12px 24px 5px',
		backgroundColor: 'rgba(255,255,255,0.05)',
		borderRadius: '8px',
		color: theme.palette.text.primary,
		'& input::placeholder': {
			opacity: 0.75,
		},
		fontSize: theme.typography.pxToRem(18),
	},
});
class Input extends React.Component {
	render() {
		const {
			name,
			id,
			placeholder,
			fullWidth,
			error,
			additionalText,
			showLabel,
			classes,
			helpertext,
			validationMessageMode,
			InputLabelProps,
			...other
		} = this.props;
		return (
			<InputFormControl
				name={name}
				InputLabelProps={InputLabelProps}
				placeholder={placeholder}
				fullWidth={fullWidth}
				error={error}
				additionalText={additionalText}
				showLabel={showLabel}
				helpertext={helpertext}
				validationMessageMode={validationMessageMode}
			>

				<InputBase
					name={name}
					id={id}
					placeholder={showLabel ? '' : placeholder}
					classes={{ root: classes.root }}
					fullWidth={fullWidth}
					{...other}
				/>
			</InputFormControl>
		);
	}
}

Input.propTypes = {
	showLabel: PropTypes.bool,
	additionalText: PropTypes.string,
	/**
	 * If `true`, the input will be focused during the first mount.
	 */
	autoFocus: PropTypes.bool,
	/**
	 * Useful to extend the style applied to components.
	 */
	classes: PropTypes.object,
	/**
	 * The default input value.
	 */
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	/**
	 * If `true`, the input will be disabled.
	 */
	disabled: PropTypes.bool,
	/**
	 * End `InputAdornment` for this component.
	 */
	endAdornment: PropTypes.node,
	/**
	 * If `true`, the input will indicate an error.
	 */
	error: PropTypes.bool,
	/**
	 * If `true`, the input will take up the full width of its container.
	 */
	fullWidth: PropTypes.bool,
	/**
	 * Текст ошибки валидации, отображаемый при props.error == true
	 */
	helpertext: PropTypes.string,
	/**
	 * The id of the `input` element.
	 */
	id: PropTypes.string,
	/**
	 * The component used for the native input.
	 * Either a string to use a DOM element or a component.
	 */
	inputComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	/**
	 * Properties applied to the `input` element.
	 */
	inputProps: PropTypes.object,
	/**
	 * Use that property to pass a ref callback to the native input component.
	 */
	inputRef: PropTypes.func,
	/**
	 * If `true`, a textarea element will be rendered.
	 */
	multiline: PropTypes.bool,
	/**
	 * Name attribute of the `input` element.
	 */
	name: PropTypes.string,
	/**
	 * Callback fired when the value is changed.
	 */
	onChange: PropTypes.func,
	/**
	 * The short hint displayed in the input before the user enters a value.
	 */
	placeholder: PropTypes.string,
	/**
	 * Number of rows to display when multiline option is set to true.
	 */
	minRows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	/**
	 * Maximum number of rows to display when multiline option is set to true.
	 */
	maxRows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	/**
	 * Start `InputAdornment` for this component.
	 */
	startAdornment: PropTypes.node,
	/**
	 * Type of the input element. It should be a valid HTML5 input type.
	 */
	type: PropTypes.string,
	/**
	 * Разрешает выводить сообщение валидации.
	 */
	validationMessageMode: PropTypes.oneOf(['none', 'insteadOfAdditional', 'afterAdditional']),
	/**
	 * The input value, required for a controlled component.
	 */
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
	]),
	InputLabelProps: PropTypes.object,
};
Input.defaultProps = {
	showLabel: true,
	additionalText: undefined,
	autoFocus: false,
	classes: {},
	defaultValue: undefined,
	disabled: false,
	endAdornment: null,
	error: false,
	fullWidth: true,
	helpertext: undefined,
	id: null,
	inputComponent: 'input',
	inputProps: {},
	InputLabelProps: {},
	inputRef: null,
	multiline: false,
	name: null,
	onChange: null,
	placeholder: '',
	minRows: 4,
	maxRows: 10,
	startAdornment: null,
	type: 'text',
	validationMessageMode: 'none',
	value: undefined,
};

export default withStyles(styles)(Input);
