import React from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	reduxForm,
	Field,
} from 'redux-form';
import { withStyles, withTheme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';

import {
	FormSelect,
	FormInput,
	Divider,
} from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import { translate } from '@global';
import { userAccounts, app } from '@redux';
import { RequireTotpOrSmsOrderConfirmationCodeItem } from '@shared';
import { template } from '@templates';

const styles = theme => ({
	root: {
		position: 'relative',
	},
	row: {
		display: 'flex',
	},
	inputItem: {
		paddingLeft: '0px',
	},
	divider: {
		marginTop: '30px',
		marginBottom: '20px',
	},
	logo: {
		padding: '16px 16px 4px 16px',
		position: 'relative',
		width: '80px',
		height: '44px',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.customstyles.title,
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	button: {
		...theme.mixins.stickyButton,
	},
	inputSelectItem: {
		marginTop: '10px',
		paddingLeft: '24px',
	},
	preFieldLabel: {
		margin: '10px 24px 0px 24px',
		lineHeight: '21px',
		fontSize: '14px',
		color: theme.palette.text.secondary,
	},
	staticText: {
		margin: '10px 24px 10px 24px',
		lineHeight: '21px',
		fontSize: '14px',
		color: theme.palette.text.secondary,
	},
	fieldLeftAdditionalMargin: {
		marginLeft: '24px',
	},
});

const mapStateToProps = (state, ownProps) => {
	const currentTradeAccount = userAccounts.selectors.currentTradeAccount(state);
	const currentAccountDisplayFullName = userAccounts.selectors.currentAccountDisplayFullName(state);
	const language = app.selectors.language(state);

	return {	
		currentTradeAccount,
		currentAccountDisplayFullName,
		language,
	};
};
const getReasons = () => [
	{ value: 'MONEY:OUT:REASON:OUT_PROFIT', title: translate('orders_money_out_reason_out_profit', 'Withdrawal of profit') },
	{ value: 'MONEY:OUT:REASON:NEED_MONEY', title: translate('orders_money_out_reason_need_money', 'I need cash') },
	{ value: 'MONEY:OUT:REASON:NEED_LEARN', title: translate('orders_money_out_reason_need_learn', 'Not enough experience') },
	{ value: 'MONEY:OUT:REASON:OTHER_MARKET', title: translate('orders_money_out_reason_other_market', 'I am transferring money to another account with your company') },
	{ value: 'MONEY:OUT:REASON:OTHER_BROKER', title: translate('orders_money_out_reason_other_broker', 'I am leaving your company for another broker') },
	{ value: 'MONEY:OUT:REASON:BAD_SERVICE', title: translate('orders_money_out_reason_bad_service', 'Low quality services') },
	{ value: 'MONEY:OUT:REASON:FOREX_NOT_MINE', title: translate('orders_money_out_reason_not_mine', 'I\'m bad at Forex trading') },
];

class CYWithdrawalCryptoWalletConfirmForm extends React.Component {

	static validate(formvalues, props) {

		const values = formvalues;
		const errors = {};

		if (!values) {
			return errors;
		}

		if (values.NonOrderData) {
			errors.NonOrderData = {};
		}

		if (!values.NonOrderData.ConfirmationCode) {
			errors.NonOrderData.ConfirmationCode = 'required';
		}

		return errors;
	}

	render() {
		const {
			classes,
			handleSubmit,
			theme,
			currentTradeAccount,
			currentAccountDisplayFullName,
			values,
			invalid,
			submitting,
			...props
		} = this.props;


		return (
			<form
				onSubmit={handleSubmit}
				className={classes.root}
			>
				<div className={classes.accountContainer}>
					<div className={classes.row}>
						<div className={classes.logo}>
							<template.firmLogo />
						</div>
						<Field component={FormSelect} name="NonOrderData.TradeCode" label={translate('common_account', 'Account')} disabled>
							<MenuItem value={currentTradeAccount.tradeCode}>
								<div className={classes.inputContainer}>
									<div className={classes.account}>{currentAccountDisplayFullName}</div>
								</div>
							</MenuItem>
						</Field>
					</div>
				</div>
				<div className={classes.divider}>
					<Divider />
				</div>

				<div className={classes.inputItem}>
					<div className={classes.staticText}>
						<R id="orders_money_out_reason" defaultValue="Please, specify a reason of withdrawal" />
					</div>
				</div>
				<div className={classes.inputSelectItem}>
					<Field
						component={FormSelect}
						name="EMoneyOut.Reason"
						label={translate('orders_money_out_reason_title', 'The reason of withdrawal')}
					>
						{getReasons().map(x => (
							<MenuItem value={x.value} key={x.value}>
								{x.title}
							</MenuItem>
						))}
					</Field>
				</div>
				<div className={classes.inputItem}>
					<Field
						component={FormInput}
						name="EMoneyOut.ReasonComment"
						placeholder={translate('common_withdrawal_reason', 'Reason')}
						label={translate('common_withdrawal_reason', 'Reason')}
						multiline
					/>
				</div>

				<RequireTotpOrSmsOrderConfirmationCodeItem
					customClasses={classes}
					name="NonOrderData.ConfirmationCode"
					reason="NontradingOrderApply"
					submitDisabled={invalid}
					submitting={submitting}
				/>

			</form>
		);
	}
}

CYWithdrawalCryptoWalletConfirmForm.propTypes = {
	classes: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
	currentAccountDisplayFullName: PropTypes.string.isRequired,
};

export default compose(
	withStyles(styles),
	withTheme,
	connect(mapStateToProps, null),
	reduxForm({
		form: 'CYWithdrawalCryptoWalletConfirmForm',
		validate: CYWithdrawalCryptoWalletConfirmForm.validate,
		onSubmitSuccess: (result, dispatch, props) => {
			if (props.onSuccess && (typeof props.onSuccess === 'function')) {
				props.onSuccess();
			}
		},
	}),
)(CYWithdrawalCryptoWalletConfirmForm);
