import React from 'react';
import { Button, R } from '@dev2t/react-ui-kit/src';
import { ContentCloud, useTranslate } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { apiKeys } from '@redux';

const useStyles = makeStyles(theme => ({
    button: {
		flex: '1 0 80px',
		minWidth: '80px',
	},
}));

export default function Description() {
	const classes = useStyles();
    const dispatch = useDispatch();
	const apitokens = useSelector(apiKeys.selectors.tokens);
	const translate = useTranslate();

	return (
		<ContentCloud
			submitting={apitokens.submitting}
			id={'ApiKeysDescription'}
			header={translate('common_menu_api_management', 'API Management')}
		>
			<Box>
                <Box>
					<R
						id={"api_keys_description_1"}
						defaultValue={`1. Each account can create up to 5 API Keys. 
							The key will be generated in the form of a JWT token. 
							<a href="https://gitlab.com/just2trade/j2t-rest-api" target="_blank">API docs.</a>`}
						enableHtml
					/> 
				</Box>	
                <Box marginBottom='30px'>
                    <R
						id={"api_keys_description_2"}
						defaultValue="2. Do not disclose your API keys 
							to anyone to avoid loss of assets. 
							You should treat your API keys like your passwords."
						enableHtml
					/> 
				</Box>	
                <Button
					className={classes.button}
					color="primary"
					variant="outlined"
					size="small"
					disabled={!apitokens.isAbleToCreate}
					onClick={() => dispatch(apiKeys.actions.showCreateApiKeyModal())}
				>
					<R id="api_keys_create_api_key" defaultValue="Create key" />
                </Button>
			</Box>
		</ContentCloud>
	);
}
