import { AccountStatuses } from '@enums';
import { env, constants } from '@config';
import { getPlatformFullName } from '@utils';

const currentAccountIsDemo = state => state.userAccounts.currentAccountIsDemo;

const accounts = state =>
	state.userAccounts.accounts.map(acc => ({
		...acc,
		DisplayFullName: [constants.LIME_LME, constants.FIRM_J2TX].some(x => x === env.firm)
			? getPlatformFullName(acc.Platform) + ((acc.Login || '') && ` - ${acc.Login}`)
			: acc.TradeCode,
	}));

export default {
	accounts,
	platforms: state => state.userAccounts.accountInfo.value?.Platforms,
	tariffs: state => state.userAccounts.accountInfo.value?.Tariffs,
	accountInfo: state => ({
		loading: state.userAccounts.accountInfo.loading,
		fetchingAccountActivation: state.userAccounts.accountInfo.fetchingAccountActivation,
		Success: state.userAccounts.accountInfo.Success,
		...(state.userAccounts.accountInfo?.value || {}), // Для совместимости делаем плоским объект
	}),

	accountsInfo: state => state.userAccounts.allAccountsInfo?.AccountsInfo,
	accountsInfoLoading: state => state.userAccounts.allAccountsInfo?.loading,

	/**
	 * Признак, указывающий что выполняется загрузка информации о выбранном счете
	 */
	accountInfoLoading: state => state.userAccounts.submittingBaseData || Boolean(state.userAccounts.accountInfo.value?.loading),
	fundingInfo: state => state.userAccounts.accountInfo.value?.FundingInfo,
	// balance: state => state.userAccounts.accountInfo.value?.Balance,
	moneyInfo: state => state.userAccounts.accountInfo.value?.MoneyInfo,
	currentTradeAccount: state => ({
		tradeCode: state.userAccounts.currentAccount,
		clearerFirm: state.userAccounts.currentClearer,
	}),
	currentAccount: state => state.userAccounts.currentAccount,
	currentClearer: state => state.userAccounts.currentClearer,
	currentAccountDisplayFullName: state => {
		const acc = accounts(state).find(a => a.TradeCode === state.userAccounts.currentAccount);
		return (acc && acc.DisplayFullName) || state.userAccounts.currentAccount;
	},
	selectedAccount: state => accounts(state).find(a => a.TradeCode === state.userAccounts.currentAccount),
	currentAccountData: state => state.userAccounts.currentAccountData,
	currentAccountIsDemo,
	currentAccountStatus: state => state.userAccounts.accountInfo.value?.Status,
	reportsAndConfirmations: state => state.userAccounts.reportsAndConfirmations,
	linkedAccounts: state => state.userAccounts.linkedAccounts,
	linkedAccountsSubmitting: state => state.userAccounts.linkedAccountsSubmitting,
	hasMaxTemplatesCount: state => state.userAccounts.hasMaxTemplatesCount,
	/**
	 * Признак, указывающий что выполняется загрузка информации о списке счетов
	 */
	loading: state => state.userAccounts.submittingBaseData,
	cashActivities: state => state.userAccounts.cashActivities,
	nonTraidingActivities: state => state.userAccounts.nonTraidingActivities,
	charts: state => state.userAccounts.charts,
	userInfo: state => state.userAccounts.userInfo,
	getTradeSystemCredentialsRequest: state => state.userAccounts.tradeSystemCredentialsRequest,
	hasDemoAccount: state => state.userAccounts.accounts && state.userAccounts.accounts.some(a => a.IsDemo),
	getDemoAccount: state => state.userAccounts.accounts && state.userAccounts.accounts.find(a => a.IsDemo),
	anyActiveAccountExists: state => {
		const accs = accounts(state);
		return (
			accs && accs.some(acc => !acc.IsDemo && (acc.Status === AccountStatuses.ACTIVE || acc.Status === AccountStatuses.RESTRICTED))
		);
	},
	hasOpenAccount: state => state.userAccounts.accounts.some(acc => !acc.IsClosed),
	hasAccounts: state => state.userAccounts.accounts && state.userAccounts.accounts.length > 0,
	modals: state => state.userAccounts.modals,
	rates: state => state.userAccounts.rates,
	getLimeTraderAccountInfo: state => state.userAccounts.limeTraderAccountInfo,
};
