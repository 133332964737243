
function addItemToArray(arr, item) {
	if (!arr) {
		return [item];
	} else if (!arr.find(it => it === item)) {
		arr.push(item);
	}
	return arr;
}

function removeItemFromArray(arr, item) {
	return arr && arr.filter(it => it !== item);
}

function removeItemsFromArray(arr, items) {
	return arr && arr.filter(it => items.indexOf(it) < 0);
}


export function	addHashToUrl(location, hash) {
	const hashList = location.hash && location.hash.substr(1).split('/');
	return `${location.pathname}#${addItemToArray(hashList, hash).join('/')}`;
}
export function	changeHashInUrl(location, from, to) {
	let hashList = location.hash && location.hash.substr(1).split('/');
	hashList = removeItemFromArray(hashList, from);
	return `${location.pathname}#${addItemToArray(hashList, to).join('/')}`;
}
export function	removeHashFromUrl(location, hash) {
	const hashList = location.hash && location.hash.substr(1).split('/');
	return hashList ? `${location.pathname}#${removeItemFromArray(hashList, hash).join('/')}` : location.pathname;
}
export function	removeHashesFromUrl(location, hashes) {
	const hashList = location.hash && location.hash.substr(1).split('/');
	return hashList ? `${location.pathname}#${removeItemsFromArray(hashList, hashes).join('/')}` : location.pathname;
}
