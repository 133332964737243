
export default function getAmountRestrictions(destinationAccount) {
	
	if (!destinationAccount) return undefined;

	if (destinationAccount.IsMT5) {
		return {
			BTC: 0,
			ETH: 0,
			USDT: 0,
			LTC: 0,
			XRP: 0,
		}
	}
	else if (destinationAccount.IsWallet) {
		return {
			BTC: 0,
			ETH: 0,
			USDT: 0,
			LTC: 0,
			XRP: 0,
		}
	}
	else if (destinationAccount.IsStaking) {
		return {
			BTC: 0.001,
			ETH: 0.01,
			USDT: 10,
			LTC: 0.1,
			XRP: 10
		}
	}
	else {
		throw new Error('unknown account type')
	}
}
