import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { constants } from '@config';
import { snackbar, userAccounts } from '@redux';
import { connect } from 'react-redux';
import {
	reduxForm,
	Field,
	getFormMeta,
	getFormSyncErrors,
	reset,
} from 'redux-form';
import {
	Button,
	FormInputPassword,
} from '@components';

const styles = theme => ({
	root: {
		display: 'block',
		position: 'relative',
		marginBottom: '40px',
	},
	button: {
		paddingTop: '12px',
	},
	formValidationMessages: {
		color: theme.palette.error.main,
		marginTop: '12px',
	},
});

const CHANGE_MT5_PASSWORD_FORM = 'ChangeMt5PasswordForm';

const mapStateToProps = (state) => {
	const currentTradeAccount = userAccounts.selectors.currentTradeAccount(state);
	return {
		initialValues: {
			password: '',
			passwordConfirm: '',
			tradeCode: currentTradeAccount.tradeCode,
			clearerFirm: currentTradeAccount.clearerFirm,
		},
		fields: getFormMeta(CHANGE_MT5_PASSWORD_FORM)(state),
		errors: getFormSyncErrors(CHANGE_MT5_PASSWORD_FORM)(state),
	};
};
const mapDispatchToProps = dispatch => ({
	actions: {
		showMessage: text => dispatch(snackbar.actions.showMessage({
			type: 'success',
			text,
		})),
	},
});

class ChangeMt5PasswordForm extends React.Component {
	static validate(values) {
		const errors = {};
		if (!values.password) {
			errors.password = 'required';
		} else if (!constants.regExpMt5Password.test(values.password)) {
			errors.password = 'Does not meet requirements';
		}
		if (values.password !== values.passwordConfirm) {
			errors.passwordConfirm = 'required';
		}
		return errors;
	}
	touchedField = (field) => {
		const { fields } = this.props;
		const f = fields && fields[field];
		return !!(f && f.touched);
	}
	isValidField = (field) => {
		const { errors } = this.props;
		return !(errors && errors[field]);
	}

	render() {
		const {
			classes,
			handleSubmit,
			invalid,
			submitting,
			pristine,
		} = this.props;

		return (
			
			<form
				onSubmit={handleSubmit(userAccounts.actions.changeMt5Password)}
				className={classes.root}
				autoComplete="off"
			>
				<div className={classes.inputItem}>
					<Field
						component={FormInputPassword}
						name="password"
						placeholder="Password"
						inputProps={{ autoComplete: 'new-password' }}
					/>
				</div>
				<div className={classes.inputItem}>
					<Field
						component={FormInputPassword}
						name="passwordConfirm"
						placeholder="Confirm Password"
						inputProps={{ autoComplete: 'new-password' }}
					/>
				</div>
				{!pristine && invalid && this.touchedField('password') && !this.isValidField('password') &&
					<div className={classes.formValidationMessages}>
						Password must be at least 8 charachters long and contain each charachter type:
						<ul>
							<li>Uppercase letters: A-Z</li>
							<li>Lowercase letters: a-z</li>
							<li>Numbers: 0-9</li>
						</ul>
					</div>
				}
				<div className={classes.button}>
					<Button
						fullWidth
						type="submit"
						variant="contained"
						color="primary"
						disabled={invalid || pristine || submitting}
					>
						{submitting ?
							<CircularProgress
								
								size={18}
							/>
							: 'Change Password'
						}
					</Button>
				</div>
			</form>
		);
	}
}

ChangeMt5PasswordForm.propTypes = {
	classes: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	pristine: PropTypes.bool.isRequired,
	invalid: PropTypes.bool.isRequired,
	submitting: PropTypes.bool.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: CHANGE_MT5_PASSWORD_FORM,
		validate: ChangeMt5PasswordForm.validate,
		onSubmitSuccess: (result, dispatch, props) => {
			props.actions.showMessage('Mt5 password successfully changed');
			dispatch(reset(CHANGE_MT5_PASSWORD_FORM));
		},
	}),
)(ChangeMt5PasswordForm);
