import React from 'react';
import { useDispatch } from 'react-redux';
import { app, apiKeys } from '@redux';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
	ProgressContainer,
	SvgIcon
} from '@components';
import { R } from '@dev2t/react-ui-kit/src';
import { toUsaDateFormat } from '@global';

const styles = (theme) => ({
	noIcon: {
		display: 'inline-block',
		width: '48px',
	},
});
const useStyles = makeStyles(styles);

const useActions = () => {
	const dispatch = useDispatch();
	return ({
		getApiKeys: () => dispatch(apiKeys.actions.getApiKeys.request()),
		showConfirmModal: payload => dispatch(app.actions.showConfirmModal(payload)),
		revokeApiKey: payload => dispatch(apiKeys.actions.revokeApiKey.request(payload)),
		showCreateApiKeyModal: () => dispatch(apiKeys.actions.showCreateApiKeyModal()),
	});
};

const ApiKeysPanel = (props) => {
	const { apitokens } = props;
	const classes = useStyles();
	const actions = useActions();
	
	const showDeleteModal = apiKey => () => {
		actions.showConfirmModal({
			text: <R id="delete_api_key" defaultValue="Please click confirm if you want to delete API key"/>,
			action: onEndRequest => actions.revokeApiKey({
				keyId: apiKey.TokenId,
				onEndRequest,
			}),
		});
	};

	return (
		<ProgressContainer submitting={apitokens.submitting}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>
							<R id="api_keys_table_token_name"/>
						</TableCell>
						<TableCell>
							<R id="api_keys_table_created_at"/>
						</TableCell>
						<TableCell>
							<R id="api_keys_table_expiration_date"/>
						</TableCell>
						<TableCell>
							<R id="api_keys_table_trade_codes"/>
						</TableCell>
						<TableCell>
							<div className={classes.noIcon} />
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{apitokens.items.map(item => 
						<TableRow key={item.TokenId}>
							<TableCell>
								{item.TokenName}
							</TableCell>
							<TableCell>
								{toUsaDateFormat(item.CreatedDate)}
							</TableCell>
							<TableCell>
								{toUsaDateFormat(item.ExpirationDate)}
							</TableCell>
							<TableCell>
								{item.TradeCodes.replaceAll(',', ', ')}
							</TableCell>
							<TableCell>
								<IconButton onClick={showDeleteModal(item)}>
									<SvgIcon icon="Delete" />
								</IconButton>
							</TableCell>
						</TableRow>
					)}	
				</TableBody>
			</Table>
		</ProgressContainer>

	);
};

export default ApiKeysPanel;
