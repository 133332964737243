import React from 'react';
import { R } from '@dev2t/react-ui-kit/src/components';
import Typography from '@material-ui/core/Typography';

export default function Disclosures() {
	return (
		<Typography variant='h5'>
			<R id='common_menu_disclosures' defaultValue='Disclosures'></R>
		</Typography>
	);
}
