import { Button } from '@dev2t/react-ui-kit/src';
import { ExpansionPanel } from '@components';
import { env } from '@config';
import { ModalPopups } from '@enums';
import { translate } from '@global';
import { makeStyles } from '@material-ui/core/styles';
import { app, userAccounts } from '@redux';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles({
	button: {
		marginLeft: "20px"
	}
});

const TwoFactorCredentialsPanel = ({
	onToggle,
	panelId,
	isOpen,
	classes: customClasses,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const {
		Guid,
		DemoUserId,
		IsTotpEnabled,
		CanEnableTotp: userIsAlreadyLegitToEnableTwoFactor,
	} = useSelector(userAccounts.selectors.userInfo);

	const isDemo = !Guid && !!DemoUserId;
	const userCanChangeTwoFactor = (env.allowTwoFactorByTotp || IsTotpEnabled)
		&& !isDemo
		&& userIsAlreadyLegitToEnableTwoFactor;

	return !userCanChangeTwoFactor
		? null
		: (
			<ExpansionPanel
				header={translate('common_header_account_two_factor', 'Two-Factor Authentication')}
				id={panelId}
				onToggleOpen={onToggle}
				isOpen={isOpen}
				classes={{
					root: customClasses.expansionPanelRoot,
					content: customClasses.expansionPanelContent
				}}
			>
				<Button
					type="button"
					variant="outlined"
					color="primary"
					onClick={() => dispatch(app.actions.showBaseModal(ModalPopups.CHANGE_TWO_FACTOR_AUTHORIZATION))}
					className={classes.button}
				>
					{IsTotpEnabled
						? translate("shared_totp_disable_button", "Disable")
						: translate("shared_totp_enable_button", "Enable")}
				</Button>
			</ExpansionPanel>
		)
};

export default TwoFactorCredentialsPanel;