/* eslint-disable import/extensions */
import React from 'react';
import { auth, app } from '@redux';
import { connect } from 'react-redux';
import * as qs from 'query-string';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {
	A,
} from '@components';
import { constants, env } from '@config';
import { template } from '@templates';
import { AutoLogin, LoginForm, LoginFormLocal } from './components';
import { SelectLanguage, R } from '@dev2t/react-ui-kit/src/components';

const styles = theme => ({
	'@global': {
		body: {
			// backgroundColor: theme.palette.common.white,
		},
	},
	container: {
		textAlign: 'center',
		marginTop: '42px',
	},
	logo: {
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			width: '312px',
		},
		height: '100%',
		[theme.breakpoints.down('xs')]: {
			maxHeight: '150px',
		},
		color: theme.palette.text.primary,
		stroke: theme.palette.text.primary,
	},
	lmeLogo: {
		maxWidth: '280px',
	},
	j2txLogo: {
		maxWidth: '280px',
	},
	limeLogo: {
		marginBottom: '32px',
	},
	nsrLogo: {
	},
	text: {
		...theme.customstyles.headline2,
		color: theme.palette.text.primary,
	},
	languageSelector: {
		position: 'fixed',
		top: '10px',
		right: '10px',
	},
});

const mapDispatchToProps = dispatch => ({
	actions: {
		setAfterAuthUrl: payload => dispatch(auth.actions.setAfterAuthUrl(payload)),
		onChangeLanguage: lang => dispatch(app.actions.changeLanguage.request(lang)),
	},
});

const mapStateToProps = state => ({
	language: app.selectors.language(state),
});

class LoginView extends React.Component {
	componentDidMount() {
		const { actions, location } = this.props;

		actions.setAfterAuthUrl(location.pathname + location.hash);
	}

	render() {
		const {
			classes,
			location,
			actions,
			language,
		} = this.props;

		const parsedQueryString = qs.parse(location.search);

		return (
			<Grid container justifyContent="center" spacing={0} className={classes.container}>
				<Grid item xl={3} lg={4} md={5} sm={7} xs={11}>
					<div>
						{template.loginLogo &&
							<A target="_blank" href={env.brokerSiteUrl}>
								<img
									alt="Logo"
									className={classNames({
										[classes.logo]: true,
										[classes.nsrLogo]: env.firm === constants.LIME_NSR,
										[classes.limeLogo]: env.firm !== constants.LIME_NSR,
										[classes.lmeLogo]: env.firm === constants.LIME_LME,
										[classes.j2txLogo]: env.firm === constants.FIRM_J2TX,
									})}
									src={template.loginLogo}
								/>
							</A>
						}
					</div>

					<h2 className={classes.text}>
						<R id="my_account_sign_in" defaultValue="My Account Sign In" />
					</h2>
					{(parsedQueryString && parsedQueryString.autologin)
						? <AutoLogin autologin={parsedQueryString.autologin} />
						: (env.useLocalAuth ? <LoginFormLocal /> : <LoginForm />)
					}
					<SelectLanguage
						config={env.menuLangsConfig}
						currentLang={language}
						onChange={actions.onChangeLanguage}
						classes={{ root: classes.languageSelector }}
					/>
				</Grid>
			</Grid>
		);
	}
}

LoginView.propTypes = {
	classes: PropTypes.object,
	location: PropTypes.object.isRequired,
};

LoginView.defaultProps = {
	classes: {},
};

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(LoginView);
