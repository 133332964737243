import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { ModalPopups } from '@enums';
import { profile, userAccounts } from '@redux';
import { connect } from 'react-redux';
import {
	reduxForm,
	Field,
} from 'redux-form';
import {
	Button,
	FormInputPhoneIconNumber,
	SuccessMessage,
	Modal,
	ProgressContainer,
	FileInput,
	withTranslate,
} from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import { RequireTotpConfirmationCodeItem } from '@shared';

const styles = theme => ({
	root: {
		display: 'block',
		position: 'relative',
	},
	button: {
		paddingTop: '24px',
	},
	inputItem: {
		marginTop: '5px',
		paddingLeft: '50px',
	},
});

const mapStateToProps = (state) => {
	const userProfile = userAccounts.selectors.accountInfo(state);
	return {
		isTotpEnabled: userAccounts.selectors.userInfo(state).IsTotpEnabled,
		loading: userProfile.loading,
	};
};

class ChangeProfilePhoneModal extends React.Component {
	static validate(values) {
		const errors = {};

		if (!values.Value) {
			errors.Value = 'required';
		}

		return errors;
	}

	constructor(props) {
		super(props);
		this.onPhoneChange = this.onPhoneChange.bind(this);
	}

	onPhoneChange(match) {
		if (match) {
			this.setState({
				phoneState: match,
			});
		}
	}

	onFilesUpload = (field, files) => this.props.change(field, files);

	render() {
		const {
			classes,
			onClose,
			handleSubmit,
			invalid,
			submitSucceeded,
			translate,
			submitting,
			loading,
			isTotpEnabled,
		} = this.props;
		return (
			<Modal
				title={translate('account_add_phone', 'Add Phone')}
				onClose={onClose}
				className={`QA-${ModalPopups.CHANGE_PROFILE_PHONE}`}
			>
				{submitSucceeded && <SuccessMessage onClick={onClose} text={translate('request_add_phone', 'Your request to add phone is processed.')} />}
				{!submitSucceeded &&
				    <ProgressContainer submitting={loading} disableCheckChildren >
						<form
							onSubmit={handleSubmit(profile.actions.changeProfilePhoneFormSubmitHandler)}
							className={classes.root}
						>
							<div className={classes.inputItem}>
								<Field
									component={FormInputPhoneIconNumber}
									name="Value"
									placeholder="New phone"
									label="New phone"
									onPhoneChange={this.onPhoneChange}
								/>
							</div>
							<p>
					              <R id="please_upload_your_files" defaultValue="Please upload your ID document" />
							</p>
							<div className={classes.fileUploader}>
					          	<FileInput
						        onFilesUpdate={files => this.onFilesUpload('Attachments', files)}
					         	/>
							</div>
							{isTotpEnabled &&
								<div className={classes.inputItem}>
									<RequireTotpConfirmationCodeItem
										name="ConfirmationCode"
										disabled={submitting} />
								</div>}
							<div className={classes.button}>
								<Button
									fullWidth
									type="submit"
									variant="contained"
									color="primary"
									disabled={invalid || submitting}
								>
									{submitting ?						
							        <CircularProgress
								      color="inherit"
								     size={18}
							        />
							         : translate('account_add_phone', 'Add Phone')
						            }	
								</Button>
							</div>
						</form>
					</ProgressContainer>
				}
			</Modal>
		);
	}
}

ChangeProfilePhoneModal.propTypes = {
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	invalid: PropTypes.bool.isRequired,
	submitting: PropTypes.bool.isRequired,
	submitSucceeded: PropTypes.bool.isRequired,
};

export default compose(
	withStyles(styles),
	withTranslate,
	connect(mapStateToProps),
	reduxForm({
		form: 'ChangeProfilePhoneForm',
		validate: ChangeProfilePhoneModal.validate,
	}),
)(ChangeProfilePhoneModal);
