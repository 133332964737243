import React from 'react';

const Svg = (
	<svg viewBox="0 0 0.84 0.84" xmlns="http://www.w3.org/2000/svg">
		<g fill="none">
			<path fill="#23292F" d="M.84.42a.42.42 0 0 1-.42.42A.42.42 0 0 1 0 .42a.42.42 0 0 1 .84 0z" />
			<path d="M.606.21h.076L.524.366a.148.148 0 0 1-.207 0L.158.21h.076l.12.119a.093.093 0 0 0 .131 0L.606.21zM.233.645H.158L.317.488a.148.148 0 0 1 .207 0l.158.157H.606l-.12-.12a.093.093 0 0 0-.131 0l-.121.12z" fill="#FFF" />
		</g>
	</svg>
);


export default Svg;
