import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { translate } from '@global';
import { ModalPopups } from '@enums';
import { app, userAccounts } from '@redux';
import BankAccountItem from './BankAccountItem/BankAccountItem';

const BankAccountItemNigerianBankCY = () => {
	const currentTradeAccount = useSelector(userAccounts.selectors.currentTradeAccount);
	const dispatch = useDispatch();

	const showWithdrawFundsModal = bankAccount => {
		const userAccount = {
			tradeCode: currentTradeAccount.tradeCode,
			clearer: currentTradeAccount.clearerFirm,
		};
		dispatch(app.actions.showBaseModal(ModalPopups.CY_WITHDRAWAL_NIGERIAN_BANK, { userAccount, bankAccount }));
	};

	const handleWithdrawal = bankAccount => {
		showWithdrawFundsModal(bankAccount);
	};

	return (
		<BankAccountItem
			isLimeInternationalStyle
			bankAccount={{
				Type: 'NigerianBankWithdraw',
				displayNumber: translate('orders_selectmethod_nigerian_bank_title', 'Nigerian Bank Account'),
			}}
			hasDepositButton={() => false}
			hasWithdrawalButton={() => true}
			onWithdrawFundsModal={handleWithdrawal}
		/>
	);
};

export default BankAccountItemNigerianBankCY;
