import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ModalPopups } from '@enums';
import {
	Modal, SuccessMessage, ErrorMessage, ProgressContainer
} from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import { makeStyles } from '@material-ui/core/styles';
import { ordersCy, userAccounts } from '@redux';
import { useJ2txCommission } from '@hooks';
import { getUSDTProtocol } from '@global';
import { formValueSelector } from 'redux-form';
import {
	J2TXWithdrawalAmountForm,
	J2TXWithdrawalConfirmForm,
} from './components';

const useStyles = makeStyles(theme => ({
	modalContainer: {
		maxWidth: '550px',
	},
}));

function getTarget(currency) {
	switch (currency) {
		case 'USD':
			return 'USD';
		case 'EUR':
			return 'EUR';
		case 'BTC':
			return 'BITCOIN';
		case 'ETH':
			return 'ETHER';
		case 'USDT':
			return 'TETHER';
		case 'XRP':
			return 'RIPPLE';
		case 'LTC':
			return 'LITECOIN';
		default:
			throw new Error(`impossible currency for j2tx wallet withdraw: ${currency}`);
	}
}

const J2TXWalletWithdrawalModal = ({ onClose }) => {
	const [step, setStep] = useState('amountform');
	const loading = useSelector(state => userAccounts.selectors.loading(state) || userAccounts.selectors.accountInfo(state).loading);
	const classes = useStyles();

	// commission for form validation
	const EMoneyOut = useSelector(state => formValueSelector(ModalPopups.J2TX_WALLET_WITHDRAWAL)(state, 'EMoneyOut'));
	const commission = useJ2txCommission(EMoneyOut?.AccountCurrency, getUSDTProtocol(EMoneyOut?.TargetAccount));

	// provide initial values into the form
	const { tradeCode, currencyCode } = useSelector(userAccounts.selectors.modals)[ModalPopups.J2TX_WALLET_WITHDRAWAL];

	const clearerFirm = 'J2TX';

	const platform = useSelector(userAccounts.selectors.accounts)
		.find(x => x.TradeCode === tradeCode && x.ClearingFirm === clearerFirm)
		.Platform;

	const initialValues = {
		NonOrderData: {
			ConfirmationCode: null,
			TradeCode: tradeCode,
			ClearingFirm: clearerFirm,
		},
		EMoneyOut: {
			SelectedAccount: `${tradeCode};${platform}`,
			IsAllOut: false,
			IsCrypto: true,
			Urgently: false,
			Summ: 0,
			Currency: currencyCode,
			AccountCurrency: currencyCode,
			Comment: '',
			Reason: 'MONEY:OUT:REASON:NEED_MONEY',
			ReasonComment: '',
			SelectTarget: getTarget(currencyCode),
			TargetAccount: '',
		},
	};

	const handleGotoPage = page => (result, dispatch, props) => setStep(page);
	return (
		<Modal
			title={<R id="shared_withdrawal_funds" defaultValue="Withdrawal Funds" />}
			onClose={onClose}
			classes={{ container: classes.modalContainer }}
		>
			<ProgressContainer submitting={loading}>
				<div>
					{step === 'succeeded' && (
						<SuccessMessage
							onClick={onClose}
							text={<R id="common_crypto_wallet_completed" defaultValue="Withdrawal order completed" />}
						/>
					)}
					{step === 'failed'
						&& (
							<ErrorMessage
								onClick={onClose}
								text={<R id="common_crypto_wallet_failed" defaultValue="Could not complete order" />}
							/>
						)}
					{step === 'amountform'
						&& (
							<J2TXWithdrawalAmountForm
								initialValues={initialValues}
								destroyOnUnmount={false}
								commission={commission}
								// forceUnregisterOnUnmount
								form={ModalPopups.J2TX_WALLET_WITHDRAWAL}
								// validate={amountFormValidate}
								enableReinitialize
								onSubmit={handleGotoPage('confirmform')}
								onSuccess={() => setStep('confirmform')}
								onFail={() => setStep('failed')}
							/>
						)}
					{step === 'confirmform'
						&& (
							<J2TXWithdrawalConfirmForm
							// enableReinitialize
							// forceUnregisterOnUnmount
								form={ModalPopups.J2TX_WALLET_WITHDRAWAL}
								onSubmit={ordersCy.actions.cyWithdrawalEmoneyForm}
								onSuccess={() => setStep('succeeded')}
								onFail={() => setStep('failed')}
							/>
						)}
				</div>
			</ProgressContainer>
		</Modal>
	);
};

export default J2TXWalletWithdrawalModal;
