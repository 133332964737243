import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import {
	ProgressContainer,
	ShowMore,
} from '@components';
import { snackbar, reports } from '@redux';
import { CYReportsTable } from './CYReportsTable';

const useStyles = makeStyles(theme => ({
	loader: {
		color: theme.palette.primary.main,
	},
}));

const CYReportsPagingTable = (props) => {
	const { tradeCode } = props;
	const classes = useStyles();

	const [reportsCount, setReportsCount] = useState(5);

	const accountReports = useSelector(reports.selectors.reports);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(reports.actions.getBrokerReports.request({ tradeCode }));
	}, [tradeCode, dispatch]);

	const showError = message => dispatch(snackbar.actions.showErrorMessage({ text: message }));

	const pageSize = 5;

	const { Items, submitting } = accountReports;


	return (
		<ProgressContainer classes={{ loader: classes.loader }} submitting={submitting && accountReports.tradeCode !== tradeCode} display="left" id="reports-progress">
			{Items &&
				<Grid container>
					<Grid item lg={12} md={12} sm={12} xs={12}>
						<div className={classes.table}>
							<CYReportsTable
								items={Items.slice(0, reportsCount)}
								submitting={submitting}
								showError={showError}
							/>
						</div>
						<ShowMore
							submitting={submitting && Items && Items.length > 0}
							onClick={() => setReportsCount(reportsCount + pageSize)}
							showMoreButton={Items.length > reportsCount}
						/>
					</Grid>
				</Grid>
			}
		</ProgressContainer>
	);
};

CYReportsPagingTable.propTypes = {
	/**
	 * Код текущего счета. Он требуется поскольку могут быть уже запущенные на формирование отчеты,
	 *  и нам нужно отобржать только то, что соответствует текущему выбранному счету
	 */
	tradeCode: PropTypes.string.isRequired,
};
export default CYReportsPagingTable;
