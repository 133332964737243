import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { dashboard, profile, userAccounts } from '@redux';
import { scrollTo } from '@global';

const styles = theme => ({
	root: {},
	listItemText: {
		...theme.customstyles.body,
		color: theme.palette.text.primary,
	},
});

const mapStateToProps = state => ({
	tariffs: userAccounts.selectors.tariffs(state),
	profile: profile.selectors.profile(state),
});
 
const mapDispatchToProps = dispatch => ({
	actions: {
		sidebarClose: () => dispatch(dashboard.actions.sidebarClose())
	},
});

class DocumentsQuickLinks extends React.Component {
	scrollTo = (elementId) => {
		scrollTo(elementId);
		this.props.actions.sidebarClose();
	}

	render() {
		const {
			classes,
			profile: {
				submitting,
			},
		} = this.props;
		return (
			<div>
				<List>
					{!submitting && DocTaxFoms && DocTaxFoms.length > 0 &&
						<ListItem button onClick={() => this.scrollTo('tax-forms')}>
							<ListItemText
								className={classes.listItemText}
								primary="Tax Forms"
								disableTypography
							/>
						</ListItem>
					}
					<ListItem button onClick={() => this.scrollTo('profile-documents')}>
						<ListItemText
							className={classes.listItemText}
							primary="Documents"
							disableTypography
						/>
					</ListItem>
					<ListItem button onClick={() => this.scrollTo('disclosures')}>
						<ListItemText
							className={classes.listItemText}
							primary="Disclosures"
							disableTypography
						/>
					</ListItem>
				</List>
			</div>
		);
	}
}

DocumentsQuickLinks.propTypes = {
	classes: PropTypes.object.isRequired,
	profile: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(DocumentsQuickLinks);
