import {env} from '@config';

// locales for dates and ints in app
// Выбираем по одному, чтобы в сборку попали только нужные данные, а не всё вместе
import intlEn from 'react-intl/locale-data/en'; // Английский
import intlEs from 'react-intl/locale-data/es'; // Испанский
import intlMs from 'react-intl/locale-data/ms'; // Малазийский
import intlPl from 'react-intl/locale-data/pl'; // Польский
import intlRu from 'react-intl/locale-data/ru'; // Русский
import intlUk from 'react-intl/locale-data/uk'; // Украинский
import intlZh from 'react-intl/locale-data/zh'; // Китайский
import intlKo from 'react-intl/locale-data/ko'; // Корейский
import intlHi from 'react-intl/locale-data/hi'; // Хинди
import intlBg from 'react-intl/locale-data/bg'; // Болгарский
import intlEl from 'react-intl/locale-data/el'; // Греческий
import intlPt from 'react-intl/locale-data/pt'; // Португальский
import intlId from 'react-intl/locale-data/id'; // Индонезийский
import intlVi from 'react-intl/locale-data/vi'; // Вьетнамский
import intlIt from 'react-intl/locale-data/it'; // Итальянский
import intlTr from 'react-intl/locale-data/tr'; // Турецкий
import intlRo from 'react-intl/locale-data/ro'; // Румынский
import intlFr from 'react-intl/locale-data/fr'; // Французский
import intlDe from 'react-intl/locale-data/de'; // Немецкий

const allDicWordLanguages = [
	{
		language: 'en',
		displayName: 'English',
		dicWordKey: 'en_lang',
		intlLocale: intlEn,
		sharedTranslate: {},
	},
	{
		language: 'ru',
		displayName: 'Русский',
		dicWordKey: 'ru_lang',
		intlLocale: intlRu,
		sharedTranslate: {},
	},
	{
		language: 'zh',
		displayName: '中文',
		dicWordKey: 'zh_lang',
		intlLocale: intlZh,
		sharedTranslate: {},
	},
	{
		language: 'es',
		displayName: 'Español',
		dicWordKey: 'es_lang',
		intlLocale: intlEs,
		sharedTranslate: {},
	},
	{
		language: 'pl',
		displayName: 'Polski',
		dicWordKey: 'pl_lang',
		intlLocale: intlPl,
		sharedTranslate: {},
	},
	{
		language: 'uk',
		displayName: 'Українська',
		intlLocale: intlUk,
		dicWordKey: 'uk_lang',
	},
	{
		language: 'ms',
		displayName: 'Malay',
		intlLocale: intlMs,
		dicWordKey: 'ms_lang',
	},
	{
		language: 'ko',
		displayName: '한국어',
		dicWordKey: 'ko_lang',
		intlLocale: intlKo,
	},
	{
		language: 'hi',
		displayName: 'हिंदी',
		dicWordKey: 'hi_lang',
		intlLocale: intlHi,
	},
	{
		language: 'bg',
		displayName: 'Български',
		dicWordKey: 'bg_lang',
		intlLocale: intlBg,
	},
	{
		language: 'el',
		displayName: 'Ελληνικά',
		dicWordKey: 'el_lang',
		intlLocale: intlEl,
	},
	{
		language: 'pt',
		displayName: 'Português',
		dicWordKey: 'pt_lang',
		intlLocale: intlPt
	},
	{
		language: 'id',
		displayName: 'Indonesia',
		dicWordKey: 'id_lang',
		intlLocale: intlId
	},
	{
		language: 'vi',
		displayName: 'Tiếng Việt',
		dicWordKey: 'vi_lang',
		intlLocale: intlVi
	},
	{
		language: 'it',
		displayName: 'Italiano',
		dicWordKey: 'it_lang',
		intlLocale: intlIt
	},
	{
	  language: 'tr',
	  displayName: 'Türkiye',
	  dicWordKey: 'tr_lang',
	  intlLocale: intlTr
  	},
	{
		language: 'ro',
		displayName: 'Română',
		dicWordKey: 'ro_lang',
		intlLocale: intlRo
	},
	{
		language: 'fr',
		displayName: 'Français',
		dicWordKey: 'fr_lang',
		intlLocale: intlFr
	},
	{
		language: 'de',
		displayName: 'Deutsch',
		dicWordKey: 'de_lang',
		intlLocale: intlDe
	},
];

export const getAllowedLanguages = () => {
	if (env.allowedLangs) {
		return allDicWordLanguages.filter(x => env.allowedLangs.indexOf(x.language) >= 0);
	}
	return allDicWordLanguages.filter(x => x.language === 'en');
};

// // set allowed languages
export const setAlowedLanguages = (langs) => {
	return langs && langs.length ?
		getAllowedLanguages().filter(item => langs.some(x => x === item.language)) :
		getAllowedLanguages();
};

