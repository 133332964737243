import React from 'react';
import {
	withStyles,
	withTheme,
} from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { userAccounts } from '@redux';
import { Button, BankLogo, IconButton, SvgIcon } from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import { env } from '@config';
import { toUsaMoneyFormat, isNumber } from '@global';
import classNames from 'classnames';
import LinkBankAccountButton from '../ConnectBankAccountItem/LinkBankAccountButon';
import BankAccountItemContent from './components/BankAccountItemContent';
// import { DropDownMenu } from './components';

const styles = theme => ({
	header: {
		display: 'flex',
		flexWrap: 'nowrap',
		alignItems: 'center',
	},
	headerContent: {
		display: 'flex',
		flexGrow: 1,
		alignItems: 'center',
		gap: '10px',
		flexWrap: 'wrap',
	},
	linkType: {
		marginRight: '16px',
	},
	linkTypeWide: { width: '56px' },
	bankLogo: {
		marginRight: '16px',
	},
	leftColumn: {
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center',
		justifyContent: 'center',
		minWidth: '200px',
	},
	middleColumn: {
		marginRight: '16px',
		flexGrow: 1,
		display: 'flex',
		justifyContent: 'flex-end',
	},
	rightColumn: {
		display: 'flex',
		gap: '10px',
		flexShrink: 0,
	},
	noIcon: {
		display: 'inline-block',
		width: '48px',
	},
	balance: {
		...theme.customstyles.title,
		color: theme.palette.text.primary,
	},
	title: {
		...theme.customstyles.body,
		color: theme.palette.text.primary,
	},
	displayNumber: {
		...theme.customstyles.body,
		color: theme.palette.text.secondary,
	},
	linkAccountButton: {
		backgroundColor: 'lime',
		width: '120px',
	},
	textButtonContainer: {
		display: 'flex',
		width: '250px',
		minWidth: '250px',
		justifyContent: 'center',
		'& .MuiButton-root': {
			flex: '1 0 125px',
		},
	},
	arrowIcon: {
		stroke: theme.palette.text.secondary,
	},
	content: {
		...theme.customstyles.body,
		transition: 'all 0.5s',
		marginTop: '12px',
		marginLeft: '24px',
		marginRight: '-24px',
		[theme.breakpoints.up('sm')]: {
			marginLeft: '-24px',
		},
		'@media (min-width: 760px)': {
			marginLeft: '24px',
		},
		[theme.breakpoints.up('md')]: {
			marginLeft: '-24px',
		},
		'@media (min-width: 1400px)': {
			marginLeft: '80px',
		},
	},
	opened: {
		display: 'block',
	},
	closed: {
		display: 'none',
	},
});

const checkEnableWallet = (userInfo) => {
	const userGuid = userInfo.Guid && userInfo.Guid.toUpperCase();
	return userGuid && env.WEBCAB_5004_walletEnableUsers.includes(userGuid);
};

const mapStateToProps = state => ({
	currentAccountStatus: userAccounts.selectors.currentAccountStatus(state),
	isEnableWallet: checkEnableWallet(userAccounts.selectors.userInfo(state)),
});

const mapDispatchToProps = dispatch => ({
	actions: {
	},
});

class BankAccountItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
		};
	}

	gaDepositUrl = () => {
		if (this.props.bankAccount.Type) {
			return `/deposit/${this.props.bankAccount.Type.toLowerCase()}/`;
		}
		return null;
	}

	gaWithdrawalUrl = () => {
		if (this.props.bankAccount.Type) {
			return `/withdrawal/${this.props.bankAccount.Type.toLowerCase()}/`;
		}
		return null;
	}

	toggleOpen = () => {
		const status = !this.state.open;
		this.setState({
			open: status,
		});
	}

	render() {
		const {
			classes,
			bankAccount,
			currentAccountStatus,
			hasDepositButton,
			hasWithdrawalButton,
			withdrawalButtonText,
			isEnableWallet,
			isLimeInternationalStyle,
			onDepositFundsModal,
			onWithdrawFundsModal,
			onUnlinkAccount,
		} = this.props;
		const opened = !!this.state.open;
		const contentCssClass = classNames({
			[classes.content]: true,
			[classes.opened]: opened,
			[classes.closed]: !opened,
		});
		return (
			<>
				<div className={classes.header}>
					<div className={classes.headerContent}>
						<Box display="flex" flexGrow={1}>
							{!isLimeInternationalStyle && (
								<div
									className={classNames({
										[classes.linkType]: true,
										[classes.linkTypeWide]: isEnableWallet,
									})}
								>
									{bankAccount.Type}
								</div>
							)}
							<div className={classes.bankLogo}>
								{isLimeInternationalStyle
									? <BankLogo bankAccount={bankAccount} imgRelativePath="/static/paySystemLogos" />
									: <BankLogo bankAccount={bankAccount} />}
							</div>
							<div className={classes.leftColumn}>
								{bankAccount.BankName && (
									<div className={classes.title}>
										{bankAccount.BankName}
									</div>
								)}
								{isNumber(bankAccount.Balance) && (
									<Hidden mdUp implementation="js">
										<div className={classes.balance}>
											{toUsaMoneyFormat(bankAccount.Balance)}
										</div>
									</Hidden>
								)}
								{bankAccount.Code ? (
									<div className={classes.displayNumber}>
										●●●●&nbsp;
										{bankAccount.displayNumber}
									</div>
								) : (
									<>
										{isLimeInternationalStyle ? (
											<div className={classes.displayNumber}>
												{bankAccount.displayNumber}
											</div>
										) : (
											<div className={classes.displayNumber}>
												BankAccount is not linked
											</div>
										)}
									</>
								)}
								{bankAccount.Code && bankAccount.HavePendingWithdrawOrder && (
									<div className={classes.displayNumber}>
										Request pending approval
									</div>
								)}
							</div>
							<div className={classes.middleColumn}>
								{isNumber(bankAccount.Balance) && (
									<Hidden smDown implementation="js">
										<div className={classes.balance}>
											{toUsaMoneyFormat(bankAccount.Balance)}
										</div>
									</Hidden>
								)}
							</div>
						</Box>
						<div className={classes.rightColumn}>
							{!bankAccount.Code && (
								(currentAccountStatus.CanFund || currentAccountStatus.CanWithdrawal)
									&& bankAccount.Type === 'ACH' && currentAccountStatus.CanAchLink && (
									<LinkBankAccountButton
										bankAccount={bankAccount}
										classes={classes.linkAccountButton}
									/>
								)
							)}
							{hasDepositButton(bankAccount) && (
								<Button
									gaUrl={this.gaDepositUrl()}
									onClick={() => onDepositFundsModal(bankAccount)}
									variant="outlined"
									color="primary"
									size="small"
								>
									Deposit
								</Button>
							)}
							{hasWithdrawalButton(bankAccount) && (
								<Button
									gaUrl={this.gaWithdrawalUrl()}
									onClick={() => onWithdrawFundsModal(bankAccount)}
									variant="outlined"
									color="primary"
									size="small"
									style={{ minWidth: '93px' }}
									disabled={!currentAccountStatus.IsAllowedOut}
								>
									{withdrawalButtonText ?? (
										<R id="shared_withdraw" defaultValue="Withdraw" />
									)}
								</Button>
							)}
						</div>
					</div>

					<Box display="flex" flexWrap="nowrap">
						{bankAccount.CanUnlink && (
							<IconButton onClick={() => onUnlinkAccount(bankAccount)}>
								<SvgIcon icon="Delete" />
							</IconButton>
						)}
						{!bankAccount.CanUnlink && <div className={classes.noIcon} />}
						{bankAccount.Code ? (
							<IconButton onClick={this.toggleOpen}>
								{opened
									? <SvgIcon className={classes.arrowIcon} icon="ArrowUp" />
									: <SvgIcon className={classes.arrowIcon} icon="ArrowDown" />}
							</IconButton>
						) : <div className={classes.noIcon} />}
					</Box>
				</div>
				{bankAccount.Code && opened && (
					<div className={contentCssClass}>
						<BankAccountItemContent linkedaccount={bankAccount} />
					</div>
				)}
			</>
		);
	}
}

BankAccountItem.propTypes = {
	actions: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	bankAccount: PropTypes.object.isRequired,
	currentAccountStatus: PropTypes.object.isRequired,
	isEnableWallet: PropTypes.bool.isRequired,
};

export default compose(
	withStyles(styles),
	withTheme,
	connect(mapStateToProps, mapDispatchToProps),
)(BankAccountItem);
