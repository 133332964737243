import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ModalPopups } from '@enums';
import { linkedAccounts, userAccounts } from '@redux';
import {
	reduxForm,
	Field,
} from 'redux-form';
import {
	Modal,
	FormRadioGroup,
	Radio,
	Button,
	ErrorMessage,
} from '@components';
import { PlaidAccountRadioItem } from './components';


const styles = theme => ({
	formroot: {
		display: 'block',
		position: 'relative',
	},
	progress: {
		display: 'flex',
		justifyContent: 'center',
	},
	accItem: {
		width: '100%',
		margin: '12px',
	},
	item: {
		borderBottom: '1px solid #deeaef',
		'&:hover': {
			backgroundColor: theme.palette.customColors.hover,
		},
	},
	button: {
		...theme.mixins.stickyButton,
	},
	loaderRoot: {
		color: theme.palette.primary.main,
	},
});


const mapStateToProps = state => ({
	initialValues: {
		TradeCode: userAccounts.selectors.currentAccount(state),
	},
	bankAccounts: linkedAccounts.selectors.bankAccounts(state),
});

const mapDispatchToProps = dispatch => ({
	actions: {},
});


class PlaidSelectAccountModal extends React.Component {
	static validate(values) {
		const errors = {};

		if (!values.LinkAccountNo) {
			errors.LinkAccountNo = 'required';
		}

		return errors;
	}

	render() {
		const {
			classes,
			onClose,
			handleSubmit,
			submitting,
			invalid,
			bankAccounts,
		} = this.props;

		return (
			<Modal
				title="Select Account to Link"
				onClose={onClose}
				bgColor="#f9fcfd"
				textColor="#345464"
				className={`QA-${ModalPopups.PLAID_SELECT_ACCOUNT}`}
			>
				{bankAccounts && bankAccounts.loading &&
					<div className={classes.progress}>
						<CircularProgress
							classes={{ root: classes.loaderRoot }}
							size={58}
						/>
					</div>
				}
				{bankAccounts && !bankAccounts.loading && bankAccounts.error &&
					<ErrorMessage
						onClick={onClose}
						text={bankAccounts.error}
						buttonText="Return"
					/>
				}
				{bankAccounts && !bankAccounts.loading && !bankAccounts.error &&
					<form
						onSubmit={handleSubmit(linkedAccounts.actions.plaidSelectAccountFormSubmitHandler)}
						className={classes.formroot}
					>
						<div className={classes.root}>
							<Field
								fullWidth
								name="LinkAccountNo"
								orientation="vertical"
								component={FormRadioGroup}
							>
								{bankAccounts.accounts.map(item => (
									<Radio
										classes={{ label: classes.accItem, root: classes.item }}
										key={item.Id}
										value={item.Id}
										label={<PlaidAccountRadioItem item={item} />}
									/>
								))}
							</Field>
							<div className={classes.button}>
								<Button
									fullWidth
									type="submit"
									variant="contained"
									color="primary"
									disabled={invalid || submitting}
								>
									{submitting ?
										<CircularProgress
											
											size={18}
										/>
										: 'Link Bank Account'
									}
								</Button>
							</div>
						</div>
					</form>
				}
			</Modal>
		);
	}
}

PlaidSelectAccountModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	bankAccounts: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: 'PlaidSelectAccountForm',
		validate: PlaidSelectAccountModal.validate,
	}),
)(PlaidSelectAccountModal);
