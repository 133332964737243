import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { notifications } from '@redux';
import {
	AlertNotify,
} from './components';

const mapStateToProps = state => ({
	items: notifications.selectors.notifications(state).items,
	loading: notifications.selectors.loading(state),
});

class J2TXAlerts extends React.Component {
	render() {
		const {
			items,
			loading,
		} = this.props;

		return (
			<div>
				{!loading && items &&
				<div>
					{items.filter(item => item.AlertType === 'alert').map((item, index) => (
						<AlertNotify
							text={item.Short || item.Full}
							title={item.Title}
						/>
					))}
				</div>
				}
			</div>
		);
	}
}

export default compose(
	connect(mapStateToProps),
)(J2TXAlerts);
