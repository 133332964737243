import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { profile, signature, userAccounts } from '@redux';
import {
	A,
	ChangeLink,
	ExpansionPanel,
} from '@components';
import { InputEditableItem } from '@shared';
import { env } from '@config';
import { TwoFactorAutenticationType } from '@enums';

const useActions = () => {
	const dispatch = useDispatch();
	return ({
		showChangeEmailModal: () => {
			const changeEmailAction = profile.actions.showChangeEmailModal();
			dispatch(signature.actions.collectSignaturesProcessStart(changeEmailAction));
		},
		showChangeTwoFactorAuthorizationModal: () => dispatch(profile.actions.showChangeTwoFactorAuthorizationModal()),
	});
};

//todo: американский компонент. Можно удалить в рамках общего удаления, т.к. не используется
const AccountCredentialsPanel = (props) => {
	const {
		onToggle,
		panelId,
		isOpen,
		classes,
	} = props;
	const {
		PersonalInformation,
		PendingOrders,
	} = useSelector(profile.selectors.profile) || {};
	const userInfo = useSelector(userAccounts.selectors.userInfo);
	const hasOpenAccount = useSelector(userAccounts.selectors.hasOpenAccount);
	const actions = useActions();

	const getAuthenticationState = () => {
		switch (userInfo.TwoFactorAutenticationType) {
		case TwoFactorAutenticationType.SMS:
			return 'Enabled (SMS)';
		case TwoFactorAutenticationType.TOTP:
			return 'Enabled (TOTP)';
		default:
			return 'Disabled';
		}
	};

	return (
		<ExpansionPanel
			header="Account Credentials"
			id={panelId}
			onToggleOpen={onToggle}
			isOpen={isOpen}
			classes={{ root: classes.expansionPanelRoot, content: classes.expansionPanelContent }}
		>
			<InputEditableItem
				title="Email"
				value={PersonalInformation.Email}
				editControl={ hasOpenAccount &&
					<ChangeLink onClick={actions.showChangeEmailModal} disabled={PendingOrders.Email} />
				}
			/>
			<InputEditableItem
				title="Password"
				value="*************"
				editControl={ hasOpenAccount &&
					<A target="_blank" href={`${env.OAuthServiceUrl}password/reset`}>CHANGE</A>
				}
			/>
			<InputEditableItem
				title="Two-factor authentication"
				value={getAuthenticationState()}
				editControl={ hasOpenAccount &&
					PersonalInformation.Phone &&
					<ChangeLink onClick={actions.showChangeTwoFactorAuthorizationModal} disabled={false} />
				}
			/>
		</ExpansionPanel>
	);
};

AccountCredentialsPanel.propTypes = {
	classes: PropTypes.object.isRequired,
	onToggle: PropTypes.func.isRequired,
	panelId: PropTypes.string.isRequired,
	isOpen: PropTypes.bool,
};

AccountCredentialsPanel.defaultProps = {
	isOpen: false,
};

export default AccountCredentialsPanel;
