import React from 'react';

import {
	useTranslate,
	ContentCloud,
} from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
	root: {
		'& a': {
			color: theme.palette.primary.main,
			textDecoration: 'none',
		},
	},
}));


export default function FAQ() {

	const classes = useStyles();
	const translate = useTranslate();

	return (
		<ContentCloud
			submitting={false}
			id={'StakingFAQ'}
			header={translate('staking_faq', 'Staking FAQ')}
		>
			<Box className={classes.root}>
				{[1,2,3,4,5].map(i => 
					<Box marginBottom='30px' key={i}>
						<Typography variant='h6'>
							<R
								id={`staking_faq_header_${i}`}
								defaultValue="Earned Total"
							/>
						</Typography>
						<R
							className={classes.root}
							id={`staking_faq_section_${i}`}
							defaultValue="Earned Total"
							enableHtml
						/> 
					</Box>	
				)}
			</Box>
		</ContentCloud>
	);
}
