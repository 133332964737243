import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { userAccounts } from '@redux';
import { DemoAccountsTabs } from '@enums';
import DemoAccountsList from './ListVariants/DemoAccountList';
import { Button, R } from '@dev2t/react-ui-kit/src';
import { SvgIcon } from '@components';
import { ProgressContainer } from '@components';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({ 
    title:{
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        fontSize: '20px',
    },
    headerContainer:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        margin: '15px 0 30px 0',
        padding: '0px 25px',
    },
    switchers: {
        backgroundColor: theme.palette.primary.contrastText,
        border: '1px solid #deeaef',
        borderRadius: '10px',
        padding: '5px 0'
    },
    threeLineSwitcher: {
        marginTop: '5px',
    },
    listContainer: {
        padding: '0 20px'
    }
}));

const DemoAccounts = (props) => {
    const { viewType } = props;
    const classes = useStyles();
    const theme = useTheme();

    const accounts = useSelector(userAccounts.selectors.accounts);
    const balances = useSelector(userAccounts.selectors.accountsInfo);
    const accountsInfoLoading = useSelector(userAccounts.selectors.accountsInfoLoading);
    const loading = useSelector(userAccounts.selectors.loading);


    const [activeList, setActiveList] = useState(viewType || DemoAccountsTabs.CARD);

    return (
        <ProgressContainer submitting={accountsInfoLoading || loading}>
                <Box>
                    <Box className={classes.headerContainer}>
                    <Box>
                        <Typography className={classes.title}><R id="common_menu_demo_overview" defaultValue="Demo accounts" /></Typography>
                    </Box>
                    <Box className={classes.switchers}>
                        <Button className={classes.threeLineSwitcher} onClick={() => { setActiveList(DemoAccountsTabs.STANDARD) }}>
                            <SvgIcon
							    icon="ThreeLines"
							    htmlColor={activeList === DemoAccountsTabs.STANDARD ? theme.palette.primary.main : theme.palette.text.disabled}
						    />
                        </Button>
                        <Button onClick={() => { setActiveList(DemoAccountsTabs.CARD) }}>
                            <SvgIcon
							    icon="Tile"
							    htmlColor={activeList === DemoAccountsTabs.CARD ? theme.palette.primary.main : theme.palette.text.disabled}
						    />
                        </Button>
                    </Box>
                </Box>
                {!(balances === undefined || accounts === undefined) && 
                    <Box className={classes.listContainer}>
                        <DemoAccountsList accounts={accounts} balances={balances} viewType={activeList}/>
                    </Box>
                }
            </Box>
        </ProgressContainer>
    );
};

export default DemoAccounts;
