import { useTranslate } from '@components';

function getCommission(currency, usdtProtocol) {
	if (currency.toUpperCase() === 'USDT' && !usdtProtocol) return undefined;
	switch (currency) {
		case 'USD':
			return {
				percent: 2,
			};
		case 'EUR':
			return {
				percent: 2,
			};
		case 'BTC':
			return {
				flat: 0.0005,
			};
		case 'ETH':
			return {
				flat: 0.02,
			};
		case 'USDT':
			switch (usdtProtocol) {
				case 'TRC20':
					return {
						flat: 15,
					};
				case 'ERC20':
					return {
						flat: 25,
					};
				default:
					return {
						flat: 1000000000,
					};
			}
		case 'XRP':
			return {
				flat: 25,
			};
		// case 'LTC':
		// 	// временно очень большая комиссия, пока не скажут точную
		// 	return 10000000000;
		default:
			throw new Error(`impossible currency for j2tx withdraw: ${currency}`);
	}
}

export function useJ2txCommission(currency, usdtProtocol) {
	const translate = useTranslate();
	function getCommissionLabel(currency, usdtProtocol) {
		const commission = getCommission(currency, usdtProtocol);
		if (currency === 'USDT' && usdtProtocol) {
			return `${translate('common_commission', 'Commission')}: ${usdtProtocol ? translate('common_min', 'min') : ''} ${commission.flat} ${currency} ${usdtProtocol ? `(${usdtProtocol})` : ''}`;
		} if (currency === 'USDT' && !usdtProtocol) {
			return translate('enter_correct_account', 'Enter correct account');
		} if (commission.percent) {
			return `${translate('common_commission', 'Commission')} ${commission.percent}%`;
		}
		return `${translate('common_commission', 'Commission')} ${commission.flat} ${currency}`;
	}

	if (!currency && !usdtProtocol) return undefined;
	const commission = getCommission(currency, usdtProtocol);
	const label = getCommissionLabel(currency, usdtProtocol);
	return { ...commission, label };
}
