import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { useSelector } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
	R,
	TextField,
} from '@dev2t/react-ui-kit/src';
import { 
	Modal, 
	ErrorMessage,
	SuccessMessage,
	SvgIcon,
} from '@components';
import { apiKeys, } from '@redux';
import CreateApiKeyReduxForm from './CreateApiKeyModalForm';

const useStyles = makeStyles(theme => ({
	keyForCopy: {
		fontSize: '18px',
		color: theme.typography.disable,
		textOverflow: 'ellipsis',
    	overflow: 'hidden',
		whiteSpace: 'nowrap',
		width: '100%',
	},
	keyCopyContainer: {
		display: 'flex',
    	flexDirection: 'row',
    	alignItems: 'center',
	},
}));

const CreateApiKeyModal = ({ onClose }) => {
	const classes = useStyles();
	const [step, setStep] = useState('form');
	const secretKey = useSelector(apiKeys.selectors.createdSecretKey)
	
	return (
		<Modal
			title={<R id="api_keys_create_api_key_title" defaultValue="Create Api Key"/>}
			onClose={onClose}
		>
			{step === 'succeeded' && (
				<SuccessMessage
					onClick={onClose}
					text={
						<div>
							<div>
								<R id="api_keys_copy_key" defaultValue="
								Please copy and save the secret. 
								This is the only time you can see the secret, 
								it will disappear after navigating away from this page"/>
							</div>
							{secretKey && (
								<div className={classes.keyCopyContainer}>	
									<div className={classes.keyForCopy}>
										<TextField disableUnderline fullWidth readOnly disabled value={secretKey}/>
									</div>
									<CopyToClipboard text={secretKey}>
										<IconButton>
											<SvgIcon icon="ContentCopy" />
										</IconButton>
									</CopyToClipboard>
								</div>
							)}
						</div>
					}
				/>
			)}
			{step === 'failed' && (
				<ErrorMessage
					onClick={onClose}
					text={
						<R
							id="common_reportstatus_error"
							defaultValue="Error"
						/>
					}
				/>
			)}
			{step === 'form' && (
				<CreateApiKeyReduxForm 
					onSubmit={apiKeys.actions.createApiKey}
					onSubmitFail={() => setStep('failed')}
					onSuccess={() => setStep('succeeded')}
			/>
			)}
		</Modal>
	);
}

export default CreateApiKeyModal;
