import { put, select, fork, takeEvery } from 'redux-saga/effects';
import {
	achWithdraw,
	brokerWithdraw,
	transferBetweenAccounts,
	wireWithdraw,
	wireLinking,
	exchangeCurrency,
	stake,
	unstake
} from '@api';
import { SubmissionError } from 'redux-form';
import { BaseGetSagaHandler, callApi, linkedAccounts, userAccounts, snackbar } from '@redux';
import { push } from 'connected-react-router';
import { ModalPopups } from '@enums';
import { addHashToUrl, removeHashFromUrl, captureError } from '@global';
import actions from './actions';
import { MapWireLinkPayload } from '../linkedAccounts/saga';

function* reloadLinkedAccounts(tradeCode) {
	if (tradeCode != null)
	{
		yield put(linkedAccounts.actions.getLinkedAccounts.request({ tradeCode }));
	}
}

// function* reloadTransactionHistory(tradeCode, clearerFirm) {
// 	const cashActivities = yield select(userAccounts.selectors.cashActivities);
// 	const {
// 		StartDate,
// 		EndDate,
// 	} = cashActivities.pagination;

// 	// reload first page
// 	yield put(userAccounts.actions.getTransactionsHistory.request({
// 		TradeCode: tradeCode,
// 		ClearerFirm: clearerFirm,
// 		StartDate,
// 		EndDate,
// 		Page: 1,
// 		PageSize: 10,
// 	}));
// }

function* withdrawFormAchSubmit({ payload }) {
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: achWithdraw,
		errorText: 'ACH Withdraw Funds',
		handler: actions.withdrawAchFundsForm,
	}, { payload });

	if (isSuccess) {
		yield* userAccounts.saga.reloadTransactionHistory(payload.TradeCode, payload.ClearerFirm);
	}
}
function* withdrawFormBrokerSubmit({ payload }) {
	const createTransferModel = {
		TradeCode: payload.TradeCode,
		ClearingFirm: payload.ClearingFirm,
		Amount: payload.Amount,
		Direction: payload.Direction,
		BrokerAccount: payload.bankAccountNumber,
	};
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: brokerWithdraw,
		errorText: 'BROKER Withdraw Funds',
		handler: actions.withdrawFundsBrokerForm,
	}, { payload: createTransferModel });

	if (isSuccess) {
		yield* userAccounts.saga.reloadTransactionHistory(payload.TradeCode, payload.ClearingFirm);
	}
}


function* transferBetweenAccountsSubmitSaga({ payload }) {
	
	const accounts = yield select(userAccounts.selectors.accounts);

	if (payload.TradeCodeTo === 'J2TX_STAKING_MOCK_TRADECODE' || accounts.find(x => x.TradeCode === payload.TradeCodeTo).IsStaking) {
		yield stakeSubmitSaga({ payload });
		return;
	}
	else if (payload.TradeCodeFrom === 'J2TX_STAKING_MOCK_TRADECODE' || accounts.find(x => x.TradeCode === payload.TradeCodeFrom).IsStaking) {
		yield unstakeSubmitSaga({ payload });
		return;
	}

	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: transferBetweenAccounts,
		errorText: 'Transfer between accounts',
		handler: actions.transferBetweenAccountsSubmitForm,
	}, { payload });
	

	if (isSuccess) {
		yield* userAccounts.saga.reloadTransactionHistory(payload.TradeCodeFrom, payload.ClearingFirmFrom);
	}
}

function* stakeSubmitSaga({ payload }) {
	
	payload = {
		// при staking передаем не staking tradeCode, а wallet tradeCode
		TradeCode: payload.TradeCodeFrom,
		Amount: payload.Summ,
		Currency: payload.SummCurrency,
	}

	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: stake,
		errorText: 'Stake',
		handler: actions.transferBetweenAccountsSubmitForm,
	}, { payload });

	if (isSuccess) {
		yield* userAccounts.saga.reloadTransactionHistory();
	}

}

function* unstakeSubmitSaga({ payload }) {
	
	payload = {
		// при unstaking передаем не staking tradeCode, а wallet tradeCode
		TradeCode: payload.TradeCodeTo,
		Amount: payload.Summ,
		Currency: payload.SummCurrency,
	}

	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: unstake,
		errorText: 'Unstake',
		handler: actions.transferBetweenAccountsSubmitForm,
	}, { payload });

	if (isSuccess) {
		yield* userAccounts.saga.reloadTransactionHistory();
	}
}

function* exchangeCurrencySubmitSaga({ payload }) {
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: exchangeCurrency,
		errorText: 'Exchange currency',
		handler: actions.exchangeCurrencySubmitForm,
	}, { payload });

	// if (isSuccess) {
	// 	yield* userAccounts.saga.reloadTransactionHistory(payload.TradeCodeFrom, payload.ClearingFirmFrom);
	// }
}


function MapWireWithdrawalPayload(payload) {
	return {
		...payload.wireWithdrawal,
		Name: payload.wireWithdrawal.ClearingFirm === 'cor' ? payload.wireWithdrawal.Name : 'Name',
		Surname: payload.wireWithdrawal.ClearingFirm === 'cor' ? payload.wireWithdrawal.Surname : 'Surname',
		Address: payload.wireWithdrawal.ClearingFirm === 'cor' ? payload.wireWithdrawal.Address : 'Address',
		ZipCode: payload.wireWithdrawal.ClearingFirm === 'cor' ? payload.wireWithdrawal.ZipCode : 'ZipCode',
		Phone: payload.wireWithdrawal.ClearingFirm === 'cor' ? payload.wireWithdrawal.Phone : '123456789',
		BankRoutingNumber: payload.wireWithdrawal.WireType === 'International' ? '123456789' : payload.wireWithdrawal.BankRoutingNumber,
		BankSwiftCode: payload.wireWithdrawal.WireType === 'Domestic' ? '123456789' : payload.wireWithdrawal.BankSwiftCode,
		UseIntermediaryBank: payload.wireWithdrawal.UseIntermediaryBank ? 'FME' : false,
		IntermediaryBankRoutingNumber: (payload.wireWithdrawal.UseIntermediaryBank && payload.wireWithdrawal.WireType === 'International') ? '123456789' : payload.wireWithdrawal.IntermediaryBankRoutingNumber,
		IntermediaryBankSwiftCode: (payload.wireWithdrawal.UseIntermediaryBank && payload.wireWithdrawal.WireType === 'Domestic') ? '123456789' : payload.wireWithdrawal.IntermediaryBankSwiftCode,
		ConfirmationCode: payload.wireWithdrawal.ConfirmationCode,
		Summ: payload.wireWithdrawal.Summ,
	};
}

function* withdrawFormWireSubmit({ payload }) {
	try {
		const request = yield callApi(wireWithdraw, MapWireWithdrawalPayload(payload));
		if (!request.data.Success) {
			yield put(actions.withdrawFundsWireFormFailure(new SubmissionError({
				_error: request.data.Errors[0].Message,
			})));
			yield put(snackbar.actions.showErrorMessage({ text: request.data.Errors[0].Message }));
		} else {
			yield put(actions.withdrawFundsWireFormSuccess());
			yield* reloadLinkedAccounts(payload.wireWithdrawal.TradeCode);
			yield* userAccounts.saga.reloadTransactionHistory(payload.wireWithdrawal.TradeCode, payload.wireWithdrawal.ClearingFirm);
		}
		return request.data;
	} catch (error) {
		captureError(error);
		yield put(actions.withdrawFundsWireFormFailure(new SubmissionError({
			_error: 'NETWORK ERROR',
		})));
		yield put(snackbar.actions.showErrorMessage({ text: error.message }));
		return { Success: false, Errors: [{ Message: error.message }] };
	}
}


function* linkAndWithdrawFundsWireFormSubmit({ payload }) {
	try {
		const wireLinkinkPayload = MapWireLinkPayload(payload);
		const wireWithdrawalPayload = {
			...MapWireWithdrawalPayload(payload),
			WireType: wireLinkinkPayload.WireType,
			BankAccountName: wireLinkinkPayload.BankAccountName,
			BankAccountNumber: wireLinkinkPayload.BankAccountNumber,
			BankAddress: wireLinkinkPayload.BankAddress,
			BankName: wireLinkinkPayload.BankName,
			BankRoutingNumber: wireLinkinkPayload.BankRoutingNumber,
			BankSwiftCode: wireLinkinkPayload.BankSwiftCode,
		};
		const request = yield callApi(wireLinking, {
			...wireLinkinkPayload,
			WireWithdrawal: wireWithdrawalPayload,
			ConfirmationCode: wireWithdrawalPayload.ConfirmationCode,
			Summ: wireWithdrawalPayload.Summ,
		});

		if (!request.data.Success) {
			yield put(actions.linkAndWithdrawFundsWireFormFailure(new SubmissionError({
				_error: request.data.Errors[0].Message,
			})));
			yield put(snackbar.actions.showErrorMessage({ text: request.data.Errors[0].Message }));
		} else {
			yield put(actions.linkAndWithdrawFundsWireFormSuccess());
			yield* reloadLinkedAccounts(payload.wireLink.TradeCode);
			yield* userAccounts.saga.reloadTransactionHistory(payload.wireLink.TradeCode, payload.wireLink.ClearingFirm);
		}
	} catch (error) {
		captureError(error);
		yield put(snackbar.actions.showErrorMessage({ text: error.message }));
	}
}

function* showWithdrawFundsAchModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(addHashToUrl(location, ModalPopups.WITHDRAW_FUNDS_ACH)));
}

function* hideWithdrawFundsAchModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(removeHashFromUrl(location, ModalPopups.WITHDRAW_FUNDS_ACH)));
}

function* showWithdrawFundsWireModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(addHashToUrl(location, ModalPopups.WITHDRAW_FUNDS_WIRE)));
}

function* hideWithdrawFundsWireModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(removeHashFromUrl(location, ModalPopups.WITHDRAW_FUNDS_WIRE)));
}
function* showWithdrawFundsBrokerModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(addHashToUrl(location, ModalPopups.WITHDRAW_FUNDS_BROKER)));
}

function* hideWithdrawFundsBrokerModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(removeHashFromUrl(location, ModalPopups.WITHDRAW_FUNDS_BROKER)));
}

// Watchers
function* watchWithdrawFormAchSubmit() {
	yield takeEvery(actions.withdrawAchFundsForm.REQUEST, withdrawFormAchSubmit);
}
function* watchWithdrawFormBrokerSubmit() {
	yield takeEvery(actions.withdrawFundsBrokerForm.REQUEST, withdrawFormBrokerSubmit);
}
function* watchTransferBetweenAccountsSubmit() {
	yield takeEvery(actions.transferBetweenAccountsSubmitForm.REQUEST, transferBetweenAccountsSubmitSaga);
}
function* watchExchnageCurrencySubmit() {
	yield takeEvery(actions.exchangeCurrencySubmitForm.REQUEST, exchangeCurrencySubmitSaga);
}
function* watchLinkAndWithdrawFundsWireFormSubmit() {
	yield takeEvery(actions.linkAndWithdrawFundsWireForm.REQUEST, linkAndWithdrawFundsWireFormSubmit);
}
function* watchWithdrawFormWireSubmit() {
	yield takeEvery(actions.WITHDRAW_FUNDS_WIRE_FORM_SUBMIT_REQUEST, withdrawFormWireSubmit);
}
function* watchShowWithdrawFundsAchModal() {
	yield takeEvery(actions.SHOW_WITHDRAW_FUNDS_ACH_MODAL, showWithdrawFundsAchModal);
}
function* watchHideWithdrawFundsAchModal() {
	yield takeEvery(actions.HIDE_WITHDRAW_FUNDS_ACH_MODAL, hideWithdrawFundsAchModal);
}
function* watchShowWithdrawFundsWireModal() {
	yield takeEvery(actions.SHOW_WITHDRAW_FUNDS_WIRE_MODAL, showWithdrawFundsWireModal);
}
function* watchHideWithdrawFundsWireModal() {
	yield takeEvery(actions.HIDE_WITHDRAW_FUNDS_WIRE_MODAL, hideWithdrawFundsWireModal);
}
function* watchShowWithdrawFundsBrokerModal() {
	yield takeEvery(actions.SHOW_WITHDRAW_FUNDS_BROKER_MODAL, showWithdrawFundsBrokerModal);
}
function* watchHideWithdrawFundsBrokerModal() {
	yield takeEvery(actions.HIDE_WITHDRAW_FUNDS_BROKER_MODAL, hideWithdrawFundsBrokerModal);
}
// Root saga
const rootSaga = [
	fork(watchWithdrawFormAchSubmit),
	fork(watchWithdrawFormBrokerSubmit),
	fork(watchLinkAndWithdrawFundsWireFormSubmit),
	fork(watchWithdrawFormWireSubmit),
	fork(watchShowWithdrawFundsAchModal),
	fork(watchHideWithdrawFundsAchModal),
	fork(watchShowWithdrawFundsWireModal),
	fork(watchHideWithdrawFundsWireModal),
	fork(watchShowWithdrawFundsBrokerModal),
	fork(watchHideWithdrawFundsBrokerModal),
	fork(watchTransferBetweenAccountsSubmit),
	fork(watchExchnageCurrencySubmit),
];

export default {
	rootSaga,
};
