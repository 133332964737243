import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MURadio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	root: {},
	primary: {
		color: theme.palette.text.primary,
	},
	red: {
		color: theme.palette.error.main,
	},
	checkedPrimary: {
		color: theme.palette.primary.light,
	},
	label: {
		...theme.customstyles.title,
	},
	additionalText: {
		padding: '0px 24px',
		color: theme.palette.text.secondary,
		...theme.customstyles.body,
	},
	checkedError: {
		color: `${theme.palette.error.main} !important`,
	},
});

const Radio = ({
	classes,
	disabled,
	error,
	helpertext,
	...props
}) => (
	<React.Fragment>
		<FormControlLabel
			classes={{
				root: classes.root,
				label: classNames({
					[classes.label]: true,
					[classes.red]: props.checked && error,
					[classes.primary]: !props.checked || !error,
				}),
			}}
			control={
				<MURadio
					classes={{
						checked: classNames({
							[classes.checkedPrimary]: true,
							[classes.checkedError]: error,
						}),
						colorSecondary: classes.red,
					}}
					color="primary"
					disabled={disabled}
				/>
			}
			{...props}
		/>
		{props.checked && error &&
			<FormHelperText error classes={{ root: classes.additionalText }}>
				{helpertext}
			</FormHelperText>
		}
	</React.Fragment>
);

Radio.propTypes = {
	checked: PropTypes.bool,
	classes: PropTypes.object.isRequired,
	disabled: PropTypes.bool,
	error: PropTypes.bool,
	helpertext: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

Radio.defaultProps = {
	checked: false,
	disabled: false,
	error: false,
	helpertext: null,
};

export default withStyles(styles)(Radio);
