import React from 'react';
import { useSelector } from 'react-redux';
import { apiKeys } from '@redux';
import ApiKeysPanelContent from './ApiKeysPanelContent';
import {
	useTranslate,
	ContentCloud,
} from '@components';

const ApiKeysPanel = (props) => {
	const translate = useTranslate();
	const apitokens = useSelector(apiKeys.selectors.tokens);

	if (Array.isArray(apitokens.items) && apitokens.items.length > 0) {
		return (
			<ContentCloud
				submitting={apitokens.submitting}
				id={'ApiKeysTokens'}
				header={translate('api_keys_tokens_list', 'Tokens list')}
			>
				<ApiKeysPanelContent apitokens={apitokens}/>
			</ContentCloud>
		);
	}
	else{
		return <></>;
	}
};

export default ApiKeysPanel;
