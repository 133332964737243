import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, SuccessMessage, ErrorMessage, ProgressContainer } from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import { makeStyles } from '@material-ui/core/styles';
import { nontradingOrders, ordersCy, userAccounts } from '@redux';
import {
	CYOpenAccountChoosePlatform,
	CYOpenAccountChooseType,
	CYOpenAccountChooseLeverage,
	CYOpenAccountChooseCurrency,
} from './components';

const useStyles = makeStyles(theme => ({
	modalContainer: {
		maxWidth: '800px',
	},
}));

const CY_NON_TRADING_OPEN_ACCOUNT_FORM_ID = 'CYNonTradingOpenAccountModal';
const CYNonTradingOpenAccountModal = ({ onClose }) => {
	const [step, setStep] = useState('chooseplatform');
	const [error, setError] = useState('');
	const loading = useSelector(state => userAccounts.selectors.loading(state) || userAccounts.selectors.accountInfo(state).loading);
	const classes = useStyles();

	const handleGotoPage = page => (result, dispatch, props) => setStep(page);

	return (
		<Modal
			title={<R id="common_title_add_live_accounts" defaultValue="Open additional live account" />}
			onClose={onClose}
			bgColor="#f9fcfd"
			textColor="#345464"
			classes={{ container: classes.modalContainer }}
		>
			<ProgressContainer submitting={loading}>
				<div>
					{step === 'succeeded'
						&& (
							<SuccessMessage
								onClick={onClose}
								text={<R id="common_open_account_completed" defaultValue="Account has been successfully opened" />}
							/>
						)}
					{step === 'failed'
						&& (
							<ErrorMessage
								onClick={onClose}
								text={error || <R id="common_open_account_failed" defaultValue="Could not complete new account" />}
							/>
						)}
					{step === 'chooseplatform' && (
						<CYOpenAccountChoosePlatform
							destroyOnUnmount={false}
							forceUnregisterOnUnmount
							form={CY_NON_TRADING_OPEN_ACCOUNT_FORM_ID}
							enableReinitialize
							onSubmit={handleGotoPage('choosetype')}
							onSuccess={() => setStep('choosetype')}
							onSubmitFail={() => setStep('failed')}
						/>
					)}
					{step === 'choosetype'
						&& (
							<CYOpenAccountChooseType
								destroyOnUnmount={false}
								// forceUnregisterOnUnmount
								form={CY_NON_TRADING_OPEN_ACCOUNT_FORM_ID}
								// enableReinitialize
								onSubmit={handleGotoPage('chooseleverage')}
								onSuccess={() => setStep('chooseleverage')}
								onSubmitFail={() => setStep('failed')}
							/>
						)}
					{step === 'chooseleverage'
						&& (
							<CYOpenAccountChooseLeverage
								destroyOnUnmount={false}
								// forceUnregisterOnUnmount
								form={CY_NON_TRADING_OPEN_ACCOUNT_FORM_ID}
								// enableReinitialize
								onSubmit={nontradingOrders.actions.sendNonTradingOpenAccount}
								onSuccess={() => setStep('succeeded')}
								// onSubmit={handleGotoPage('chooseleverage')}
								// onSuccess={() => setStep('chooseleverage')}
								onSubmitFail={err => { setError(err._error); setStep('failed'); }}
								onSubmitSuccess={() => setStep('succeeded')}
							/>
						)}
					{step === 'choosecurrency'
						&& (
							<CYOpenAccountChooseCurrency
								destroyOnUnmount={false}
								// enableReinitialize
								// forceUnregisterOnUnmount
								form={CY_NON_TRADING_OPEN_ACCOUNT_FORM_ID}
								onSubmit={nontradingOrders.actions.sendNonTradingOpenAccount}
								onSuccess={() => setStep('succeeded')}
								onSubmitFail={err => { setError(err._error); setStep('failed'); }}
								onSubmitSuccess={() => setStep('succeeded')}
							/>
						)}
				</div>
			</ProgressContainer>
		</Modal>
	);
};

export default CYNonTradingOpenAccountModal;
