import React from 'react';
import { List, R } from '@dev2t/react-ui-kit/src';
import { A, SvgIcon } from '@components';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Mt4Img, Mt5Img } from '@content/mtLogos';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({ 
    Mt4Img: {
		width: '50px',
		heigth: '50px',
		borderRadius: '5px',
		boxShadow: '0px 3px 3px 1px #DCDCDC',
        marginRight: '20px',
	},
    platformFullName: {
		color: theme.palette.primary.main,
        fontWeight: 'bold',
	},
    cardRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        boxShadow: '0 6px 16px 0 rgba(61, 65, 71, 0.12)',
        borderRadius: '10px',
        margin: '0 0 15px 0',
        padding: '20px',
        [theme.breakpoints.down('sm')]: {
			display: 'flex',
			flexDirection: 'column',
		},
    },
    tradeLogin: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        marginBottom: '10px',
    },
    cardImageColumn: {
        display: 'flex',
        flexDirection: 'row',
        width: '40%',
        [theme.breakpoints.down('sm')]: {
			width: '100%',
            justifyContent: 'space-between',
            marginBottom: '30px',
		},
    },
    cardImageColumnItem1: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
			width: '40%',
		},
    },
    cardImageColumnItem2: {
        [theme.breakpoints.down('sm')]: {
			width: '60%',
		},
    },
    cardSecondColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: '60%',
        [theme.breakpoints.down('sm')]: {
			width: '100%',
		},
    },
    allInfo: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
    },
    serverAndPlatform: {
        display: 'flex',
        flexDirection: 'column',
        width: '60%',
        [theme.breakpoints.down('sm')]: {
			width: '100%',
            justifyContent: 'space-between',
		},
    },
    currencyAndLeverage: {
        display: 'flex',
        flexDirection: 'column',
        width: '40%',
        [theme.breakpoints.down('sm')]: {
			width: '100%',
            justifyContent: 'space-between',
		},
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
			display: 'flex',
			flexDirection: 'column',
		},
    },
    button: {
        marginRight: '15px',
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
        },
        [theme.breakpoints.down('sm')]: {
			marginBottom: '20px',
		},
    },
    buttonLink: {
        textDecoration: 'none',
        color: 'inherit',
    },
    currencyAndLeverageLine:{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
			justifyContent: 'flex-start',
		},
    },
    serverAndPlatformLine:{
        display: 'flex',
        flexDirection: 'row',
        '&:first-child':{
            marginRight: '5px',
        },
    },
    serverAndCurrency: {
        marginBottom: '15px',
        [theme.breakpoints.down('sm')]: {
			marginBottom: '5px',
		},
    },
    platformAndLeverage: {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
			marginBottom: '40px',
		},
    },
    btEdit: {
		margin: '-14px -12px -12px -8px',
	},
}));

const KeyIntoText = withStyles(theme => ({
    root: {
        marginRight: '5px',
        [theme.breakpoints.down('sm')]: {
			width: '40%',
		},
    },
}))(Box);

const ValueTextValue = withStyles(theme => ({
    root: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
			width: '60%',
		},
    },
}))(Box);


const DemoAccountsCardList = (props) => {
    const { accounts, createTerminalLink, showLeverageDialog } = props;
    const classes = useStyles();
    return (
        <List>
            {accounts.map((acc) => (
                <div key={acc.TradeLogin} className={classes.cardRow}>
                    <div className={classes.cardImageColumn}>
                        <div className={classes.cardImageColumnItem1}>
                            {acc.IsForex ? (
									<img className={classes.Mt4Img} src={Mt4Img} alt="" />
								) : (
									<img className={classes.Mt4Img} src={Mt5Img} alt="" />
								)}
                        </div>
                        <div className={classes.cardImageColumnItem2}>
                            <div className={classes.tradeLogin}>
                                {acc.TradeLogin}
                            </div>
                            <div className={classes.platformFullName}>{acc.ProductType}</div>
                        </div>
                    </div>


                    <div className={classes.cardSecondColumn}>
                        <div className={classes.allInfo}>
                            <div className={classes.serverAndPlatform}>
                                <div className={`${classes.serverAndPlatformLine} ${classes.serverAndCurrency}`}>
                                    <KeyIntoText><R id="common_useraccounts_trade_server" defaultValue="Server" />:</KeyIntoText>
                                    <ValueTextValue>{acc.ServerName}</ValueTextValue>
                                </div>
                                <div className={`${classes.serverAndPlatformLine} ${classes.platformAndLeverage}`}>
                                    <KeyIntoText><R id="shared_platform" defaultValue="Platform" />:</KeyIntoText>
                                    <ValueTextValue>{ acc.IsForex ? 'MT4' : 'MT5' }</ValueTextValue>
                                </div>
                            </div>
                            <div className={classes.currencyAndLeverage}>
                                <div className={`${classes.currencyAndLeverageLine} ${classes.serverAndCurrency}`}>
                                    <KeyIntoText><R id="common_account_currency" defaultValue="Account currency" />:</KeyIntoText>
                                    <ValueTextValue>{acc.Balance.Currencies?.find(x => x !== undefined).CurrencyCode ?? '-'}</ValueTextValue>
                                </div>
                                <div className={`${classes.currencyAndLeverageLine}  ${classes.platformAndLeverage}`}>
                                    <KeyIntoText><R id="order_type_account_leverage" defaultValue="Leverage" />:</KeyIntoText>
                                    <ValueTextValue>{acc.LeverageText ?? "-"}
                                        {/* <IconButton onClick={showLeverageDialog} classes={{ root: classes.btEdit }}>
									        <SvgIcon
										        icon="Edit"
										        color="primary"
										        fontSize="small"
										        classes={{ root: classes.btEditSmallIcon }}/>
						                </IconButton> */}
                                    </ValueTextValue>
                                </div>
                            </div>
                        </div>

                        <div className={classes.buttons}>
                            <Button className={classes.button} variant='outlined' color='primary'>
                                <Link className={classes.buttonLink} to="/dashboard/tradingplatforms">
                                    <R id="common_menu_trading_platforms" defaultValue="Trading Platforms" />
							    </Link>
                            </Button>   
                            <Button className={classes.button} variant='outlined' color='primary'>
                                <A className={classes.buttonLink} href={createTerminalLink(acc)} target="_blank">
                                    <R id="common_trade" defaultValue="Trade" />
							    </A>
                            </Button>      
                        </div>
                    </div>
                </div>
            ))}
        </List>
    );
}

export default DemoAccountsCardList;