import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { A } from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';

const styles = theme => ({
	pendingInfoText: {
		display: 'inline-block',
		color: theme.palette.error.main,
		margin: '0 0',
		textTransform: 'uppercase',
	},
});

class ChangeLink extends React.Component {
	render() {
		const {
			classes,
			className,
			onClick,
			activeLinkText,
			inactiveLinkText,
			disabled,
		} = this.props;

		const activeText = activeLinkText || <R id="shared_change" defaultValue="CHANGE" />;
		const disabledText = inactiveLinkText || <R id="shared_change_request_in_progress" defaultValue="CHANGE REQUEST IN PROGRESS" />;
		return (
			<span className={className}>
				{disabled ? (
					<p className={classes.pendingInfoText}>{disabledText}</p>
				) : (
					<A onClick={onClick} upper>{activeText}</A>
				)}
			</span>
		);
	}
}

ChangeLink.propTypes = {
	classes: PropTypes.object.isRequired,
	onClick: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	activeLinkText: PropTypes.string,
	inactiveLinkText: PropTypes.string,
	className: PropTypes.string,
};


ChangeLink.defaultProps = {
	activeLinkText: undefined,
	inactiveLinkText: undefined,
	disabled: false,
	className: undefined,
};

export default compose(
	withTheme,
	withStyles(styles),
)(ChangeLink);
