import React from 'react';

import { useAccount } from '@hooks';
import { AccountType } from '@enums';

import FAQ from './components/FAQ';
import Balance from './components/Balance';
import Transactions from './components/Transactions';

export default function Staking() {
	
	const accounts = useAccount(AccountType.STAKING);
	const tradeCode = accounts[0]?.TradeCode;

	return (
		<>
			<Balance tradeCode={tradeCode} />
			<Transactions tradeCode={tradeCode} />
			<FAQ />
		</>
	);
}
