import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { makeStyles, useTheme } from '@material-ui/core';
import {
	reduxForm,
	Field,
	formValueSelector,
	change,
} from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';

import {
	FormSelect,
	Divider,
	Button,
	useTranslate,
	SvgIcon,
} from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import { userAccounts } from '@redux';
import { template } from '@templates';
import { useAvailableCurrencies } from './useAvailableCurrencies';

const BigSvgIcon = withStyles(theme => ({
	root: {
		width: '150px',
		height: '150px',
	},
}))(SvgIcon);

const useStyles = makeStyles(theme => ({
	root: {
		position: 'relative',
	},
	row: {
		display: 'flex',
	},
	inputItem: {
		paddingLeft: '50px',
	},
	divider: {
		marginTop: '30px',
		marginBottom: '20px',
	},
	logo: {
		padding: '16px 16px 4px 16px',
		position: 'relative',
		width: '80px',
		height: '44px',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.customstyles.title,
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	button: {
		...theme.mixins.stickyButton,
	},
	methodsContainer: {
		marginBottom: '24px',
	},
	methodItem: {
		textAlign: 'center',
		padding: '20px',
		border: '2px solid #deeaef',
		borderRadius: '8px',
		borderColor: theme.palette.text.secondary,
		'&:hover': {
			boxShadow: '0 5px 10px 0 rgba(61, 65, 71, 0.16)',
			borderWidth: '2px',
			transform: 'translate(0, 1.5px) scale(1.01)',
		},
	},
	methodItemSelected: {
		borderColor: theme.palette.text.primary,
	},
	methodImg: {
		maxWidth: '120px',
		height: '80px',
		[theme.breakpoints.up('sm')]: {
			height: '120px',
		},
	},
}));

const J2TXWithdrawalCryptoWalletSelectMethod = ({
	form, handleSubmit, invalid, submitting,
}) => {
	const dispatch = useDispatch();

	const EMoneyOut = useSelector(state => formValueSelector(form)(state, 'EMoneyOut'));
	const NonOrderData = useSelector(state => formValueSelector(form)(state, 'NonOrderData'));

	const availableCurrencies = useAvailableCurrencies(NonOrderData.TradeCode);

	const selectMethod = m => {
		dispatch(change(form, 'EMoneyOut', {
			...EMoneyOut,
			Currency: m.AccountCurrency,
			AccountCurrency: m.AccountCurrency,
			SelectTarget: m.SelectTarget,
		}));
	};

	const theme = useTheme();
	const classes = useStyles();
	const translate = useTranslate();

	return (
		<form
			onSubmit={handleSubmit}
			className={classes.root}
		>
			<div className={classes.accountContainer}>
				<div className={classes.row}>
					<div className={classes.logo}>
						<template.firmLogo />
					</div>
					<Field component={FormSelect} name="NonOrderData.TradeCode" label={translate('common_account', 'Account')} disabled>
						<MenuItem value={NonOrderData.TradeCode}>
							<div className={classes.inputContainer}>
								<div className={classes.account}>{NonOrderData.TradeCode}</div>
							</div>
						</MenuItem>
					</Field>
				</div>
			</div>
			<div className={classes.divider}>
				<Divider />
			</div>
			<div className={classes.methodsContainer}>
				<Grid container spacing={3}>
					{availableCurrencies.map(m => (
						<Grid item xs={6} key={m.SelectTarget}>
							<div
								className={classNames({
									[classes.methodItem]: true,
									[classes.methodItemSelected]: EMoneyOut.SelectTarget === m.SelectTarget,
								})}
								onClick={() => selectMethod(m)}
							>
								<BigSvgIcon viewBox="0 0 32 32" icon={m.Icon} />
								<div className={classes.methodTitle}>{m.Title}</div>
							</div>
						</Grid>
					))}
				</Grid>
			</div>
			<Button
				fullWidth
				type="submit"
				variant="contained"
				color="primary"
				disabled={invalid || submitting || !EMoneyOut.SelectTarget}
			>
				{submitting
					? (
						<CircularProgress
							style={{ color: theme.palette.primary.contrastText }}
							size={18}
						/>
					)
					: <R id="common_btn_continue" defaultValue="Continue" />}
			</Button>
		</form>
	);
};

J2TXWithdrawalCryptoWalletSelectMethod.propTypes = {
	form: PropTypes.string.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	invalid: PropTypes.bool.isRequired,
	submitting: PropTypes.bool.isRequired,
};

function withValidation(Component) {
	function ComponentWithValidation(props) {
		const translate = useTranslate();

		const J2TXWithdrawalCryptoWalletSelectMethodValidate = formvalues => {
			const values = formvalues;
			const errors = {};

			if (!values) {
				return errors;
			}

			if (values.EMoneyOut) {
				errors.EMoneyOut = {};

				if (!values.EMoneyOut.Currency) {
					errors.EMoneyOut.Currency = translate('shared_required', 'Required');
				}
				if (!values.EMoneyOut.SelectTarget) {
					errors.EMoneyOut.SelectTarget = translate('shared_required', 'Required');
				}
				if (!values.EMoneyOut.AccountCurrency) {
					errors.EMoneyOut.AccountCurrency = translate('shared_required', 'Required');
				}
			}

			return errors;
		};

		return <Component {...props} validate={J2TXWithdrawalCryptoWalletSelectMethodValidate} />;
	}
	return ComponentWithValidation;
}

export default compose(
	withValidation,
	reduxForm({
		onSubmitSuccess: (result, dispatch, props) => {
			if (props.onSuccess && (typeof props.onSuccess === 'function')) {
				props.onSuccess();
			}
		},
		onSubmitFail: (errors, dispatch, submitError, props) => {
			// eslint-disable-next-line no-underscore-dangle
			if (errors._error && props.onFail) {
				props.onFail();
			}
		},
	}),
)(J2TXWithdrawalCryptoWalletSelectMethod);
