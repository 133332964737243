
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';

import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import compose from 'recompose/compose';

import { Select } from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import { OperationType, OperationTabs } from '@enums';
import { app, userAccounts } from '@redux';

import OperationsList from './OperationsList';

const styles = (theme) => {
	return {
		periodsTabs: {
			minWidth: '0px',
		},
		endOfFlexSelect: {
			alignSelf: 'flex-end',
			minWidth: '250px',
		},
	};
};

function OperationsTable(props) {
	
	const onTableChange = (newTable) => {
		setActiveTable(newTable);
	};

	const [activeTable, setActiveTable] = useState(OperationTabs.TRANSACTION_HISTORY)
	const resolution = useSelector(app.selectors.resolution)
	const {classes} = props;

	return (
		<div>
			{resolution >= app.CONFIG.RESOLUTIONS_ENUM.md
			? 
				<div className={classes.tabs}>
					<Tabs
						onChange={(ev, value) => onTableChange(value)}
						indicatorColor="primary"
						textColor="primary"
						centered
						value={activeTable}
					>
						<Tab
							classes={{ root: classes.periodsTabs }}
							label={<R id={OperationTabs.TRANSACTION_HISTORY}/>}
							value={OperationTabs.TRANSACTION_HISTORY}
						/>
						<Tab
							classes={{ root: classes.periodsTabs }}
							label={<R id={OperationTabs.NON_TRADING_OPERATIONS}/>}
							value={OperationTabs.NON_TRADING_OPERATIONS}
						/>
					</Tabs>
				</div>
			:
				<Box display="flex" justifyContent="center" alignItems="center">
					<FormControl variant="outlined">
						<Select
							value={activeTable}
							onChange={(event) => onTableChange(event.target.value)}
							className={classes.endOfFlexSelect}
						>
							<MenuItem value={OperationTabs.TRANSACTION_HISTORY}>
								<R id={OperationTabs.TRANSACTION_HISTORY}/>
							</MenuItem>
							<MenuItem value={OperationTabs.NON_TRADING_OPERATIONS}>
								<R id={OperationTabs.NON_TRADING_OPERATIONS}/>
							</MenuItem>
						</Select>
					</FormControl>
				</Box>
			}
			{activeTable === OperationTabs.TRANSACTION_HISTORY
			? <OperationsList
				selector={userAccounts.selectors.cashActivities}
				action={userAccounts.actions.getMoneyTransactionsHistory.request} 
				comboBoxOptions={OperationType.CashOperations()}
				/>
			: <OperationsList 
				selector={userAccounts.selectors.nonTraidingActivities}
				action={userAccounts.actions.getNonTradingOrdersHistory.request}
				comboBoxOptions={OperationType.NonTraidingOperations()}
				/> }			
		</div>
	);
}

OperationsTable.defaultProps = {};

export default compose(
	withStyles(styles),
)(OperationsTable);
