/* eslint-disable react/no-did-update-set-state,no-param-reassign,consistent-return */
import React, { useCallback } from 'react';
import { ModalPopups } from '@enums';
import { Modal } from '@components';
import compose from 'recompose/compose';
import { connect, useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { EnableTotpForm, DisableTotpForm, TotpChangeSuccessMessage } from './components';
import { twoFactor, userAccounts } from '@redux';
import { translate } from '@global';

const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
	}
});

const ChangeTwoFactorAuthorizationModal = ({ onClose }) => {
	const classes = useStyles();

	const isEnabled = useSelector(userAccounts.selectors.userInfo)?.IsTotpEnabled;
	const actionFinishedSuccessfully = useSelector(twoFactor.selectors.modalActionFinishedSuccessfully);
	const dispatch = useDispatch();

	const handleClose = useCallback(() => {
		dispatch(twoFactor.actions.handleClose());
		onClose && typeof onClose === 'function' && onClose();
	}, [onClose])

	return (
		<Modal
			title={translate('account_totp_modal_title', 'Two-factor authentication')}
			className={`QA-${ModalPopups.CHANGE_TWO_FACTOR_AUTHORIZATION}`}
			onClose={handleClose}
		>
			<div className={classes.root}>
				{actionFinishedSuccessfully && <TotpChangeSuccessMessage onClose={handleClose} />}
				{!actionFinishedSuccessfully && (isEnabled ? <DisableTotpForm /> : <EnableTotpForm />)}
			</div>
		</Modal>
	);
}
	

export default ChangeTwoFactorAuthorizationModal;