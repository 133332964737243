import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(theme => createStyles({
	inputRoot: {
		padding: '12px 24px 5px',
		backgroundColor: 'rgba(255,255,255,0.05)',
		borderRadius: '8px',
		color: theme.palette.text.primary,
		'& input::placeholder': {
			opacity: 0.75,
		},
		fontSize: theme.typography.pxToRem(18),
	},
	autocompleteInputRoot: {
		'&[class*="MuiInput-root"]': {
			paddingBottom: '5px',
		},
	},
	label: {
		top: '10px',
		left: '24px',
		...theme.customstyles.title,
	},
	additionalText: {
		padding: '0px 24px',
		color: theme.palette.text.secondary,
		...theme.customstyles.body,

	},
	errorText: {
		padding: '0px 24px',
		color: theme.palette.error.main,
		...theme.customstyles.body,

	},
}));

export default function AutocompleteInput({
	autocompleteQuery,
	autocompleteProps,
	onChange: propOnChange,
	value: propValue,
	defaultValue,
	...otherprops
}) {
	const controlled = propValue !== undefined;
	const defaultValueRef = React.useRef(defaultValue);
	const propControlledValue = (controlled ? propValue : defaultValueRef.current) || '';

	// признак что список должен быть раскрыт по логике Autocomplete
	const [openByAutocomplete, setOpenByAutocomplete] = React.useState(false);
	// признак что список должен быть раскрыт в зависимости от смого запроса
	const [openByQuery, setOpenByQuery] = React.useState(false);
	const [options, setOptions] = React.useState([]);
	const [query, setQuery] = React.useState('');
	const [value, setValue] = React.useState(propControlledValue);
	const [inputValue, setInputValue] = React.useState(propControlledValue);
	const [queryLoading, setQueryLoading] = React.useState(false);
	const open = openByQuery && openByAutocomplete;
	const loading = open && queryLoading;
	const classes = useStyles();
	const timerRef = React.useRef(null);

	React.useEffect(() => {
		if (controlled) {
			setInputValue(propValue);
			setValue(propValue);
		}
	}, [propControlledValue]);

	React.useEffect(() => {
		if (query.length < autocompleteQuery.minQueryLength || query.length > autocompleteQuery.maxQueryLength) {
			setQueryLoading(false);
			setOptions([]);
			setOpenByQuery(false);
			return;
		}
		setOpenByQuery(true);
		setQueryLoading(true);
	
		const getOptions = autocompleteQuery.getOptions(query);
		if (Array.isArray(getOptions))
		{
			setOptions(getOptions);
			if (!timerRef.current) {
				setQueryLoading(false);
			}
			return;
		}
		getOptions.then(
			resultOptions => setOptions(Array.isArray(resultOptions) ? resultOptions : []),
			() => setOptions([]),
		).then(() => {
			if (!timerRef.current) {
				setQueryLoading(false);
			}
		});
	}, [query]);

	const onInputChange = (e, val, reason) => {
		if (reason === 'reset') {
			return;
		}
		setInputValue(val);
		if (reason === 'input') {
			if (timerRef.current) {
				window.clearTimeout(timerRef.current);
				timerRef.current = null;
			}
			timerRef.current = window.setTimeout(() => {
				timerRef.current = null;
				setQuery(val);
			}, 500);
		}
	};

	const onChange = (e, newSelectedOption) => {
		setValue(newSelectedOption);
		const inpVal = newSelectedOption ? autocompleteProps.getOptionLabel(newSelectedOption) : '';
		setInputValue(inpVal);
		if (propOnChange) {
			propOnChange(inpVal);
		}
		if (autocompleteProps.onChange) {
			autocompleteProps.onChange(newSelectedOption);
		}
	};

	const filterOptions = autocompleteProps.filterOptions
		|| (autocompleteQuery.getOptions && ((prmOptions, prmState) => prmOptions)) // при загрузке уже не нужно фильтровать
		|| undefined;

	return (
		<Autocomplete
			open={open}
			onOpen={(e) => {
				setOpenByAutocomplete(true);
			}}
			onClose={() => {
				setOpenByAutocomplete(false);
			}}
			classes={{ inputRoot: classes.autocompleteInputRoot }}
			getOptionSelected={autocompleteProps.getOptionSelected}
			renderOption={autocompleteProps.renderOption}
			getOptionLabel={option => ((option && autocompleteProps.getOptionLabel(option)) || '')}
			options={options}
			loading={loading}
			onChange={onChange}
			onInputChange={onInputChange}
			freeSolo
			value={value}
			inputValue={inputValue}
			filterOptions={filterOptions}
			renderInput={(params) => {
				const params2 = {
					...params,
					InputProps: {
						...params.InputProps,
						endAdornment: (
							<React.Fragment>
								{loading ? <CircularProgress color="inherit" size={20} /> : null}
								{(loading || (options && options.length > 0 && params.inputProps.value)) &&
									params.InputProps.endAdornment
								}
							</React.Fragment>
						),
						disableUnderline: true,
						classes: {
							...(params.InputProps.classes || {}),
							root: classNames(params.InputProps.classes && params.InputProps.classes.root, classes.inputRoot),
						},
					},
					InputLabelProps: {
						...params.InputLabelProps,
						classes: {
							...(params.InputLabelProps.classes || {}),
							formControl: classes.label,
						},
					},
					FormHelperTextProps: {
						classes: {
							root: classNames({
								[classes.additionalText]: !otherprops.error,
								[classes.error]: otherprops.error,
							}),
						},
					},
				};

				return (
					<TextField
						{...otherprops}
						onChange={(e) => {
							if (propOnChange) {
								propOnChange(e.target.value);
							}
						}}
						{...params2}
					/>
				);
			}}
		/>
	);
}

AutocompleteInput.propTypes = {
	autocompleteQuery: PropTypes.shape({
		minQueryLength: PropTypes.number,
		maxQueryLength: PropTypes.number,
		/**
		 * Функция возвращает либо список опций, либо Promise который возвращает список опций (inputValue) => options | Promise=>options.
		 * Если возвращается промис, все ошибки получения данных должны в нем обрабатываться.
		 * Данный компонент проигнорирует оставшиеся ошибки и установит в случае ошибок пустой массив
		 * inputValue - введенный текст, по которому должен выполняться поиск
		 */
		getOptions: PropTypes.func,
	}).isRequired,
	autocompleteProps: PropTypes.shape({
		/**
		 * Функция для проверки что опция выбрана  (option, value) => bool
		 * option - элемент списка
		 * value - текущее выбранное значение (в элементе input)
		 */
		getOptionSelected: PropTypes.func.isRequired,
		/**
		 * Функция для отрисовки элемента в выпадающем списке (option, state) => Node
		 * option - элемент списка
		 * state - состояние элемента { inputValue, getOptionLabel}
		 */
		renderOption: PropTypes.func.isRequired,
		/**
		 * Функция для получения значения (option) => string
		 * option - элемент списка
		 */
		getOptionLabel: PropTypes.func.isRequired,
		/**
		 * Событие при  для получения значения (valueOption) => string
		 * valueOption - выбранный элемент списка. Может быть null, если выбраное значение очищено
		 */
		onChange: PropTypes.func,
		/**
		 * Функция для фильтрации списка опций (options, state) => options
		 * options - массив опций в списка
		 * state - состояние элемента { inputValue, getOptionLabel}
		 */
		filterOptions: PropTypes.func,
	}).isRequired,
	/**
	 * If `true`, the input will indicate an error.
	 */
	error: PropTypes.bool,
	/**
	 * Событие при изменении значения (valueText) => string
	 * valueText - строковое значение введенное в поле или текстовое отображение выбранного элемента
	 *  (то, которое попадает в текстовое поле после выбора элемента, т.е. определяется getOptionLabel)
	 */
	onChange: PropTypes.func,
	/**
	 * The default input value for uncontrolled component
	 */
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	/**
	 * The input value, required for a controlled component.
	 */
	value: PropTypes.oneOfType([PropTypes.string, 		PropTypes.number]),
};
AutocompleteInput.defaultProps = {
	error: false,
	onChange: undefined,
	defaultValue: undefined,
	value: undefined,
};
