import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { profile, userAccounts } from '@redux';
import {
	ChangeLink,
	ExpansionPanel,
} from '@components';
import { InputEditableItem } from '@shared';

const useActions = () => {
	const dispatch = useDispatch();
	return ({
		showChangeMarketDataSubscriberStatusModal: () => dispatch(profile.actions.showChangeMarketDataSubscriberStatusModal()),
	});
};

const MarketDataSubscriberPanel = (props) => {
	const {
		onToggle,
		panelId,
		isOpen,
	} = props;
	const userProfile = useSelector(profile.selectors.profile) || {};
	const hasOpenAccount = useSelector(userAccounts.selectors.hasOpenAccount);
	const actions = useActions();

	return (
		<ExpansionPanel
			header="Market Data Subscriber"
			id={panelId}
			onToggleOpen={onToggle}
			isOpen={isOpen}
		>
			<InputEditableItem
				title="Market Data Subscriber Status"
				value={userProfile.MarketDataStatus}
				valueIfEmpty="None"
				editControl={ hasOpenAccount &&
					<ChangeLink onClick={actions.showChangeMarketDataSubscriberStatusModal} disabled={userProfile.PendingOrders.MarketDataStatus} />
				}
			/>
		</ExpansionPanel>
	);
};

MarketDataSubscriberPanel.propTypes = {
	onToggle: PropTypes.func.isRequired,
	panelId: PropTypes.string.isRequired,
	isOpen: PropTypes.bool,
};

MarketDataSubscriberPanel.defaultProps = {
	isOpen: false,
};

export default MarketDataSubscriberPanel;
