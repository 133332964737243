import { SuccessMessage } from '@components';
import { translate } from '@global';
import { twoFactor } from '@redux';
import React from 'react';
import { useSelector } from 'react-redux';

const TotpChangeSuccessMessage = ({ onClose }) => {
    const isEnabling = useSelector(twoFactor.selectors.modalActionIsEnabling);

    return (
        <SuccessMessage
            onClick={onClose}
            text={isEnabling
                ? translate("account_totp_enable_success_message", "You've successfully enabled second factor authentication.")
                : translate("account_totp_disable_success_message", "You've successfully disabled second factor authentication.")}
        />
    )
}

export default TotpChangeSuccessMessage;