import React from 'react';
import classNames from 'classnames';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { SvgIcon } from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';

const useStyles = makeStyles(() => ({
	gridContainer: {
		'&>$gridItem': { padding: '0px' },
	},
	gridItem: { padding: '0px' },
	currency: {},
}));

export default function LogoIcon(props) {
	const { hideLabel, currency } = props;
	function getIconName() {
		switch (currency) {
			case 'USD':
				return 'USDIcon';
			case 'EUR':
				return 'EUROIcon';
			case 'BTC':
				return 'BitcoinIcon';
			case 'ETH':
				return 'EtheriumIcon';
			case 'LTC':
				return 'LTCIcon';
			case 'XRP':
				return 'XRPIcon';
			case 'USDT':
				return 'USDTIcon';
			case 'DOGE':
				return 'DOGEIcon';
			case 'ADA':
				return 'ADAIcon';
			case 'BNB':
				return 'BNBIcon';
			default:
				return 'OtherIcon';
		}
	}

	function getFullCurrencyName() {
		switch (currency) {
			case 'USD':
			case 'EUR':
				return '';
			case 'BTC':
				return <R id="common_bitcoin" defaultValue="Bitcoin" />;
			case 'ETH':
				return <R id="common_etherium" defaultValue="Etherium" />;
			case 'USDT':
				return <R id="common_tether" defaultValue="Tether" />;
			case 'LTC':
				return <R id="common_litecoin" defaultValue="Litecoin" />;
			case 'XRP':
				return <R id="common_ripple" defaultValue="Ripple" />;
			case 'DOGE':
				return <R id="common_dogecoin" defaultValue="Dogecoin" />;
			case 'ADA':
				return <R id="common_cardano" defaultValue="Cardano" />;
			default:
				return currency;
		}
	}

	const classes = useStyles(props);
	return (
		<Grid style={{ width: '70px', maxHeight: '40px', margin: 0  }} container spacing={1} className={classes.gridContainer}>
			<Grid item style={{ height: '24px'}} xs={6} className={classes.gridItem}>
				<SvgIcon viewBox="0 0 32 32" fontSize="medium" icon={getIconName()} />
			</Grid>
			<Grid item xs={6} className={classNames(classes.gridItem, classes.currency)}>
				{currency}
			</Grid>
			{!hideLabel && getFullCurrencyName() &&
				<Grid item xs={12} className={classes.gridItem}>
					<Typography color="textSecondary" variant="caption">{getFullCurrencyName()}</Typography>
				</Grid>
			}
		</Grid>
	);
}
