import { fork, put, select, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
	createApiKey,
	getApiKeys,
	revokeApiKey,
} from '@api';
import { ModalPopups } from '@enums';
import { addHashToUrl, removeHashFromUrl } from '@global';
import { BaseGetSagaHandler } from '@redux';
import actions from './actions';


function* watchGetApiKeysRequest() {
	yield takeEvery(actions.getApiKeys.REQUEST, BaseGetSagaHandler, {
		apiMethod: getApiKeys,
		errorText: 'Get Clients',
		handler: actions.getApiKeys,
	});
}
function* revokeApiKeySagaHandler({ payload: { onEndRequest, ...others } }) {
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: revokeApiKey,
		errorText: 'Api Key Revoke',
		handler: actions.revokeApiKey,
		onEndRequest,
	}, { payload: others });

	if (isSuccess) {
		yield put(actions.getApiKeys.request());
	}
}

function* createApiKeyRequestSaga({ payload }) {
	payload.MT5TradeCodesList = payload.MT5TradeCodesList.filter(v => !!v);
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: createApiKey,
		errorText: 'Create Api Key',
		handler: actions.createApiKey,
	}, { payload });

	if (isSuccess) {
		yield put(actions.getApiKeys.request());
	}
}

function GetModalPopupType(type) {
	switch (type) {
	case actions.SHOW_CREATE_APIKEY_MODAL:
		return ModalPopups.CREATE_APIKEY;
	default:
		return '';
	}
}
function* showModal({ type }) {
	const location = yield select(state => state.router.location);
	yield put(push(addHashToUrl(location, GetModalPopupType(type))));
}

function* hideModal({ payload }) {
	const location = yield select(state => state.router.location);
	yield put(push(removeHashFromUrl(location, payload)));
}

function* watchRevokeApiKeyRequest() {
	yield takeEvery(actions.revokeApiKey.REQUEST, revokeApiKeySagaHandler);
}

function* watchCreateApiKeyRequest() {
	yield takeEvery(actions.createApiKey.REQUEST, createApiKeyRequestSaga);
}

function* watchShowModal() {
	yield takeEvery([
		actions.SHOW_CREATE_APIKEY_MODAL,
	], showModal);
}

function* watchHideModal() {
	yield takeEvery(actions.HIDE_MODAL, hideModal);
}
// Root saga
const rootSaga = [
	fork(watchGetApiKeysRequest),
	fork(watchRevokeApiKeyRequest),
	fork(watchCreateApiKeyRequest),
	fork(watchShowModal),
	fork(watchHideModal),
];

export default {
	rootSaga,
};
