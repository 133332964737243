import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ModalPopups } from '@enums';
import {
	reduxForm,
	Field,
	formValueSelector,
} from 'redux-form';
import {
	Button,
	FileInput,
	FormInput,
	Modal,
	SuccessMessage,
} from '@components';
import { dashboard, notifications } from '@redux';

const styles = theme => ({
	root: {
		display: 'block',
		position: 'relative',
	},
	progress: {
		display: 'flex',
		justifyContent: 'center',
	},
	title: {
		...theme.customstyles.subheading,
		color: theme.palette.text.primary,
		wordWrap: 'break-word',
	},
	body: {
		...theme.customstyles.body,
		color: theme.palette.text.primary,
		marginTop: '16px',
		wordWrap: 'break-word',
	},
	date: {
		...theme.customstyles.body,
		color: theme.palette.text.secondary,
	},
	button: {
		paddingTop: '12px',
	},
});

const mapStateToProps = state => ({
	message: notifications.selectors.message(state),
});

const mapDispatchToProps = (dispatch, props) => ({
	actions: {
		notificationsOpen: () => dispatch(dashboard.actions.notificationsOpen()),
	},
});

class MessageModal extends React.Component {
	closeModal = (event) => {
		if (this.props.onClose) {
			this.props.onClose(event);
		}
		this.props.actions.notificationsOpen();
	}

	render() {
		const {
			classes,
			message,
		} = this.props;

		return (
			<Modal
				title=""
				onClose={this.closeModal}
				bgColor="#f9fcfd"
				textColor="#345464"
				className={`QA-${ModalPopups.MESSAGE}`}
			>
				{message &&
					<div>
						<div className={classes.title}>{message.Title}</div>
						<div className={classes.date}>{moment.utc(message.MessageDate).local().format('D MMM YYYY HH:mm')}</div>
						{/* eslint-disable-next-line react/no-danger */}
						<div className={classes.body} dangerouslySetInnerHTML={{ __html: message.Full }} />
					</div>
				}
			</Modal>
		);
	}
}

MessageModal.defaultProps = {
	message: null,
};

MessageModal.propTypes = {
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	message: PropTypes.object,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(MessageModal);
