import React from 'react';
import compose from 'recompose/compose';
import {
	Modal,
	Button,
	FormInputPassword,
	withTranslate,
} from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import { snackbar, userAccounts } from '@redux';
import { Field, reduxForm, reset } from 'redux-form';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
	modalContainer: {
		maxWidth: '800px',
	},
	requirement: {
		color: theme.palette.text.secondary,
		padding: '0 12px',
		'&:last-of-type': {
			marginBottom: '30px',
		},
	},
}));

const configuration = {
	minLength: 8,
	maxLength: 15,
	// при добавлении спецсимволов, особенно "-", в список убедиться что регулярное выражение не сломается
	allowedSymbols: ['!', '#', '$', '@', '?'],
};

const allowedSymbolsRegExp = new RegExp(`^[a-zA-Z0-9${configuration.allowedSymbols.join('')}]+$`);

const CY_CHANGE_MT4_PASSWORD_FORM = 'CY_CHANGE_MT4_PASSWORD_FORM';

const CYChangeMt4PasswordModal = ({ onClose, handleSubmit, invalid, submitting, translate }) => {
	const classes = useStyles();
	const theme = useTheme();

	const currentAccount = useSelector(userAccounts.selectors.accountInfo);

	const title = (
		<span>
			<R id="common_password_change" defaultValue="Password change" />
			{' '}
			{currentAccount?.TradeCode}
			{' - '}
			{currentAccount?.TradeLogin}
		</span>
	);

	return (
		<Modal
			title={title}
			onClose={onClose}
			bgColor={theme.palette.background.paper}
			textColor={theme.palette.text.primary}
			classes={{ container: classes.modalContainer }}
		>
			<form onSubmit={handleSubmit(userAccounts.actions.changeMt4Password)}>
				<Field
					component={FormInputPassword}
					name="NewPassword"
					placeholder={translate('common_password', 'Password')}
					// предотвращает предложение браузера сохранить этот пароль как пароль от ЛК
					inputProps={{ autoComplete: 'one-time-code' }}
				/>
				<Field
					component={FormInputPassword}
					name="RepeatPassword"
					placeholder={translate('common_repeat_password', 'Repeat password')}
					// предотвращает предложение браузера сохранить этот пароль как пароль от ЛК
					inputProps={{ autoComplete: 'one-time-code' }}
				/>
				<Box className={classes.requirement} mt={2}>
					<R
						id="change_password_min_length"
						defaultValue={`Minimum length of ${configuration.minLength} symbols`}
						translateParameters={{ minLength: configuration.minLength }}
					/>
				</Box>
				<Box className={classes.requirement}>
					<R
						id="change_password_max_length"
						defaultValue={`Maximum length of ${configuration.maxLength} symbols`}
						translateParameters={{ maxLength: configuration.maxLength }}
					/>
				</Box>
				<Box className={classes.requirement}>
					<R
						id="change_password_latin"
						defaultValue="Password must contain Latin characters"
					/>
				</Box>
				<Box className={classes.requirement}>
					<R
						id="change_password_lower"
						defaultValue="Password must contain lowercase characters"
					/>
				</Box>
				<Box className={classes.requirement}>
					<R
						id="change_password_upper"
						defaultValue="Password must contain uppercase characters"
					/>
				</Box>
				<Box className={classes.requirement}>
					<R
						id="change_password_digits"
						defaultValue="Password must contain digits"
					/>
				</Box>
				<Box className={classes.requirement}>
					<R
						id="allowed_special_symbols"
						defaultValue={`Allowed special symbols: ${configuration.allowedSymbols}`}
						translateParameters={{ symbols: configuration.allowedSymbols }}
					/>
				</Box>
				<Button
					type="submit"
					variant="contained"
					color="primary"
					fullWidth
					disabled={invalid || submitting}
				>
					{submitting ? (
						<CircularProgress
							style={{ color: theme.palette.common.white }}
							size={18}
						/>
					) : (
						<R id="common_change_password" defaultValue="Change password" />
					)}
				</Button>
			</form>
		</Modal>
	);
};

export default compose(
	withTranslate,
	reduxForm({
		form: CY_CHANGE_MT4_PASSWORD_FORM,
		initialValues: {
			NewPassword: '',
			RepeatPassword: '',
		},
		onSubmitSuccess: (_, dispatch, props) => {
			dispatch(reset(CY_CHANGE_MT4_PASSWORD_FORM));
			props.onClose();
			dispatch(snackbar.actions.showMessage({
				type: 'success',
				text: props.translate('common_password_change_success', 'Password has been changed successfully'),
			}));
		},
		onSubmitFail: (_, dispatch) => {
			dispatch(reset(CY_CHANGE_MT4_PASSWORD_FORM));
		},
		validate: values => {
			const errors = {};
			if (values.NewPassword !== values.RepeatPassword) {
				errors.RepeatPassword = 'passwords not match';
			}
			Object.entries(values).forEach(([key, value]) => {
				// текущая модалка не предусматривает вывод ошибки, только красную обводку поля
				// при изменении дизайна обернуть ошибки в дикворды
				if (!value) {
					errors[key] = 'required';
					return errors;
				}
				if (value.length < configuration.minLength) {
					errors[key] = `minimum length is ${configuration.minLength} symbols`;
					return errors;
				}
				if (value.length > configuration.maxLength) {
					errors[key] = `maximum length is ${configuration.maxLength} symbols`;
					return errors;
				}
				if (!value.match(/[a-z]/gu)) {
					errors[key] = 'password must containt at least 1 lowercase latin symbol';
					return errors;
				}
				if (!value.match(/[A-Z]/gu)) {
					errors[key] = 'password must containt at least 1 uppercase latin symbol';
					return errors;
				}
				if (!value.match(/[0-9]/gu)) {
					errors[key] = 'password must containt at least 1 digit';
					return errors;
				}
				if (!value.match(allowedSymbolsRegExp)) {
					errors[key] = 'password contains not allowed characters';
					return errors;
				}
			});

			return errors;
		},
	}),
)(CYChangeMt4PasswordModal);
