import React from 'react';

const Svg = (
	<svg viewBox="0 0 0.84 0.84" xmlns="http://www.w3.org/2000/svg">
		<g fill="none">
			<path fill="#F3BA2F" d="M.84.42a.42.42 0 0 1-.42.42A.42.42 0 0 1 0 .42a.42.42 0 0 1 .84 0z" />
			<path fill="#FFF" d="M.318.378.42.276l.102.102.059-.059L.42.158.259.319l.059.059zM.158.42.217.361.276.42.217.479.158.42zm.161.042L.42.564.522.462l.059.059L.42.682.259.521.318.462zM.564.42.623.361.682.42.623.479.564.42zM.48.42.42.48.36.42.371.409.376.404.42.36l.06.06z" />
		</g>
	</svg>
);


export default Svg;
