import actions from './actions';
import auth from '../auth';

const initialState = {
	tokens: {
		submitting: false,
		loaded: false,
		items: [],
		isAbleToCreate: false,
	},
	modals: {},
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
	case actions.getApiKeys.REQUEST:
		return {
			...state,
			tokens: {
				...state.tokens,
				submitting: true,
				isAbleToCreate: false,
			},
		};
	case actions.getApiKeys.SUCCESS:
		return {
			...state,
			tokens: {
				loaded: true,
				submitting: false,
				items: [
					...payload.response.Tokens,
				],
				isAbleToCreate: payload.response.IsAbleToCreate
			},
		};
	case actions.getApiKeys.FAILURE:
		return {
			...state,
			tokens: {
				...initialState.clients,
				submitting: false,
				loaded: true,
				isAbleToCreate: false,
			},
		};

	case actions.createApiKey.SUCCESS: {
		return {
			...state,
			modals: {
				...state.modals,
			},
			createdSecretKey: payload.response.JwtToken,
		};
	}
	case actions.createApiKey.FAILURE:
	case actions.SHOW_CREATE_APIKEY_MODAL:
		return {
			...state,
			modals: {
				...state.modals,
			},
			createdSecretKey: null,
		};
	case auth.actions.LOGOUT_SUCCESS:
		return { ...initialState };
	default:
		return state;
	}
};
