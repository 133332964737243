import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { constants, env } from '@config';
import { toUsaMoneyFormat } from '@global';
import { userAccounts } from '@redux';
import { R } from '@dev2t/react-ui-kit/src/components';


const useStyles = makeStyles(theme => ({
	root: {
		borderRadius: '10px',
		// backgroundColor: '#ffffff',
		boxShadow: '0 6px 16px 0 rgba(61, 65, 71, 0.12)',
		padding: '25px 24px',
		// margin: '0 -25px',
	},
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			flexFlow: 'wrap',
			alignItems: 'flex-start',
		},
	},
	leftColumn: {
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	middleColumn: {
		[theme.breakpoints.down('sm')]: {
			order: 1,
		},
	},
	chartThumbnailPeriod: {
		...theme.customstyles.body,
		color: theme.palette.text.secondary,
		textAlign: 'center',
		marginTop: '-6px',
	},
	rightColumn: {
		textAlign: 'right',
		[theme.breakpoints.down('sm')]: {
			textAlign: 'left',
			marginTop: '24px',
		},
	},
	value: {
		...theme.customstyles.subheading,
		color: theme.palette.text.primary,
	},
	valueContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	percentsChange: {
		marginLeft: '24px',
	},
	description: {
		...theme.customstyles.body,
		color: theme.palette.text.secondary,
	},

}));

export const J2TAccountBriefingPanel = () => {

	const classes = useStyles();
	const currentAccountData = useSelector((state) => userAccounts.selectors.currentAccountData(state));
	const freeMargin = currentAccountData.baseParams && currentAccountData.baseParams.FreeMargin;
	const balance = currentAccountData.baseParams && currentAccountData.baseParams.Balance;
	const marketValue = currentAccountData.baseParams && currentAccountData.baseParams.MarketValue;

	return (
		<div className={classes.root}>
			<div className={classes.container}>
				<div className={classes.leftColumn}>
					<div className={classes.valueContainer}>
						<div className={classes.value}>
							{toUsaMoneyFormat(balance)}
						</div>
					</div>
					<div className={classes.description}>
						{env.firm === constants.LIME_NSR ? 'Cash' : <R id="shared_balance" defaultValue="Balance" />}
					</div>
				</div>
				<div className={classes.rightColumn}>
					<div className={classes.valueContainer}>
						<div className={classes.value}>
							{env.firm === constants.LIME_NSR 
								? toUsaMoneyFormat(marketValue)
								: toUsaMoneyFormat(freeMargin)
							}
						</div>
					</div>
					<div className={classes.description}>
						{env.firm === constants.LIME_NSR ? 'Securities Market value' : <R id="shared_freemargin" defaultValue="Free Margin" />}
					</div>
				</div>
			</div>
		</div>

	);
}