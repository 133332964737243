import React from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
	reduxForm,
	Field,
	formValueSelector,
} from 'redux-form';
import { withStyles, withTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';

import {
	FormSelect,
	Divider,
	Button,
} from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import { translate } from '@global';
import { userAccounts, app } from '@redux';
import { template } from '@templates';

const styles = theme => ({
	root: {
		position: 'relative',
	},
	row: {
		display: 'flex',
	},
	inputItem: {
		paddingLeft: '50px',
	},
	divider: {
		marginTop: '30px',
		marginBottom: '20px',
	},
	logo: {
		padding: '16px 16px 4px 16px',
		position: 'relative',
		width: '80px',
		height: '44px',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.customstyles.title,
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	button: {
		...theme.mixins.stickyButton,
	},
	methodsContainer: {
		marginBottom: '24px',
	},
	methodItem: {
		height: '100%',
		textAlign: 'center',
		padding: '20px',
		border: '2px solid #deeaef',
		borderRadius: '8px',
		'&:hover': {
			boxShadow: '0 5px 10px 0 rgba(61, 65, 71, 0.16)',
			borderWidth: '2px',
			transform: 'translate(0, 1.5px) scale(1.01)',
		},
	},
	methodItemSelected: {
		borderColor: theme.palette.text.secondary,
	},
	methodImg: {
		maxWidth: '120px',
		height: '80px',
		[theme.breakpoints.up('sm')]: {
			height: '120px',
		},
	},
	buttonCenter: {
		margin: '0 auto',
		width: '30%',
		display: 'block',
	},

});

const mapStateToProps = (state, ownProps) => {
	const currentTradeAccount = userAccounts.selectors.currentTradeAccount(state);
	const language = app.selectors.language(state);

	const OpenDemoAccountOut = formValueSelector(ownProps.form)(state, 'OpenDemoAccountOut');
	return {
		OpenDemoAccountOut,
		currentTradeAccount,
		language,
	};
};
const getMethods = () => [
	{
		SelectedBalance: 5000,
		Title: '5000$',
	},
	{
		SelectedBalance: 10000,
		Title: '10000$',
	},
	{
		SelectedBalance: 50000,
		Title: '50000$',
	},
	{
		SelectedBalance: 100000,
		Title: '100000$',
	},
	{
		SelectedBalance: 1000000,
		Title: '1000000$',
	},
];

class CYOpenDemoAccountChooseBalance extends React.Component {
	static validate(formvalues, props) {
		const values = formvalues;
		const errors = {};

		if (!values) {
			return errors;
		}

		if (values.OpenDemoAccountOut) {
			errors.OpenDemoAccountOut = {};

			if (!values.OpenDemoAccountOut.SelectedBalance) {
				errors.OpenDemoAccountOut.SelectedBalance = 'required';
			}
		}

		return errors;
	}
	selectMethod = (m) => {
		const { OpenDemoAccountOut, change } = this.props;
		change('OpenDemoAccountOut', {
			...OpenDemoAccountOut,
			SelectedBalance: m.SelectedBalance,
		});
	};

	render() {
		const {
			classes,
			fromUserAccount,
			handleSubmit,
			submitSucceeded,
			currentTradeAccount,
			theme,
			values,
			OpenDemoAccountOut,
			...props
		} = this.props;

		return (
			<form
				onSubmit={handleSubmit}
				className={classes.root}
			>
				<div className={classes.accountContainer} />
				<div className={classes.divider}>
					<Divider />
				</div>
				<div className={classes.methodsContainer}>
					<Grid container spacing={2} justifyContent="space-around">
						{getMethods().map(m => (
							<Grid item key={m.SelectedBalance} >
								<div
									className={classNames({
										[classes.methodItem]: true,
										[classes.methodItemSelected]: OpenDemoAccountOut.SelectedBalance === m.SelectedBalance,
									})}
									onClick={() => this.selectMethod(m)}
								>
									<div><h2>{m.Title}</h2></div>
								</div>
							</Grid>

						))}
					</Grid>
				</div>
				<div className={classes.buttonCenter}>
					<Button
						fullWidth
						type="submit"
						variant="contained"
						color="primary"
						disabled={props.invalid || props.submitting || !OpenDemoAccountOut.SelectedBalance}
					>
						{props.submitting ?
							<CircularProgress
								style={{ color: theme.palette.common.white }}
								size={18}
							/>
							: <R id="common_confirm_order" defaultValue="Confirm" />
						}
					</Button>
				</div>
			</form>
		);
	}
}

CYOpenDemoAccountChooseBalance.propTypes = {
	classes: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	withTheme,
	connect(mapStateToProps, null),
	reduxForm({
		validate: CYOpenDemoAccountChooseBalance.validate,
	}),
)(CYOpenDemoAccountChooseBalance);
