
import { OperationType } from '@enums';
import { Select, DateRangePicker } from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles, withTheme } from '@material-ui/core/styles';
import React from 'react';
import compose from 'recompose/compose';
import Box from '@material-ui/core/Box';

const styles = (theme) => ({
	endOfFlexSelect: {
		alignSelf: 'flex-end',
		minWidth: '250px',
	}
});

function OperationsTableFilter(props) {
	
	const { 
		operationType, 
		onOperationTypeChange,

		startDate,
		onStartDateChange,

		endDate,
		onEndDateChange,

		classes,
		
		selectFields
	} = props;

	return (
		<Box display="flex" justifyContent="space-around" alignItems="center" flexWrap="wrap" m={2}>
			<DateRangePicker
				endDate={endDate}
				startDate={startDate}
				onEndDateChange={onEndDateChange}
				onStartDateChange={onStartDateChange}
			/>

			<FormControl variant="outlined">
				<Select
					value={operationType}
					onChange={(event) => onOperationTypeChange(event.target.value)}
					className={classes.endOfFlexSelect}
					fullWidth
				>
					{selectFields.map((x, index) => (
						<MenuItem value={x} key={index}>
							<R id={OperationType.getTranslateId(x)}/>
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</Box>
	);
}

export default compose(withTheme, withStyles(styles))(OperationsTableFilter);
