import { createApiActions } from '../extensions';
import { createFormAction } from 'redux-form-saga';

const prefix = "TWO_FACTOR"

const actions = {
	getSecretAndQrCode: createApiActions(`${prefix}/GET_SECRET_AND_QR_CODE`),
	enableTwoFactor: createFormAction(`${prefix}/ENABLE`),
	disableTwoFactor: createFormAction(`${prefix}/DISABLE`),

	// Используются только только SUCCESS и FAILURE. REQUEST не используется, т.к. речь об автоматической загрузке компонентом <img>
	loadImage: createApiActions(`${prefix}/LOAD_IMAGE`),

	HANDLE_CLOSE: `${prefix}/HANDLE_CLOSE`,
	handleClose: () => ({
		type: actions.HANDLE_CLOSE,
	}),
}

export default actions;