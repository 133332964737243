import moment from 'moment-timezone';
import { constants, env } from '@config';

export function toUsaFormat(num, precision = 2) {
	return num.toLocaleString('en-US', { minimumFractionDigits: precision, maximumFractionDigits: precision });
}

export function	toUsaBigNumFormat(num) {
	if (Math.abs(num) >= 1.0e+9) {
		return `${toUsaFormat(num / 1.0e+9)}B`;
	} else if (Math.abs(num) >= 1.0e+6) {
		return `${toUsaFormat(num / 1.0e+6)}M`;
	} else if (Math.abs(num) >= 1.0e+3) {
		return `${toUsaFormat(num / 1.0e+3)}K`;
	}
	return `${toUsaFormat(num)}`;
}

export function	toUsaSizeFormat(num) {
	if (Math.abs(num) >= 1.0e+9) {
		return `${toUsaFormat(num / 1.0e+9, 0)} Bb`;
	} else if (Math.abs(num) >= 1.0e+6) {
		return `${toUsaFormat(num / 1.0e+6, 0)} Mb`;
	} else if (Math.abs(num) >= 1.0e+3) {
		return `${toUsaFormat(num / 1.0e+3, 0)} Kb`;
	}
	return `${toUsaFormat(num, 0)} bytes`;
}

export function	toUsaDateFormat(date) {
	return moment(date).format('D MMM YYYY');
}

export function	toUsaDateFormatFromUtc(date, format = 'D MMM YYYY') {
	return date ? moment.utc(date).local().format(format) : '';
}

export function	toUsaMoneyFormat(num, currency = 'USD', precision = 2) {
	return typeof num !== 'number' ? '' :
		num.toLocaleString('en-US', {
			minimumFractionDigits: precision, maximumFractionDigits: precision, currency, style: 'currency',
		});
}

export function	toMoneyFormat(summ, language, currency = 'USD', precision = 2) {
	if ([constants.LIME_LME, constants.FIRM_J2TX].every(x => x !== env.firm)) {
		return toUsaMoneyFormat(summ, currency, precision);
	}
	const precisionEffectiveMax = precision == null ? 6 : precision;
	const precisionEffectiveMin = precision == null ? undefined : precision;
	return typeof summ !== 'number' ? '' :
		summ.toLocaleString(language, {
			minimumFractionDigits: precisionEffectiveMin, maximumFractionDigits: precisionEffectiveMax,
		});
}

export function	formatDateAsTimeZone(date, format = 'L LTS') {
	return date ? moment.tz(date, 'America/New_York').format(format) : '';
}

export function	formatDateAsUtc(date, format = 'L') {
	return date ? moment.utc(date).format(format) : '';
}

export function	parseMomentDate(date) {
	if ((typeof date) === 'object') {
		return date.toISOString();
	}
	return undefined;
}

export function	dateDiffInDays(firstDate, secondDate) {
	const MS_PER_DAY = 1000 * 60 * 60 * 24;
	return Math.floor((firstDate - secondDate) / MS_PER_DAY);
}

export function	getDateTimeEst(date = new Date()) {
	if (typeof date === 'string') {
		return new Date(new Date(date).toLocaleString('en-US', {
			timeZone: 'America/New_York',
		}));
	}

	return new Date(date.toLocaleString('en-US', {
		timeZone: 'America/New_York',
	}));
}
