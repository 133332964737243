import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch } from 'react-redux';
import { SvgIcon } from '@components';
import { auth } from '@redux';


const LogOutButton = () => {
	const dispatch = useDispatch();
	const [logoutProgress, setLogoutProgress] = useState(false);

	const actions = {
		Logout: () => dispatch(auth.actions.logout()),
	};

	const handleLogout = () => {
		setLogoutProgress(true);
		actions.Logout();
	};

	return (
		<IconButton onClick={handleLogout} className="QA-ExitButton">
			<SvgIcon icon="Logout" submitting={logoutProgress} />
		</IconButton>);
};

export default LogOutButton;
