import { call, fork, put, select, takeEvery, delay } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
	sendNonTradingCommonOrder,
	sendNonTradingIdentityOrder,
	sendNonTradingLeverageOrder,
	sendNonTradingRegistrationOrder,
	sendNonTradingOpenAccount,
	sendNonTradingOpenDemoAccount,
} from '@api';
import { removeHashFromUrl, cabinetLog } from '@global';
import { BaseGetSagaHandler, userAccounts } from '@redux';
import actions from './actions';


function* hideNonTradingOrderModal({ payload }) {
	const location = yield select(state => state.router.location);
	yield put(push(removeHashFromUrl(location, payload)));
}

function* sendNonTradingOrderCommonSubmitSaga({ payload }) {
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: sendNonTradingCommonOrder,
		errorText: 'Free form order',
		handler: actions.sendNonTradingOrderCommonForm,
	}, { payload });

	if (isSuccess) {
		yield* userAccounts.saga.reloadTransactionHistory(payload.TradeCode, payload.ClearingFirm);
	}
}

function* sendNonTradingOrderIdentitySubmitSaga({ payload }) {
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: sendNonTradingIdentityOrder,
		errorText: 'Identification document uploading',
		handler: actions.sendNonTradingOrderIdentityForm,
	}, { payload });

	if (isSuccess) {
		yield* userAccounts.saga.reloadTransactionHistory(payload.TradeCode, payload.ClearingFirm);
	}
}

function* sendNonTradingOrderLeverageSubmitSaga({ payload }) {
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: sendNonTradingLeverageOrder,
		errorText: 'Change in leverage',
		handler: actions.sendNonTradingOrderLeverageForm,
	}, { payload });

	if (isSuccess) {
		yield* userAccounts.saga.reloadTransactionHistory(payload.TradeCode, payload.ClearingFirm);
	}
}

function* sendNonTradingOrderRegistrationSubmitSaga({ payload }) {
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: sendNonTradingRegistrationOrder,
		errorText: 'Free form order',
		handler: actions.sendNonTradingOrderRegistrationForm,
	}, { payload });

	if (isSuccess) {
		yield* userAccounts.saga.reloadTransactionHistory(payload.TradeCode, payload.ClearingFirm);
	}
}
function* sendNonTradingOpenAccountSubmitSaga({ payload }) {
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: sendNonTradingOpenAccount,
		errorText: 'Open Account Error',
		handler: actions.sendNonTradingOpenAccount,
	}, { payload });

	if (isSuccess) {
		yield* userAccounts.saga.reloadTransactionHistory(payload.ClientData.TradeCode, payload.ClientData.ClearingFirm);
		yield delay(10000);
		yield put(userAccounts.actions.getAccountsRequest());
	}
}

function* sendNonTradingOpenDemoAccountSubmitSaga({ payload }) {
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: sendNonTradingOpenDemoAccount,
		errorText: 'Open Demo Account Error',
		handler: actions.sendNonTradingOpenDemoAccount,
	}, { payload });

	if (isSuccess) {
		yield put(userAccounts.actions.getAccountsRequest());
		yield put(userAccounts.actions.getAllAccountsInfo.request());
	}
}

function* watchHideNonTradingOrderModal() {
	yield takeEvery(actions.HIDE_NON_TRADING_ORDER_MODAL, hideNonTradingOrderModal);
}

function* watchNonTradingOrderCommonSubmit() {
	yield takeEvery(actions.sendNonTradingOrderCommonForm.REQUEST, sendNonTradingOrderCommonSubmitSaga);
}

function* watchNonTradingOrderIdentitySubmit() {
	yield takeEvery(actions.sendNonTradingOrderIdentityForm.REQUEST, sendNonTradingOrderIdentitySubmitSaga);
}

function* watchNonTradingOrderLeverageSubmit() {
	yield takeEvery(actions.sendNonTradingOrderLeverageForm.REQUEST, sendNonTradingOrderLeverageSubmitSaga);
}

function* watchNonTradingOrderRegistrationSubmit() {
	yield takeEvery(actions.sendNonTradingOrderRegistrationForm.REQUEST, sendNonTradingOrderRegistrationSubmitSaga);
}
function* watchNonTradingOpenAccountSubmit() {
	yield takeEvery(actions.sendNonTradingOpenAccount.REQUEST, sendNonTradingOpenAccountSubmitSaga);
}
function* watchNonTradingOpenDemoAccountSubmit() {
	yield takeEvery(actions.sendNonTradingOpenDemoAccount.REQUEST, sendNonTradingOpenDemoAccountSubmitSaga);
}

// Root saga
const rootSaga = [
	fork(watchHideNonTradingOrderModal),
	fork(watchNonTradingOrderCommonSubmit),
	fork(watchNonTradingOrderIdentitySubmit),
	fork(watchNonTradingOrderLeverageSubmit),
	fork(watchNonTradingOrderRegistrationSubmit),
	fork(watchNonTradingOpenAccountSubmit),
	fork(watchNonTradingOpenDemoAccountSubmit),
];

export default {
	rootSaga,
};
