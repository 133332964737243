import React, { useState } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
	Button,
	ProgressContainer,
} from '@components';
import { profile } from '@redux';
import { RequireSignatureModal } from '../../..';


const styles = theme => ({
	root: {
		display: 'block',
		position: 'relative',
	},
	progress: {
		display: 'flex',
		justifyContent: 'center',
	},
	confirmLink: {
		marginTop: '16px',
		paddingLeft: '24px',
	},
	link: {
		textTransform: 'uppercase',
	},
	button: {
		paddingTop: '24px',
	},
});

const ENABLE_THIRD_PARTY_MARKETDATA_FORM = 'EnableThirdPartyMarketDataModal'
const mapStateToProps = (state) => {
	const userProfile = profile.selectors.profile(state);
	return {
		loading: userProfile.submitting,
	};
};

const EnableThirdPartyMarketDataModal = (props) => {

	const {
		classes,
		handleSubmit,
		loading,
		onClose,
		submitting,
	} = props;

	return (
		<RequireSignatureModal
			title="Third party market data consent"
			onClose={onClose}
			bgColor="#f9fcfd"
			textColor="#345464"
		>
			<ProgressContainer submitting={loading}>
				<form
					onSubmit={handleSubmit(profile.actions.enableThirdPartyMarketDataForm)}
					className={classes.root}
				>
					<p>
						The U.S. Securities and Exchange Commission (Rule 603 of Regulation NMS) generally requires broker-dealers to provide a consolidated display of market data for most stocks for which they provide quotation information. Please confirm if you already receive such data through another source for purposes of trading in your account.
					</p>
					<div className={classes.button}>
						<Button
							fullWidth
							type="submit"
							variant="contained"
							color="primary"
							disabled={submitting}
						>
							{submitting ?
								<CircularProgress
									
									size={18}
								/>
								: 'I confirm'
							}
						</Button>
					</div>
				</form>
			</ProgressContainer>
		</RequireSignatureModal>
	);
};

EnableThirdPartyMarketDataModal.propTypes = {
	onClose: PropTypes.func.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
	reduxForm({
		form: ENABLE_THIRD_PARTY_MARKETDATA_FORM,
		onSubmitSuccess: (result, dispatch, props) => {
			props.onClose();
		},
	}),
)(EnableThirdPartyMarketDataModal);
