import actions from './actions';
import { TwoFactorQrCodeSteps } from '@enums';
import { combineReducers } from 'redux';

const qrCodeInitialState = {
    step: TwoFactorQrCodeSteps.INITIAL,
    qrCodeLink: null,
    secretCode: null,
}

const commonInitialState = {
    modalActionFinishedSuccessfully: null,
    modalActionIsEnabling: null,
};

const QrCodeReducer = (state = qrCodeInitialState, { type, payload }) => {
	switch (type) {
        case actions.getSecretAndQrCode.REQUEST:
            return {
                ...state,
                ...qrCodeInitialState,
                step: TwoFactorQrCodeSteps.LOADING_DATA
            };

        case actions.getSecretAndQrCode.SUCCESS:
            return {
                ...state,
                qrCodeLink: payload.response.QrCodeLink,
                secretCode: payload.response.SecretCode,
                step: TwoFactorQrCodeSteps.LOADING_IMAGE,
            };

        case actions.getSecretAndQrCode.FAILURE:
            return {
                ...state,
                ...qrCodeInitialState,
                step: TwoFactorQrCodeSteps.LOADING_DATA_FAILURE,
            };

        case actions.loadImage.SUCCESS:
            return {
                ...state,
                step: TwoFactorQrCodeSteps.LOADING_COMPLETE,
            };

		default:
			return state;
	}
};

const CommonReducer = (state = commonInitialState, { type, payload }) => {
    switch (type) {
        case actions.enableTwoFactor.SUCCESS:
            return {
                ...state,
                modalActionFinishedSuccessfully: true,
                modalActionIsEnabling: true,
            };

        case actions.disableTwoFactor.SUCCESS:
            return {
                ...state,
                modalActionFinishedSuccessfully: true,
                modalActionIsEnabling: false,
            };

        case actions.enableTwoFactor.FAILURE:
            return {
                ...state,
                modalActionFinishedSuccessfully: false,
                modalActionIsEnabling: true,
            };
        case actions.disableTwoFactor.FAILURE:
            return {
                ...state,
                modalActionFinishedSuccessfully: false,
                modalActionIsEnabling: false,
            };

        case actions.HANDLE_CLOSE:
            return {
                ...state,
                modalActionFinishedSuccessfully: null,
                modalActionIsEnabling: null,
            };
        
		default:
			return state;
	}
};

export default combineReducers({
    qrCode: QrCodeReducer,
    common: CommonReducer,
});
