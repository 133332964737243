import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { profile, userAccounts } from '@redux';
import {
	ChangeLink,
	ExpansionPanel,
	Input,
} from '@components';

function valueOrEmpty(val) {
	if (val) return val;
	return '';
}


const useActions = (userProfile) => {
	const dispatch = useDispatch();
	return ({
		showChangeEmploymentModal: () => dispatch(profile.actions.showChangeEmploymentModal(userProfile)),
	});
};

const EmploymentPanel = ({
	onToggle,
	panelId,
	isOpen,
	classes,
	profile: {
		EmploymentProfile,
		PendingOrders,
		Guid,
		ClientId,
	},
}) => {
	const actions = useActions({
		EmploymentProfile,
		PendingOrders,
		Guid,
		ClientId,
	});

	const hasOpenAccount = useSelector(userAccounts.selectors.hasOpenAccount);

	return (
		<ExpansionPanel
			header={
				<span>
					<span className={classes.mr60}>Employment</span>
					<span>
						{ hasOpenAccount && 
							<ChangeLink
								onClick={actions.showChangeEmploymentModal}
								disabled={PendingOrders.Employment}
							/>
						}
					</span>
				</span>}
			id={panelId}
			onToggleOpen={onToggle}
			isOpen={isOpen}
			classes={{ root: classes.expansionPanelRoot, content: classes.expansionPanelContent }}
		>
			<Input placeholder="Status" showLabel value={valueOrEmpty(EmploymentProfile.Status)} disabled />
			<Input placeholder="Company" showLabel value={valueOrEmpty(EmploymentProfile.Company)} disabled />
			<Input placeholder="Position" showLabel value={valueOrEmpty(EmploymentProfile.Position)} disabled />
			<Input
				placeholder="Address"
				showLabel
				multiline
				value={valueOrEmpty(EmploymentProfile.Address ? EmploymentProfile.Address.FullAddress : '')}
				disabled
			/>
		</ExpansionPanel>
	);
};

EmploymentPanel.propTypes = {
	classes: PropTypes.object.isRequired,
	profile: PropTypes.object.isRequired,
	onToggle: PropTypes.func.isRequired,
	panelId: PropTypes.string.isRequired,
	isOpen: PropTypes.bool,
};

EmploymentPanel.defaultProps = {
	isOpen: false,
};

export default EmploymentPanel;
