import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MUICheckbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const styles = theme => ({
	checkedPrimary: {
		color: theme.palette.primary.light,
	},
	label: {
		...theme.customstyles.title,
		color: theme.palette.text.primary,
	},
	additionalText: {
		padding: '0px 24px',
		...theme.customstyles.body,
		color: theme.palette.text.secondary,
	},
	rootError: {
		color: `${theme.palette.error.main} !important`,
	},
	errorText: {
		padding: '0px 24px',
		...theme.customstyles.body,
		color: theme.palette.error.main,
	},
	compactMode: {
		padding: '0px',
		marginLeft: '0px',
	}
});

class Checkbox extends React.Component {
	render() {
		const {
			classes,
			label,
			additionalText,
			helpertext,
			validationMessageMode,
			isCompact,
			error, 	
			...props
		} = this.props;
		return (
			<FormControl>
				<FormControlLabel
					classes={{
						label: classes.label,
					}}
					control={
						<MUICheckbox
							{...props}
							classes={{
								root: classNames({ 
									[classes.rootError]: error, 
									[classes.compactMode]: isCompact,
								}),
								checked: classes.checkedPrimary,
							}}
							color="primary"
						/>
					}
					label={label}
				/>
				{additionalText && !(error && helpertext && validationMessageMode === 'insteadOfAdditional') &&
					<FormHelperText classes={{ root: classes.additionalText }}>
						{additionalText}
					</FormHelperText>
				}
				{error && validationMessageMode !== 'none' &&
					<FormHelperText classes={{ root: classes.errorText }}>
						{helpertext}
					</FormHelperText>
				}
			</FormControl>
		);
	}
}

Checkbox.propTypes = {
	/**
	 * Разрешает выводить сообщение валидации.
	 */
	validationMessageMode: PropTypes.oneOf(['none', 'insteadOfAdditional', 'afterAdditional']),
};

Checkbox.defaultProps = {
	validationMessageMode: 'none',
	isCompact: false,
};

export default compose(withStyles(styles))(Checkbox);
