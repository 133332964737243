import { createApiActions } from '../extensions';

const actions = {

	LOGIN_LOCAL: 'AUTH/LOGIN_LOCAL',

	loginLocal: payload => ({
		type: actions.LOGIN_LOCAL,
		payload,
	}),
	AUTH_WIDGET: 'AUTH/AUTH_WIDGET',
	setWidget: payload => ({
		type: actions.AUTH_WIDGET,
		payload,
	}),


	LOGIN_USER: 'AUTH/LOGIN_USER',
	loginUser: payload => ({
		type: actions.LOGIN_USER,
		payload,
	}),


	LOGOUT: 'AUTH/LOGOUT',
	logout: () => ({
		type: actions.LOGOUT,
	}),

	LOGOUT_SUCCESS: 'AUTH/LOGOUT_SUCCESS',
	logoutSuccess: () => ({
		type: actions.LOGOUT_SUCCESS,
	}),

	checkAuth: createApiActions('AUTH/CHECK_AUTH'),

	AUTOLOGIN_REQUEST: 'AUTH/AUTOLOGIN_REQUEST',

	autologinRequest: autologin => ({
		type: actions.AUTOLOGIN_REQUEST,
		payload: {
			autologin,
		},
	}),

	SEND_PING_AUTH_REQUEST: 'AUTH/SEND_PING_AUTH_REQUEST',

	sendPingAuthRequest: () => ({
		type: actions.SEND_PING_AUTH_REQUEST,
	}),

	SEND_CONFIRMATION_CODE_REQUEST: 'AUTH/SEND_CONFIRMATION_CODE_REQUEST',
	SEND_CONFIRMATION_CODE_SUCCESS: 'AUTH/SEND_CONFIRMATION_CODE_SUCCESS',
	SEND_CONFIRMATION_CODE_FAILURE: 'AUTH/SEND_CONFIRMATION_CODE_FAILURE',
	sendConfirmationCodeRequest: (phone, reason) => ({
		type: actions.SEND_CONFIRMATION_CODE_REQUEST,
		payload: {
			phone,
			reason,
		},
	}),
	sendConfirmationCodeSuccess: () => ({
		type: actions.SEND_CONFIRMATION_CODE_SUCCESS,
	}),
	sendConfirmationCodeFailure: error => ({
		type: actions.SEND_CONFIRMATION_CODE_FAILURE,
		payload: {
			error,
		},
	}),

	SEND_EMAIL_CONFIRMATION_CODE_REQUEST: 'AUTH/SEND_EMAIL_CONFIRMATION_CODE_REQUEST',
	SEND_EMAIL_CONFIRMATION_CODE_SUCCESS: 'AUTH/SEND_EMAIL_CONFIRMATION_CODE_SUCCESS',
	SEND_EMAIL_CONFIRMATION_CODE_FAILURE: 'AUTH/SEND_EMAIL_CONFIRMATION_CODE_FAILURE',
	sendEmailConfirmationCodeRequest: (email, reason) => ({
		type: actions.SEND_EMAIL_CONFIRMATION_CODE_REQUEST,
		payload: {
			email,
			reason,
		},
	}),
	sendEmailConfirmationCodeSuccess: () => ({
		type: actions.SEND_EMAIL_CONFIRMATION_CODE_SUCCESS,
	}),
	sendEmailConfirmationCodeFailure: error => ({
		type: actions.SEND_EMAIL_CONFIRMATION_CODE_FAILURE,
		payload: {
			error,
		},
	}),
	SET_AFTER_AUTH_URL: 'AUTH/SET_AFTER_AUTH_URL',
	setAfterAuthUrl: payload => ({
		type: actions.SET_AFTER_AUTH_URL,
		payload,
	}),
};

export default actions;
