import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';


import {
	A,
	Checkbox,
	DateRangePicker,
	SvgIcon,
} from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import { reports } from '@redux';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		columnGap: theme.spacing(2),
		'&>div': {
			flex: '0 0 auto',
			minWidth: '0px',
		},
	},
	fixCheckBox: {
		marginLeft: '24px',
	},
	fixStartButton: { marginLeft: '16px' },
}));

const CYReportsFilter = (props) => {
	const { tradeCode } = props;
	const classes = useStyles(props);
	const [isLite, setIsLite] = useState(false);
	const [endDate, setEndDate] = useState(new Date());
	const [startDate, setStartDate] = useState(new Date(moment().year(), moment().month() - 2, moment().date()));
	const dispatch = useDispatch();
	const generateSubmitting = useSelector(reports.selectors.generateSubmitting);
	return (
		<div className={classes.root}>
			<div>
				<DateRangePicker
					endDate={endDate}
					startDate={startDate}
					onEndDateChange={date => setEndDate(date)}
					onStartDateChange={date => setStartDate(date)}
				/>
			</div>
			<div>
				<Checkbox
					label={<R id="common_reportsfilter_lite" defaultValue="Lite version" />}
					isCompact
					onChange={() => setIsLite(!isLite)}
					className={classes.fixCheckBox}
				/>
			</div>
			<div>
				<A
					onClick={() => dispatch(reports.actions.generateBrokerReport.request({ 
						startDate: moment(startDate).utc().format(),
						endDate: moment(endDate).utc().format(),
						isLiteVersion: isLite,
						tradeCode,
					}))}
					classes={{ root: classes.fixStartButton }}
				>
					<SvgIcon icon="Report" submitting={generateSubmitting} />
					&nbsp;<R id="shared_generate" defaultValue="Generate" />
				</A>
			</div>
		</div>
	);
};

CYReportsFilter.propTypes = {
	tradeCode: PropTypes.string.isRequired,
};

export default CYReportsFilter;
