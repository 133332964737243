import React from 'react';
import {
	ProgressContainer, ContentContainer, ExpansionPanel
} from '@components';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
	tableContainer: {
		margin: '30px 0',
	},
	open: {
		padding: '0 50px',
		[theme.breakpoints.down('sm')]: {
			padding: '0 40px',
		},
	},
	content: {
		overflowX: 'auto',
		overflowY: 'hidden',
	},
}));

export default function ContentCloud({header, submitting, children, onToggleOpen, isOpen, id}) {
	
	const classes = useStyles();
	
	return (
		<ContentContainer className={classes.tableContainer}>
			<Paper>
				<ExpansionPanel 
					id={id}
					header={header} 
					isOpen={isOpen}
					onToggleOpen={onToggleOpen}
					classes={{
						opened: classes.open,
						content: classes.content,
					}}
				>
					<ProgressContainer submitting={submitting} disableCheckChildren>
						{children}
					</ProgressContainer>
				</ExpansionPanel>
			</Paper>
		</ContentContainer>
	);
}
