import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { Button } from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import successIcon from './complete.svg';
import errorIcon from './large_error.svg';

const styles = theme => ({
	root: {
		textAlign: 'center',
	},
	icon: {
		marginTop: '16px',
	},
	message: {
		marginTop: '32px',
		...theme.customstyles.subheading,
		fontWeight: 'bold',
		color: theme.palette.text.primary,
	},
	submessage: {
		color: theme.palette.text.primary,
	},
	button: {
		marginTop: '32px',
	},
});

class BaseMessage extends React.Component {
	render() {
		const {
			classes,
			onClick,
			type,
			text,
			subtext,
			buttonText,
		} = this.props;

		return (
			<div className={classes.root}>
				{type === 'success' &&
					<div className={classes.icon}>
						<img alt="Complete icon" src={successIcon} />
					</div>
				}
				{type === 'error' &&
					<div className={classes.icon}>
						<img alt="Error icon" src={errorIcon} />
					</div>
				}
				<div className={classes.message}>
					{text}
				</div>
				<div className={classes.submessage}>
					{subtext}
				</div>
				<div className={classes.button}>
					<Button
						color="primary"
						variant="outlined"
						fullWidth
						onClick={onClick}
					>
						{ buttonText === undefined ? <R id="common_popup_success_closebutton" defaultValue="Thank you" /> : buttonText}
					</Button>
				</div>
			</div>
		);
	}
}

BaseMessage.defaultProps = {
	subtext: '',
};

BaseMessage.propTypes = {
	classes: PropTypes.object.isRequired,
	onClick: PropTypes.func.isRequired,
	text: PropTypes.node.isRequired,
	subtext: PropTypes.node,
	type: PropTypes.string.isRequired,
	buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default compose(withStyles(styles))(BaseMessage);
