import React from 'react';

const Svg = (
	<svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
		<circle cx="32" cy="32" r="32" fill="#2a5f9e" />
		<g fill="#ffce31">
			<path d="M32 11.8l.7 2.2H35l-1.8 1.4l.7 2.2l-1.9-1.4l-1.9 1.4l.7-2.2L29 14h2.3z" />
			<path d="M32 45.8l.7 2.2H35l-1.8 1.3l.7 2.3l-1.9-1.4l-1.9 1.4l.7-2.3L29 48h2.3z" />
			<path d="M40.4 43l.7 2.2h2.4l-1.9 1.4l.7 2.2l-1.9-1.4l-1.9 1.4l.8-2.2l-1.9-1.4h2.3z" />
			<path d="M46.2 37.2l.7 2.2h2.4l-1.9 1.4l.7 2.2l-1.9-1.4l-1.9 1.4l.8-2.2l-1.9-1.4h2.3z" />
			<path d="M49 28.8l.7 2.2H52l-1.9 1.4l.7 2.2l-1.8-1.4l-1.9 1.4l.7-2.2l-1.9-1.4h2.3z" />
			<path d="M46.2 20.3l.7 2.3h2.4l-1.9 1.3l.7 2.2l-1.9-1.3l-1.9 1.3l.8-2.2l-1.9-1.3h2.3z" />
			<path d="M40.4 14.5l.7 2.3h2.4l-1.9 1.3l.7 2.2l-1.9-1.3l-1.9 1.3l.8-2.2l-1.9-1.3h2.3z" />
			<path d="M23.6 43l-.7 2.2h-2.4l1.9 1.4l-.7 2.2l1.9-1.4l1.9 1.4l-.8-2.2l1.9-1.4h-2.3z" />
			<path d="M17.8 37.2l-.7 2.2h-2.4l1.9 1.4l-.7 2.2l1.9-1.4l1.9 1.4l-.8-2.2l1.9-1.4h-2.3z" />
			<path d="M15 28.8l-.7 2.2H12l1.9 1.4l-.7 2.2l1.8-1.4l1.9 1.4l-.7-2.2l1.9-1.4h-2.3z" />
			<path d="M17.8 20.3l-.7 2.3h-2.4l1.9 1.3l-.7 2.2l1.9-1.3l1.9 1.3l-.8-2.2l1.9-1.3h-2.3z" />
			<path d="M23.6 14.5l-.7 2.3h-2.4l1.9 1.3l-.7 2.2l1.9-1.3l1.9 1.3l-.8-2.2l1.9-1.3h-2.3z" />
		</g>
	</svg>
);


export default Svg;
