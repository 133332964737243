import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button } from '@components';
import PropTypes from 'prop-types';


const useStyles = makeStyles(theme => createStyles({
	root: {
		margin: '24px 74px',
		padding: '10px 16px',
		backgroundColor: theme.palette.warning.main, 
		borderRadius: '4px',
		alignItems: 'center',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {
			margin: '24px 0',
			padding: '10px 16px',
		},
	},
	content: {
		...theme.customstyles.body,
		color: theme.palette.warning.contrastText,
	},
	title: {
		...theme.customstyles.body,
		color: theme.palette.warning.contrastText,
		fontSize: '24px'
	},
	actions: {
		marginLeft: '16px',
	},
	button: {
		color: theme.palette.primary.main,
	},
}));

const AlertNotify = ({
	onClick,
	buttonText,
	text,
	title,
	hideButton,
}) => {
	const classes = useStyles();
	const showButton = !hideButton && onClick && buttonText;

	return (
		<React.Fragment>
			<div>
				<div className={classes.root}>
					<div className={classes.title}>{title}<br/></div>
					{/* <div className={classes.content}>{text}<br/></div> */}
					<div className={classes.content} dangerouslySetInnerHTML={{ __html: text }} />
					{showButton &&
						<div className={classes.actions}>
							<Button
								onClick={onClick}
								className={classes.button}
							>
								{buttonText}
							</Button>
						</div>
					}
				</div>
			</div>
		</React.Fragment>
	);
};

AlertNotify.propTypes = {
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
	buttonText: PropTypes.string,
	onClick: PropTypes.func,
	hideButton: PropTypes.bool,
};

AlertNotify.defaultProps = {
	onClick: null,
	hideButton: false,
	buttonText: null,
};

export default AlertNotify;