import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { env } from '@config';
import { AccountPlatforms, ModalPopups } from '@enums';
import {
	tradePlatforms,
	userAccounts,
} from '@redux';
import {
	Modal,
	Button,
	A,
	ProgressContainer,
} from '@components';
import { connect } from 'react-redux';
import logo from './imgs/mt5-logo.png';
import Mt5InfoForm from './components/Mt5InfoForm';
import Mt5PasswordForm from './components/Mt5PasswordForm';

const styles = theme => ({
	title: {
		...theme.customstyles.display,
		color: theme.palette.text.primary,
	},
	body: {
		marginTop: '24px',
		...theme.customstyles.body,
		color: theme.palette.text.primary,
	},
	description: {
		marginBottom: '5px',
	},
	list: {
		margin: '0',
		listStyleType: 'none',
		lineHeight: '35px',
		padding: '0 0 0 1.5em',
		'& li:': {
			'&:before': {
				content: '"—"',
				marginLeft: '-1.5em',
				width: '1.5em',
				display: 'inline-block',
			},
		},
	},
	button: {
		marginTop: '32px',
		backgroundColor: '#e3edf5',
	},
	buttonDownload: {
		marginTop: '32px',
		textAlign: 'center',
		fontSize: '1.2rem',
	},
	noteText: {
		marginTop: '32px',
		...theme.customstyles.body,
		color: theme.palette.text.secondary,
	},
});

const mapDispatchToProps = dispatch => ({
	actions: {
		setCurrentPage: page => dispatch(push(page)),
		activatePlatform: (account, tradePlatform) =>
			dispatch(tradePlatforms.actions.activatePlatformRequest(account, tradePlatform)),
	},
});

const mapStateToProps = (state) => {
	const currentAccountData = userAccounts.selectors.currentAccountData(state);
	const accountInfo = userAccounts.selectors.accountInfo(state);
	const hasOptions = currentAccountData.portfolio && currentAccountData.portfolio.Positions &&
		currentAccountData.portfolio.Positions.some(x => x.IsOption);
	return {
		currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
		platforms: userAccounts.selectors.platforms(state),
		submitting: tradePlatforms.selectors.submitting(state),
		ready: !!(accountInfo && !accountInfo.loading && !currentAccountData.submitting),
		tradeLogin: accountInfo && accountInfo.TradeLogin,
	};
};

const PLATFORM = AccountPlatforms.PLATFORM_MT5SPC;

class Mt5Modal extends React.Component {
	constructor(props) {
		super(props);
		this.activatePlatform = this.activatePlatform.bind(this);
	}

	activatePlatform() {
		this.props.actions.activatePlatform(
			this.props.currentTradeAccount,
			PLATFORM,
		);
	}
	openTerminal = () => {
		const { actions } = this.props;
		actions.setCurrentPage('/dashboard/terminalMt5');
	};

	render() {
		const {
			actions,
			onClose,
			classes,
			submitting,
			platforms: {
				ActiveTradePlatform,
				PendingTradePlatform,
			},
			hasOptions,
			ready,
			tradeLogin,
		} = this.props;
		const terminalMt5Link = `${env.baseUrl}/static/webterminal.html${(tradeLogin && `?login=${tradeLogin}`) || ''}`;
		return (
			<Modal
				title={<img alt="J2T Lite logo" src={logo} />}
				onClose={onClose}
				bgColor="#f9fcfd"
				textColor="#345464"
				className={`QA-${ModalPopups.MT5}`}
			>
				<ProgressContainer submitting={!ready}>
					<div className={classes.title}>
						MetaTrader 5
					</div>
					{ActiveTradePlatform === PLATFORM &&
						<React.Fragment>
							<Mt5InfoForm classes={{ root: classes.body }} />
							<Mt5PasswordForm />
						</React.Fragment>
					}
					<div className={classes.body}>
						{ActiveTradePlatform !== PLATFORM &&
							<React.Fragment>
								<p className={classes.description}>
									<strong>Trade stocks</strong> with original web terminal from the developers of MT5
									by MetaQuotes provides an opportunity to trade via any browser in any operating system.
								</p>
								<ul className={classes.list}>
									<li>Multi-functional platform</li>
									<li>Technical analysis tools</li>
									<li>One-click trading capability</li>
									<li>Algorithmic trading</li>
									<li>Trading alerts</li>
									<li>“Market Place”</li>
									<li>Virtual hosting</li>
									<li>Advanced market depth and real-time quotes</li>
									<li>Recommended for accounts that will qualify for Day trading with a minimum equity of $25,000</li>
									<li>Option trading is not yet supported</li>
									<li>Try out MT5 by downloading the application and opening a demo account to trade virtual money without risk</li>
								</ul>
							</React.Fragment>
						}
						<p>
							You may also download MetaTrader 5 for <A target="_blank" href="https://download.mql5.com/cdn/web/lime.trading.corp/mt5/limetrading5setup.exe">PC</A>,
							&nbsp;<A target="_blank" href="https://download.mql5.com/cdn/mobile/mt5/ios?server=LimeTrading-MT5">iOS</A>
							&nbsp;and <A target="_blank" href="https://download.mql5.com/cdn/mobile/mt5/android?server=LimeTrading-MT5">Android</A>
							&nbsp;-&nbsp;a perfectly equipped trader's working tool for trading on global financial markets.
						</p>
					</div>
					{PendingTradePlatform && PendingTradePlatform === PLATFORM &&
					<div className={classes.button}>
						<Button
							color="secondary"
							variant="contained"
							fullWidth
							disabled
						>
							ACTIVATION REQUESTED
						</Button>
					</div>
					}
					{!PendingTradePlatform && ActiveTradePlatform !== PLATFORM &&
					<div className={classes.button}>
						<Button
							color="primary"
							variant="contained"
							fullWidth
							onClick={this.activatePlatform}
							disabled={submitting || hasOptions}
						>
							{submitting ?
								<CircularProgress
									
									size={18}
								/>
								: 'Activate'
							}
						</Button>
					</div>
					}
					{ActiveTradePlatform === PLATFORM &&
					<div className={classes.buttonDownload}>
						<A target="_blank" href={terminalMt5Link} onClick={this.props.onClose}>Access MT5 Web</A>
						{/* <Button variant="text" color="primary" onClick={this.openTerminal}>
							Access MT5 Web
						</Button> */}
					</div>
					}

					<div className={classes.noteText}>
						Trading platform becomes available on the next day after activation.<br />
						Please note that only one trading platform may be used at a time.
					</div>
				</ProgressContainer>
			</Modal>
		);
	}
}

Mt5Modal.propTypes = {
	onClose: PropTypes.func.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(Mt5Modal);
