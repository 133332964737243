import React from 'react';
import PropTypes from 'prop-types';
import { Box, List, makeStyles, useTheme } from '@material-ui/core';
import { Button } from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import { usePaymentGateWidgetHook } from '@hooks';
import { userAccounts } from '@redux';
import { useDispatch } from 'react-redux';
import { Mt5GlobalCurrencyItem } from '.';

const MandatoryCurrencies = ['BTC', 'LTC', 'ETH', 'XRP', 'USDT'];

const useStyles = makeStyles(theme => ({
	button: {
		flex: '1 0 80px',
		minWidth: '80px',
		width: '100%',
		margin: '5px',
	},
	buttonSection: {
		display: 'flex',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		padding: theme.spacing(1),
		alignItems: 'center',
	},
}));

const Mt5GlobalAccountBalance = (props) => {
	const { balance, tradeCode, clearerFirm } = props;
	const theme = useTheme();
	const classes = useStyles(props);

	const dispatch = useDispatch();
	const actions = {
		showWithdrawFundsModal: () => {
			dispatch(userAccounts.actions.showJ2txMt5WithdrawalModal({
				tradeCode,
				clearerFirm,
			}));
		},
		showReportsModal: () => dispatch(userAccounts.actions.showReportsModal({ tradeCode })),
		showTransferMt5ToWalletModal: () => dispatch(userAccounts.actions.showTransferMt5ToWalletModal({ tradeCodeFrom: tradeCode })),
	};

	const Currencies = (balance && balance.Currencies) || [];
	const CryptoCurrencies = (balance && balance.CryptoCurrencies) || [];

	const additionalCurrencies = MandatoryCurrencies
		.filter(x => CryptoCurrencies.every(c => c.CurrencyCode !== x) && Currencies.every(c => c.CurrencyCode !== x))
		.map(x => ({
			CurrencyCode: x,
			Balance: 0,
		}));

	const isAllowedOut = CryptoCurrencies.filter(x => MandatoryCurrencies.includes(x.CurrencyCode)).some(x => x.IsAllowedOut);
	const isAllowedTransfer = CryptoCurrencies.filter(x => MandatoryCurrencies.includes(x.CurrencyCode)).some(x => x.IsAllowedTransfer);
	
	const [paymentWidgetId, loadingWidget, successWidget] = usePaymentGateWidgetHook({ tradeCode });

	return (
		<React.Fragment>
			<List>
				{CryptoCurrencies.map(x => (
					<Mt5GlobalCurrencyItem currencyBalanceItem={x} key={x.CurrencyCode} />
				))}
				{additionalCurrencies.map(x => (
					<Mt5GlobalCurrencyItem currencyBalanceItem={x} key={x.CurrencyCode} />
				))}
			</List>
			<Box className={classes.buttonSection} >
				<Button
					color="primary"
					className={classes.button}
					variant="outlined"
					size="small"
					disabled={loadingWidget || !successWidget}
					id={paymentWidgetId}
				>
					<R id="shared_deposit" defaultValue="Deposit" />
				</Button>
				<Button
					className={classes.button}
					color="primary"
					variant="outlined"
					size="small"
					onClick={actions.showWithdrawFundsModal}
					disabled={!isAllowedOut}
				>
					<R id="shared_withdraw" defaultValue="Withdraw" />
				</Button>
				<Button
					className={classes.button}
					color="primary"
					variant="outlined"
					size="small"
					onClick={actions.showTransferMt5ToWalletModal}
					disabled={!isAllowedTransfer}
				>
                    <R id="shared_transfer" defaultValue="Transfer" />
				</Button>
				<Button 
					className={classes.button} 
					color="primary"
					variant="outlined" 
					size="small" 
					onClick={actions.showReportsModal}
				>
					<R id="common_menu_reports" defaultValue="Reports" />
				</Button>
			</Box>
		</React.Fragment>
	);
};

const TypeCurrencyItem = PropTypes.shape({
	CurrencyCode: PropTypes.string.isRequired,
	Balance: PropTypes.number.isRequired,
});

Mt5GlobalAccountBalance.propTypes = {
	balance: PropTypes.shape({
		Currencies: PropTypes.arrayOf(TypeCurrencyItem),
		CryptoCurrencies: PropTypes.arrayOf(TypeCurrencyItem),
	}),
	tradeCode: PropTypes.string.isRequired,
	clearerFirm: PropTypes.string.isRequired,
};

export default Mt5GlobalAccountBalance;
