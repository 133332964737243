import React from 'react';

const Svg = (
	<React.Fragment>
                <path d="M1.52849 0.0732649C1.3676 0.116154 1.13698 0.212654 1.01363 0.282348C0.68648 0.475348 0.268156 0.957849 0.123352 1.31704L0 1.62263V11.219L0.123352 11.5246C0.268156 11.8838 0.675754 12.3556 1.01899 12.5539C1.53385 12.8595 1.55531 12.8595 5.69028 12.8434L9.49274 12.8274L9.79843 12.704C10.1578 12.5593 10.6297 12.1519 10.8282 11.8141C11.1339 11.2941 11.1285 11.3477 11.1285 6.42082C11.1285 1.49396 11.1339 1.54757 10.8282 1.02754C10.6297 0.689793 10.1578 0.282348 9.79843 0.137598L9.49274 0.0142927L5.6581 0.00357045C2.40804 -0.00715178 1.78056 0.00357044 1.52849 0.0732649ZM9.27821 1.83707L9.41229 1.96574V10.8759L9.27821 11.0046L9.1495 11.1386H1.95218L1.82346 11.0046L1.68939 10.8759V1.96574L1.82346 1.83707L1.95218 1.70304H9.1495L9.27821 1.83707Z"/>
                <path d="M14.4 0.0732782C14.2391 0.116167 14.0085 0.212667 13.8851 0.282362C13.558 0.475362 13.1397 0.957862 12.9949 1.31706L12.8715 1.62264V7.78792L12.9949 8.0935C13.1397 8.4527 13.5473 8.92448 13.8905 9.12284C14.4054 9.42842 14.4268 9.42842 18.5618 9.41234L22.3642 9.39625L22.6699 9.27295C23.0293 9.1282 23.5012 8.72075 23.6997 8.383C23.9893 7.88442 24 7.76111 24 4.70528C24 1.64945 23.9893 1.52614 23.6997 1.02756C23.5012 0.689806 23.0293 0.282362 22.6699 0.137612L22.3642 0.0143059L18.5296 0.00358372C15.2796 -0.00713851 14.6521 0.00358371 14.4 0.0732782ZM22.1497 1.83708L22.2838 1.96575V7.44481L22.1497 7.57348L22.021 7.7075H14.8237L14.695 7.57348L14.5609 7.44481V1.96575L14.695 1.83708L14.8237 1.70306H22.021L22.1497 1.83708Z"/>
                <path d="M14.4 11.2244C14.2391 11.2673 14.0085 11.3638 13.8851 11.4335C13.558 11.6265 13.1397 12.109 12.9949 12.4682L12.8715 12.7737V22.3701L12.9949 22.6757C13.1397 23.0349 13.5473 23.5067 13.8905 23.7051C14.4054 24.0106 14.4268 24.0106 18.5618 23.9946L22.3642 23.9785L22.6699 23.8552C23.0293 23.7104 23.5012 23.303 23.6997 22.9652C24.0054 22.4452 24 22.4988 24 17.5719C24 12.6451 24.0054 12.6987 23.6997 12.1787C23.5012 11.8409 23.0293 11.4335 22.6699 11.2887L22.3642 11.1654L18.5296 11.1547C15.2796 11.144 14.6521 11.1547 14.4 11.2244ZM22.1497 12.9882L22.2838 13.1169V22.027L22.1497 22.1557L22.021 22.2897H14.8237L14.695 22.1557L14.5609 22.027V13.1169L14.695 12.9882L14.8237 12.8542H22.021L22.1497 12.9882Z"/>
                <path d="M1.52849 14.6555C1.3676 14.6984 1.13698 14.7949 1.01363 14.8646C0.68648 15.0576 0.268156 15.5401 0.123352 15.8993L0 16.2049V22.3701L0.123352 22.6757C0.268156 23.0349 0.675754 23.5067 1.01899 23.7051C1.53385 24.0106 1.55531 24.0106 5.69028 23.9946L9.49274 23.9785L9.79843 23.8552C10.1578 23.7104 10.6297 23.303 10.8282 22.9652C11.1178 22.4666 11.1285 22.3433 11.1285 19.2875C11.1285 16.2317 11.1178 16.1084 10.8282 15.6098C10.6297 15.272 10.1578 14.8646 9.79843 14.7198L9.49274 14.5965L5.6581 14.5858C2.40804 14.5751 1.78056 14.5858 1.52849 14.6555ZM9.27821 16.4193L9.41229 16.548V22.027L9.27821 22.1557L9.1495 22.2897H1.95218L1.82346 22.1557L1.68939 22.027V16.548L1.82346 16.4193L1.95218 16.2853H9.1495L9.27821 16.4193Z"/>
        </React.Fragment>

);

export default Svg;
