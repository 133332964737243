import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getIntrnationalBanksRequisites, getDomesticBanksRequisites } from '@api';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	reduxForm,
	Field,
	formValueSelector,
} from 'redux-form';
import {
	TabContainer,
	FileInput,
	FormAutocompleteInput,
	FormCheckbox,
	FormInput,
	FormRadioGroup,
	Radio,
	Button,
} from '@components';
import { cabinetLog, captureError } from '@global';
import { userAccounts } from '@redux';
import { SignatureChecker } from '@shared';

const getPromiseWithFunc = func => query => new Promise((resolve, reject) => {
	func(query).then((response) => {
		if (response.data.Errors && response.data.Errors[0] && response.data.Errors[0].Code === 'NeedReCall') {
			resolve([]);
			return;
		}
		cabinetLog(func.name || 'respcall', response);
		if (response.data.Errors && response.data.Errors[0] && response.data.Errors[0].Code === 'Unauthorized') {
			resolve([]);
			return;
		}
		if (Array.isArray(response.data)) {
			resolve(response.data);
			return;
		}
		resolve([]);
		window.Raven.captureMessage('response.data is not array', { extra: { text: response.data.toString().substr(0, 200) } });
	}, (error) => {
		captureError(error);
		resolve([]);
	});
});

const styles = theme => ({
	root: {
		position: 'relative',
	},
	row: {
		display: 'flex',
	},
	inputItem: {
		marginTop: '30px',
		paddingLeft: '50px',
	},
	item: {
		marginTop: '30px',
		paddingLeft: '74px',
	},
	divider: {
		marginTop: '30px',
	},
	bankAccountContainer: {
		marginTop: '-15px',
	},
	accountContainer: {
	},
	logo: {
		padding: '16px 16px 4px 16px',
		position: 'relative',
		width: '72px',
		height: '60px',
	},
	arrow: {
		paddingLeft: '24px',
		height: '36px',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.customstyles.title,
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	accountBalance: {
		width: '100%',
		textAlign: 'right',
	},
	warning: {
		padding: '12px 16px',
		textAlign: 'justify',
		fontSize: '12px',
		lineHeight: '20px',
	},
	button: {
		...theme.mixins.stickyButton,
	},
	attachFileText: {
		...theme.customstyles.body,
		color: theme.palette.text.primary,
		marginTop: '24px',
	},
	fileUploader: {
		marginTop: '16px',
	},
});

const ADD_WIRE_MANUALLY_FORM_ID = 'addWireManuallyForm';

const mapStateToProps = (state, ownProps) => {
	const formSelector = formValueSelector(ownProps.form || ADD_WIRE_MANUALLY_FORM_ID);
	const currentTradeAccount = userAccounts.selectors.currentTradeAccount(state);
	const userInfo = userAccounts.selectors.userInfo(state);
	const IsOutsideCountryOfResidenceValue = formSelector(state, 'wireLink.IsOutsideCountryOfResidence');
	return {
		attachmentsReferenceValue: formSelector(state, 'wireLink.AttachmentsReference'),
		attachmentsStatementValue: formSelector(state, 'wireLink.AttachmentsStatement'),
		attachmentsExplanationLetterValue: formSelector(state, 'wireLink.AttachmentsExplanationLetter'),
		initialValues: {
			wireLink: {
				TradeCode: currentTradeAccount.tradeCode,
				ClearingFirm: currentTradeAccount.clearerFirm,
				WireType: 'Domestic',
				UseIntermediaryBank: false,
			},
		},
		currentTradeAccount,
		WireTypeValue: formSelector(state, 'wireLink.WireType'),
		UseIntermediaryBankValue: formSelector(state, 'wireLink.UseIntermediaryBank'),
		IsOutsideCountryOfResidenceValue,
		currentAccount: userAccounts.selectors.currentAccount(state),
		currentClearer: userAccounts.selectors.currentClearer(state),
		isForeign: userInfo.IsForeign,
		RequireBankReference: ['Enhanced', 'Blocked'].some(x => x === userInfo.DueDiligenceType) || IsOutsideCountryOfResidenceValue,
	};
};

class WireManuallyTabComponent extends React.Component {
	static validate(formvalues, props) {
		const values = formvalues.wireLink;
		const errors = {};
		if (!values) {
			return errors;
		}
		if (!values.BankName) {
			errors.BankName = 'required';
		}

		if (values.WireType === 'International') {
			if (!values.BankSwiftCode) {
				errors.BankSwiftCode = 'required';
			}
		}

		if (values.WireType === 'Domestic') {
			if (!values.BankRoutingNumber) {
				errors.BankRoutingNumber = 'required';
			} else if (!(/^(\d{9})$/.test(values.BankRoutingNumber))) {
				errors.BankRoutingNumber = 'Invalid Number';
			}
		}

		if (!values.BankAccountName) {
			errors.BankAccountName = 'required';
		}

		if (!values.BankAccountNumber) {
			errors.BankAccountNumber = 'required';
		} else if (!(/^[0-9a-zA-Z]+$/.test(values.BankAccountNumber))) {
			errors.BankAccountNumber = 'Invalid Number';
		}

		if (values.UseIntermediaryBank) {
			if (!values.IntermediaryBankAccountNumber) {
				errors.IntermediaryBankAccountNumber = 'required';
			} else if (!(/^[0-9a-zA-Z]+$/.test(values.IntermediaryBankAccountNumber))) {
				errors.IntermediaryBankAccountNumber = 'Invalid Number';
			}

			if (!values.IntermediaryBankName) {
				errors.IntermediaryBankName = 'required';
			}
			if (values.WireType === 'International') {
				if (!values.IntermediaryBankSwiftCode) {
					errors.IntermediaryBankSwiftCode = 'required';
				}
			}

			if (values.WireType === 'Domestic') {
				if (!values.IntermediaryBankRoutingNumber) {
					errors.IntermediaryBankRoutingNumber = 'required';
				} else if (!(/^(\d{9})$/.test(values.IntermediaryBankRoutingNumber))) {
					errors.IntermediaryBankRoutingNumber = 'Invalid Number';
				}
			}
		}
		if (!values.AttachmentsStatement || values.AttachmentsStatement.length === 0) {
			errors.AttachmentsStatement = 'required';
		}
		if (props.RequireBankReference &&
			(!values.AttachmentsReference || !values.AttachmentsReference.length === 0)
		) {
			errors.AttachmentsReference = 'required';
		}
		if (props.IsOutsideCountryOfResidenceValue &&
			(!values.AttachmentsExplanationLetter || !values.AttachmentsExplanationLetter.length === 0)
		) {
			errors.AttachmentsExplanationLetter = 'required';
		}
		return { wireLink: errors };
	}

	onFilesUpload = (field, files) => this.props.change(`wireLink.${field}`, files);

	render() {
		const {
			attachmentsReferenceValue,
			attachmentsStatementValue,
			attachmentsExplanationLetterValue,
			classes,
			theme,
			handleSubmit,
			change,
			invalid,
			submitting,
			currentTradeAccount,
			RequireBankReference,
			WireTypeValue,
			UseIntermediaryBankValue,
			IsOutsideCountryOfResidenceValue,
			isForeign,
		} = this.props;
		const noAttachments = (!attachmentsStatementValue || attachmentsStatementValue.length === 0) ||
			(RequireBankReference && (!attachmentsReferenceValue || attachmentsReferenceValue.length === 0)) ||
			(IsOutsideCountryOfResidenceValue && (!attachmentsExplanationLetterValue || attachmentsExplanationLetterValue.length === 0));

		return (
			<TabContainer>
				<SignatureChecker>
					<div>
						<p>
							Enter your bank account credentials to link the account.
						</p>
						<form
							onSubmit={handleSubmit}
							className={classes.root}
						>
							<div className={classes.item}>
								<Field
									name="wireLink.WireType"
									orientation="horizontal"
									component={FormRadioGroup}
								>
									<Radio value="Domestic" label="Domestic" />
									<Radio value="International" label="International" />
								</Field>
							</div>
							{WireTypeValue === 'Domestic' &&
								<React.Fragment>
									<div className={classes.inputItem}>
										<Field
											component={FormAutocompleteInput}
											name="wireLink.BankName"
											placeholder="Bank Name"
											label="Bank Name"
											autocompleteQuery={{
												minQueryLength: 3,
												maxQueryLength: 30,
												getOptions: getPromiseWithFunc(getDomesticBanksRequisites),
											}}
											autocompleteProps={{
												getOptionSelected: (option, value) => option.name === value.name,
												renderOption: (option, state) => (<React.Fragment><div><div>{option.name}</div><div>{option.routingNumber}, {option.city}</div></div></React.Fragment>),
												filterOptions: (options, state) => options.filter(x =>
													x.name.toLowerCase().indexOf(state.inputValue.toLowerCase()) >= 0),
												getOptionLabel: option => option.name,
												onChange: (newSelectedOption) => {
													if (newSelectedOption) {
														change('wireLink.BankRoutingNumber', newSelectedOption.routingNumber);
													}
												},
											}}
										/>
									</div>
									<div className={classes.inputItem}>
										<Field
											component={FormAutocompleteInput}
											name="wireLink.BankRoutingNumber"
											placeholder="Bank ABA/Routing Number"
											label="Bank ABA/Routing Number"
											autocompleteQuery={{
												minQueryLength: 3,
												maxQueryLength: 30,
												getOptions: getPromiseWithFunc(getDomesticBanksRequisites),
											}}
											autocompleteProps={{
												getOptionSelected: (option, value) => option.routingNumber === value.routingNumber,
												renderOption: (option, state) => (<React.Fragment><div><div>{option.name}</div><div>{option.routingNumber}</div></div></React.Fragment>),
												filterOptions: (options, state) => options.filter(x =>
													x.routingNumber.toLowerCase().indexOf(state.inputValue.toLowerCase()) === 0),
												getOptionLabel: option => option.routingNumber,
												onChange: (newSelectedOption) => {
													if (newSelectedOption) {
														change('wireLink.BankName', newSelectedOption.name);
													}
												},
											}}
										/>
									</div>

								</React.Fragment>
							}
							{WireTypeValue === 'International' &&
								<React.Fragment>
									<div className={classes.inputItem}>
										<Field
											component={FormAutocompleteInput}
											name="wireLink.BankName"
											placeholder="Bank Name"
											label="Bank Name"
											autocompleteQuery={{
												minQueryLength: 3,
												maxQueryLength: 30,
												getOptions: getPromiseWithFunc(getIntrnationalBanksRequisites),
											}}
											autocompleteProps={{
												getOptionSelected: (option, value) => option.name === value.name,
												renderOption: (option, state) => (<React.Fragment><div><div>{option.name}</div><div>{option.swift}</div></div></React.Fragment>),
												filterOptions: (options, state) => options.filter(x =>
													x.name.toLowerCase().indexOf(state.inputValue.toLowerCase()) >= 0),
												getOptionLabel: option => option.name,
												onChange: (newSelectedOption) => {
													if (newSelectedOption) {
														change('wireLink.BankSwiftCode', newSelectedOption.swift);
													}
												},
											}}
										/>
									</div>
									<div className={classes.inputItem}>
										<Field
											component={FormAutocompleteInput}
											name="wireLink.BankSwiftCode"
											placeholder="Swift Code"
											label="Swift Code"
											autocompleteQuery={{
												minQueryLength: 3,
												maxQueryLength: 30,
												getOptions: getPromiseWithFunc(getIntrnationalBanksRequisites),
											}}
											autocompleteProps={{
												getOptionSelected: (option, value) => option.swift === value.swift,
												renderOption: (option, state) => (<React.Fragment><div><div>{option.name}</div><div>{option.swift}</div></div></React.Fragment>),
												filterOptions: (options, state) => options.filter(x =>
													x.swift.toLowerCase().indexOf(state.inputValue.toLowerCase()) === 0),
												getOptionLabel: option => option.swift,
												onChange: (newSelectedOption) => {
													if (newSelectedOption) {
														change('wireLink.BankName', newSelectedOption.name);
													}
												},
											}}
										/>
									</div>
								</React.Fragment>
							}
							<div className={classes.inputItem}>
								<Field
									component={FormInput}
									name="wireLink.BankAccountName"
									placeholder={WireTypeValue === 'International' ? 'Customer Account Name' : 'Bank Account Name'}
									label={WireTypeValue === 'International' ? 'Customer Account Name' : 'Bank Account Name'}
								/>
							</div>
							<div className={classes.inputItem}>
								<Field
									component={FormInput}
									name="wireLink.BankAddress"
									placeholder="Bank Address"
									label="Bank Address"
									additionalText="City, State, Country"
								/>
							</div>
							<div className={classes.inputItem}>
								<Field
									component={FormInput}
									name="wireLink.BankAccountNumber"
									placeholder="Bank Account Number"
									label="Bank Account Number"
								/>
							</div>
							<div className={classes.item}>
								<Field
									component={FormCheckbox}
									name="wireLink.UseIntermediaryBank"
									label="Use Intermediary Bank"
								/>
							</div>
							{UseIntermediaryBankValue &&
								<div>
									<div className={classes.inputItem}>
										<Field
											component={FormInput}
											name="wireLink.IntermediaryBankName"
											placeholder="Intermediary Bank Name"
											label="Intermediary Bank Name"
										/>
									</div>
									{WireTypeValue === 'Domestic' &&
										<div className={classes.inputItem}>
											<Field
												component={FormInput}
												name="wireLink.IntermediaryBankRoutingNumber"
												placeholder="Intermediary ABA/Routing Number"
												label="Intermediary ABA/Routing Number"
											/>
										</div>
									}
									{WireTypeValue === 'International' &&
										<div className={classes.inputItem}>
											<Field
												component={FormInput}
												name="wireLink.IntermediaryBankSwiftCode"
												placeholder="Intermediary Swift Code"
												label="Intermediary Swift Code"
											/>
										</div>
									}
									<div className={classes.inputItem}>
										<Field
											component={FormInput}
											name="wireLink.IntermediaryBankAccountNumber"
											placeholder="Intermediary Account Number"
											label="Intermediary Account Number"
										/>
									</div>
								</div>
							}
							<div className={classes.item}>
								<Field
									component={FormCheckbox}
									name="wireLink.IsOutsideCountryOfResidence"
									label="Bank is outside country of residence"
								/>
							</div>
							<div className={classes.attachFileText}>
								{isForeign ?
									'Please upload your bank statement' :
									'Please upload your bank statement or voided check'
								}
							</div>
							<div className={classes.fileUploader}>
								<FileInput
									onFilesUpdate={files => this.onFilesUpload('AttachmentsStatement', files)}
								/>
							</div>
							{RequireBankReference && (
								<React.Fragment>
									<div className={classes.attachFileText}>
										Please upload your bank reference
									</div>
									<div className={classes.fileUploader}>
										<FileInput
											onFilesUpdate={files => this.onFilesUpload('AttachmentsReference', files)}
										/>
									</div>
								</React.Fragment>
							)}
							{IsOutsideCountryOfResidenceValue && (
								<React.Fragment>
									<div className={classes.attachFileText}>
										Please upload explanation letter
									</div>
									<div className={classes.fileUploader}>
										<FileInput
											onFilesUpdate={files => this.onFilesUpload('AttachmentsExplanationLetter', files)}
										/>
									</div>
								</React.Fragment>
							)}
							<div className={classes.button}>
								<Button
									fullWidth
									type="submit"
									variant="contained"
									color="primary"
									disabled={noAttachments || invalid || submitting}
								>
									{submitting ?
										<CircularProgress
											style={{ color: blue[600] }}
											size={18}
										/>
										: 'Continue'
									}
								</Button>
							</div>
						</form>
					</div>
				</SignatureChecker>
			</TabContainer>

		);
	}
}

WireManuallyTabComponent.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	invalid: PropTypes.bool.isRequired,
	submitting: PropTypes.bool.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
	WireTypeValue: PropTypes.string,
	UseIntermediaryBankValue: PropTypes.bool,
	isForeign: PropTypes.bool.isRequired,
};

WireManuallyTabComponent.defaultProps = {
	WireTypeValue: 'Domestic',
	UseIntermediaryBankValue: false,
};

export default compose(
	withTheme,
	withStyles(styles),
	connect(mapStateToProps, null),
	reduxForm({
		form: ADD_WIRE_MANUALLY_FORM_ID,
		validate: WireManuallyTabComponent.validate,
	}),
)(WireManuallyTabComponent);
