import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { SvgIcon } from '@components';
import { env } from '@config';

const styles = theme => ({
	root: () => {
		return {
			...theme.customstyles.body,
			color: theme.palette.text.primary,
			position: 'fixed',
			width: '100%',
			height: '100%',
			top: '0',
			left: '0',
			zIndex: 1,
			overflowY: 'scroll',
		}
	},
	container: {
		padding: '0 24px',
		maxWidth: '496px',
		margin: '0 auto',
		position: 'relative',
		zIndex: 2001,
	},
	containerWrapper: {
		marginTop: '50px',
	},
	err: {
		display: 'flex',
		alignItems: 'center',
	},
	icon: {
		width: '72px',
		height: '72px',
		marginRight: '24px',
	},
	body: {
	},
});

class ErrorCatcher extends React.Component {
	constructor(props) {
		super(props);
		this.state = { error: null };
	}

	componentDidCatch(error, errorInfo) {
		this.setState({ error });
		Raven.captureException(error, { extra: errorInfo });
	}

	render() {
		const {
			classes,
		} = this.props;

		if (this.state.error) {
			// render fallback UI
			return (
				<div className={classes.root}>
					<div className={classes.containerWrapper}>
						<div className={classes.container}>
							<div className={classes.body}>
								<div className={classes.err}>
									<SvgIcon icon="Error" className={classes.icon} />
									<div>
										<p>
											We are sorry, something went wrong.
										</p>
										<p>
											We shared this error with our development team.
										</p>
										<p>
											Try one more time or contact <a href={`mailto:${env.supportMail}`}>{env.supportMail}</a> if you see this error again.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
		// when there's not an error, render children untouched
		return this.props.children;
	}
}

ErrorCatcher.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ErrorCatcher);
