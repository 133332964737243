import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { profile, userAccounts } from '@redux';
import { connect } from 'react-redux';
import {
	reduxForm,
	Field,
	change as reduxFormChange,
	formValueSelector,
} from 'redux-form';
import {
	Button,
	ErrorMessage,
	FileInput,
	FormInput,
	ProgressContainer,
	SuccessMessage,
	SelectField,
} from '@components';
import { ModalPopups } from '@enums';
import { RequireSignatureModal } from '../../..';

const styles = (theme) => ({
	root: {
		display: 'block',
		position: 'relative',
		marginBottom: '40px',
	},
	progress: {
		display: 'flex',
		justifyContent: 'center',
	},
	inputSelectItem: {
		marginTop: '10px',
		paddingLeft: '25px',
		background: theme.palette.background.paper,
		borderRadius: '8px'
	},
	fileUploader: {
		marginTop: '16px',
	},
	button: {
		paddingTop: '12px',
	},
	spanItem: {
		marginTop: '20px',
		marginBottom: '8px',
		textAlign: 'center',
	},
	header: {
		textAlign: 'center',
		margin: '0px',
	},
	inputItem: {
		'& div > div': {
			background: theme.palette.background.paper,
		},
	}
});

const CHANGE_PROFILE_ADDRESS_FORM = 'ChangeProfileAddressForm';
const formSelector = formValueSelector(CHANGE_PROFILE_ADDRESS_FORM);

const mapStateToProps = (state) => {
	const modalParams = profile.selectors.modals(state)[ModalPopups.CHANGE_PROFILE_ADDRESS];
	const userProfile = modalParams || profile.selectors.profile(state);
	const userInfo = userAccounts.selectors.userInfo(state);

	const userAddress = userProfile.PersonalInformation.Address;
	const country = userAddress && userAddress.Country || '';
	const stateAbbr = userAddress && userAddress.StateAbbr || '';

	return {
		initialValues: {
			clientId: userProfile.ClientId,
			guid: userProfile.Guid,
			Attachments: [],
			streetAddress: userAddress && userAddress.StreetAddress || '',
		    house: userAddress && userAddress.House || '',
			building: userAddress && userAddress.Building || '', 
			apartment: userAddress && userAddress.Apartment || '',
			city: userAddress && userAddress.City || '', 
			zipCode: userAddress && userAddress.ZipCode || '',
			country,
			stateAbbr,
		},
		initialAddressValue: profile.selectors.profile(state).PersonalInformation.FullAddress,
		addressValue: formSelector(state, 'Value'),
		attachmentsValue: formSelector(state, 'Attachments'),
		countries: profile.selectors.getCountries(state),
		usaStates: profile.selectors.getUsaStates(state),
		profileSubmitting: userProfile.submitting || userAccounts.selectors.loading(state),
		pendingOrdersAddress: userProfile.PendingOrders.Address,
		isForeign: userInfo.IsForeign,
		isSelfEdit: (!userProfile.Guid && !userProfile.ClientId)
			|| (userProfile.Guid && userInfo.Guid.toLowerCase() === userProfile.Guid.toLowerCase()),
		country,
		stateAbbr,
	};
};
const mapDispatchToProps = dispatch => ({
	actions: {
		refreshCountriesList: () => dispatch(profile.actions.getCountries.request()),
		refreshUsaStatesList: () => dispatch(profile.actions.getUsaStates.request()),
		setFormField: (fieldName, value = null) => dispatch(reduxFormChange(CHANGE_PROFILE_ADDRESS_FORM, fieldName, value)),
	},
});

class ChangeProfileAddressModal extends React.Component {
	static validate(values) {
		const errors = {};
		if (!values.country) {
			errors.country = 'required';
		}

		if (!values.streetAddress) {
			errors.streetAddress = 'required';
		}

		if (!values.city) {
			errors.city = 'required';
		}

		if (!values.stateAbbr && values.country === 'US') {
			errors.stateAbbr = 'required';
		}
		if (!values.zipCode) {
			errors.zipCode = 'required';
		}
		if (!values.Attachments || values.Attachments.length === 0) {
			errors.Attachments = 'required';
		}
		return errors;
	}
	constructor(props) {
		super(props);
		this.state = {
			country: props.country,
		};
	}
	componentDidMount() {
		const { countries, usaStates, actions } = this.props;
		if (!countries) {
			actions.refreshCountriesList();
		}
		if (!usaStates) {
			actions.refreshUsaStatesList();
		}
	}
	countryChanged = (event) => {
		const { actions } = this.props;
		const selectedCountry = event.target.value;
		this.setState({ country: selectedCountry });
		if (selectedCountry !== 'US') {
			actions.setFormField('stateAbbr', null);
		}
	}
	sortUsFirst = (a, b) => {
		if (a.CodeLat2 === 'US') {
			return -1;
		}
		if (b.CodeLat2 === 'US') {
			return 1;
		}
		if (a.Name === b.Name) {
			return 0;
		}
		return a.Name < b.Name ? -1 : 1;
	}

	closeForeign = () => {
		const { actions, onClose } = this.props;
		onClose();
	}

	render() {
		const {
			classes,
			onClose,
			handleSubmit,
			change,
			invalid,
			profileSubmitting,
			pendingOrdersAddress,
			submitting,
			submitSucceeded,
			addressValue,
			initialAddressValue,
			attachmentsValue,
			countries,
			usaStates,
			isForeign,
			isSelfEdit,
			country,
			stateAbbr,
		} = this.props;

		const noAttachments = (!attachmentsValue || attachmentsValue.length === 0);
		const isPristine = addressValue === initialAddressValue;

		return (
			<RequireSignatureModal
				title="Change Address"
				onClose={onClose}
				bgColor="#f9fcfd"
				textColor="#345464"
				className={`QA-${ModalPopups.CHANGE_PROFILE_ADDRESS}`}
			>
				{submitSucceeded &&
					(
						isForeign && isSelfEdit ?
							<SuccessMessage
								onClick={this.closeForeign}
								text="Your request to change address is processed. Please make sure your tax form is up to date with the changes."
								buttonText="Update tax form"
							/> :
							<SuccessMessage onClick={onClose} text="Your request to change address is processed." />
					)
				}
				{!submitSucceeded && !profileSubmitting && pendingOrdersAddress &&
					<ErrorMessage onClick={onClose} text="Another request for address change is in progress. Please try again later." />
				}
				{!submitSucceeded && !pendingOrdersAddress && (
					<ProgressContainer submitting={profileSubmitting}>
						<form
							onSubmit={handleSubmit(profile.actions.changeProfileAddressFormSubmitHandler)}
							className={classes.root}
						>
							<h2 className={classes.header}>Please enter your new address</h2>
							<div className={classes.inputSelectItem}>
								<SelectField
									label="Country"
									name="country"
									onChange={this.countryChanged}
									items={
										(countries && countries
											.sort(this.sortUsFirst)
											.map(cntr => ({ value: cntr.CodeLat2, text: cntr.Name }))) || []}
									value={country}
								/>
							</div>
							<div className={classes.inputItem}>
								<Field
									component={FormInput}
									name="streetAddress"
									placeholder="Street address"
								/>
							</div>
							<div className={classes.inputItem}>
								<Field
									component={FormInput}
									name="house"
									placeholder="House"
								/>
							</div>
							<div className={classes.inputItem}>
								<Field
									component={FormInput}
									name="building"
									placeholder="Building"
								/>
							</div>
							<div className={classes.inputItem}>
								<Field
									component={FormInput}
									name="apartment"
									placeholder="Apt/Suite"
								/>
							</div>
							<div className={classes.inputItem}>
								<Field
									component={FormInput}
									name="city"
									placeholder="City"
								/>
							</div>
							{this.state.country === 'US' &&
								<div className={classes.inputSelectItem}>
									<SelectField
										label="State"
										name="stateAbbr"
										items={
											(usaStates && usaStates.map(st => ({ value: st.Abbr, text: st.Name }))) || []}
										value={stateAbbr}
									/>
								</div>
							}
							<div className={classes.inputItem}>
								<Field
									component={FormInput}
									name="zipCode"
									placeholder="Zip Code"
								/>
							</div>


							<div className={classes.spanItem}>To proceed with a form, you have to attach a proof of your new address (a recent utility bill or a bank statement)</div>
							<div className={classes.fileUploader}>
								<FileInput
									onFilesUpdate={data => change('Attachments', data)}
								/>
							</div>
							<p>
								The document should contain a proof of your new address.
								It can be one the following documents:
							</p>
							<ul>
								<li>
									Utility, phone or cable bill with the address stated on it and the name matching
									to the name of the account holder, full copy not older than 90 days
								</li>
								<li>Credit Card bill or bank statement, not older than 90 days</li>
								<li>Lease Agreement for this address</li>
								<li>Driver License with the new addressbs-list-group</li>
							</ul>
							<div className={classes.button}>
								<Button
									fullWidth
									type="submit"
									variant="contained"
									color="primary"
									disabled={noAttachments || invalid || isPristine || submitting}
								>
									{submitting ?
										<CircularProgress
											
											size={18}
										/>
										: 'Change Address'
									}
								</Button>
							</div>
						</form>
					</ProgressContainer>
				)}
			</RequireSignatureModal>
		);
	}
}

ChangeProfileAddressModal.propTypes = {
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	change: PropTypes.func.isRequired,
	invalid: PropTypes.bool.isRequired,
	submitting: PropTypes.bool.isRequired,
	submitSucceeded: PropTypes.bool.isRequired,
	countries: PropTypes.array,
	usaStates: PropTypes.array,
	country: PropTypes.string.isRequired,
	stateAbbr: PropTypes.string.isRequired,
};
ChangeProfileAddressModal.defaultProps = {
	countries: undefined,
	usaStates: undefined,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: CHANGE_PROFILE_ADDRESS_FORM,
		validate: ChangeProfileAddressModal.validate,
	}),
)(ChangeProfileAddressModal);
