import React from 'react';
import PropTypes from 'prop-types';
import { R } from '@dev2t/react-ui-kit/src/components';

import { CYWithdrawalBankModal } from './components';

const CYWithdrawalNigerianBankModal = ({ onClose }) => (
	<CYWithdrawalBankModal
		title={<R id="orders_selectmethod_nigerian_bank_title" defaultValue="Nigerian Bank Account" />}
		onClose={onClose}
		currency="NGN"
		form="CYWithdrawalNigerianBankForm"
	/>
);

CYWithdrawalNigerianBankModal.propTypes = {
	onClose: PropTypes.func.isRequired,
};

export default CYWithdrawalNigerianBankModal;
