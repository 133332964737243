import React from 'react';
import compose from 'recompose/compose';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { InputPhoneNumber } from '@components';
import flagsIcon from './flags.png';
import phoneCssStyles from './flagLargeStyles';

const styles = {
	phoneInputContainer: {
		display: 'flex',
	},
	phoneInput: {
		width: '48px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginLeft: '24px',
	},
	phoneIcon: {
		backgroundImage: `url(${flagsIcon})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: '16px 11px',
		width: '24px',
		height: '17px',
		backgroundSize: '585px 537px',
		// width: '16px',
		// height: '11px',
	},
	...phoneCssStyles,
};

class InputPhoneIconNumber extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			countryCode: 'none',
		};

		this.onInputChange = this.onInputChange.bind(this);
	}

	onInputChange(match) {
		const newCode = (match && match.determined) ? match.obj.cc : 'none';
		if (newCode !== this.state.countryCode) {
			this.setState({	countryCode: newCode });
		}
		this.props.onPhoneChange(match);
	}

	render() {
		const {
			classes,
			onPhoneChange,
			...props
		} = this.props;

		return (
			<div className={classes.phoneInputContainer}>
				<div className={classes.phoneInput}>
					<span
						className={classNames({
							[classes.phoneIcon]: true,
							[classes[`uiFlag${this.state.countryCode}`]]: true,
						})}
					/>
				</div>
				<InputPhoneNumber
					onPhoneChange={this.onInputChange}
					{...props}
				/>
			</div>

		);
	}
}

InputPhoneIconNumber.propTypes = {
	classes: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	onPhoneChange: PropTypes.func.isRequired,
};

export default compose(withStyles(styles))(InputPhoneIconNumber);
