import React from 'react';
import { ContentCloud, ListItemContainer } from '@components';
import { userAccounts } from '@redux';
import { useAccount } from '@hooks';
import { AccountType } from '@enums';
import { useSelector } from 'react-redux';
import { Mt5GlobalAccountBalance } from './components';

export default function Mt5Global() {
	
	const mt5Accounts = useAccount(AccountType.MT5);
	const accountsInfo = useSelector(userAccounts.selectors.accountsInfo);
	const loading = useSelector(userAccounts.selectors.accountsInfoLoading);
	
	const tradeCodes = mt5Accounts
		.map(x => x.TradeCode);

	const firms = new Map(
		mt5Accounts.map(x => [x.TradeCode, x.ClearingFirm])
	);

	const logins = new Map(
		mt5Accounts.map(x => [x.TradeCode, x.DisplayFullName])
	);

	const balances = new Map(
		accountsInfo && accountsInfo
		.filter(x => tradeCodes.includes(x.TradeCode))
		.map(x => [x.TradeCode, x.Balance])
	);

	return (
		<>
			{tradeCodes?.map(tradeCode => (
				<ContentCloud 
					key={tradeCode}
					header={logins.get(tradeCode)}
					id={'ExpansionMT5' + tradeCode}
					submitting={loading} 
				>
					<Mt5GlobalAccountBalance 
						balance={balances.get(tradeCode)} 
						tradeCode={tradeCode} 
						clearerFirm={firms.get(tradeCode)} 
					/>
				</ContentCloud>
			))}
		</>
	);
}
