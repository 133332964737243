import { createFormAction } from 'redux-form-saga';
import { createApiActions } from '../extensions';

const actions = {
	// actions
	SET_CURRENT_ACCOUNT: 'USER_ACCOUNTS/SET_CURRENT_ACCOUNT',
	setCurrentAccount: account => ({
		type: actions.SET_CURRENT_ACCOUNT,
		payload: {
			account,
		},
	}),

	// set account data without request to the backend (used for lime international)
	SET_ACCOUNT_BASE_PARAMS: 'USER_ACCOUNTS/SET_ACCOUNT_BASE_PARAMS',
	setCurrentAccountBaseParams: currentAccountData => ({
		type: actions.SET_ACCOUNT_BASE_PARAMS,
		payload: { 
			response: currentAccountData
		},
	}),


	GET_CURRENT_ACCOUNT_BASE_DATA_REQUEST: 'USER_ACCOUNTS/GET_CURRENT_ACCOUNT_BASE_DATA_REQUEST',
	GET_CURRENT_ACCOUNT_BASE_DATA_SUCCESS: 'USER_ACCOUNTS/GET_CURRENT_ACCOUNT_BASE_DATA_SUCCESS',

	getCurrentAccountBaseDataRequest: payload => ({
		type: actions.GET_CURRENT_ACCOUNT_BASE_DATA_REQUEST,
		payload,
	}),

	getCurrentAccountBaseDataSuccess: () => ({
		type: actions.GET_CURRENT_ACCOUNT_BASE_DATA_SUCCESS,
	}),


	getUserInfo: createApiActions('USER_ACCOUNTS/GET_USER_INFO'),


	GET_ACCOUNTS_REQUEST: 'USER_ACCOUNTS/GET_ACCOUNTS_REQUEST',
	GET_ACCOUNTS_SUCCESS: 'USER_ACCOUNTS/GET_ACCOUNTS_SUCCESS',
	GET_ACCOUNTS_FAILURE: 'USER_ACCOUNTS/GET_ACCOUNTS_FAILURE',

	// action creators
	getAccountsRequest: () => ({
		type: actions.GET_ACCOUNTS_REQUEST,
	}),
	getAccountsSuccess: accounts => ({
		type: actions.GET_ACCOUNTS_SUCCESS,
		payload: {
			accounts,
		},
	}),
	getAccountsFailure: error => ({
		type: actions.GET_ACCOUNTS_FAILURE,
		payload: {
			error,
		},
	}),

	GET_ACCOUNT_INFO_REQUEST: 'USER_ACCOUNTS/GET_ACCOUNT_INFO_REQUEST',
	GET_ACCOUNT_INFO_SUCCESS: 'USER_ACCOUNTS/GET_ACCOUNT_INFO_SUCCESS',
	GET_ACCOUNT_INFO_FAILURE: 'USER_ACCOUNTS/GET_ACCOUNT_INFO_FAILURE',

	getAccountInfoRequest: account => ({
		type: actions.GET_ACCOUNT_INFO_REQUEST,
		payload: {
			account,
		},
	}),
	getAccountInfoSuccess: payload => ({
		type: actions.GET_ACCOUNT_INFO_SUCCESS,
		payload,
	}),
	getAccountInfoFailure: error => ({
		type: actions.GET_ACCOUNT_INFO_FAILURE,
		payload: {
			error,
		},
	}),

	getAllAccountsInfo: createApiActions('USER_ACCOUNTS/GET_ALL_ACCOUNTS_INFO'),

	getAccountPortfolio: createApiActions('USER_ACCOUNTS/GET_ACCOUNT_PORTFOLIO'),

	getTradeSystemCredentials: createApiActions('USER_ACCOUNTS/GET_TRADESYSTEM_CREDENTIALS'),

	REPORTS_AND_CONFIRMATIONS_REQUEST: 'USER_ACCOUNTS/REPORTS_AND_CONFIRMATIONS_REQUEST',
	REPORTS_AND_CONFIRMATIONS_SUCCESS: 'USER_ACCOUNTS/REPORTS_AND_CONFIRMATIONS_SUCCESS',
	REPORTS_AND_CONFIRMATIONS_FAILURE: 'USER_ACCOUNTS/REPORTS_AND_CONFIRMATIONS_FAILURE',

	reportsAndConfirmationsRequest: (account, orderCode) => ({
		type: actions.REPORTS_AND_CONFIRMATIONS_REQUEST,
		payload: {
			account,
			orderCode,
		},
	}),
	reportsAndConfirmationsSuccess: () => ({
		type: actions.REPORTS_AND_CONFIRMATIONS_SUCCESS,
	}),
	reportsAndConfirmationsFailure: error => ({
		type: actions.REPORTS_AND_CONFIRMATIONS_FAILURE,
		payload: {
			error,
		},
	}),

	REFRESH_REPORTS_AND_CONFIRMATIONS_REQUEST: 'USER_ACCOUNTS/REFRESH_REPORTS_AND_CONFIRMATIONS_REQUEST',
	REFRESH_REPORTS_AND_CONFIRMATIONS_SUCCESS: 'USER_ACCOUNTS/REFRESH_REPORTS_AND_CONFIRMATIONS_SUCCESS',
	REFRESH_REPORTS_AND_CONFIRMATIONS_FAILURE: 'USER_ACCOUNTS/REFRESH_REPORTS_AND_CONFIRMATIONS_FAILURE',

	refreshReportsAndConfirmationsRequest: account => ({
		type: actions.REFRESH_REPORTS_AND_CONFIRMATIONS_REQUEST,
		payload: {
			account,
		},
	}),
	refreshReportsAndConfirmationsSuccess: payload => ({
		type: actions.REFRESH_REPORTS_AND_CONFIRMATIONS_SUCCESS,
		payload,
	}),
	refreshReportsAndConfirmationsFailure: error => ({
		type: actions.REFRESH_REPORTS_AND_CONFIRMATIONS_FAILURE,
		payload: {
			error,
		},
	}),

	OPEN_REPORTS_PAGE: 'USER_ACCOUNTS/OPEN_REPORTS_PAGE',
	openReportsPage: ({ page, account }) => ({
		type: actions.OPEN_REPORTS_PAGE,
		payload: {
			page,
			account,
		},
	}),

	CLEAR_TRANSACTIONS_HISTORY: 'USER_ACCOUNTS/CLEAR_TRANSACTIONS_HISTORY',

	clearTransastionsHistory: () => ({
		type: actions.CLEAR_TRANSACTIONS_HISTORY
	}),

	getTransactionsHistory: createApiActions('USER_ACCOUNTS/GET_TRANSACTIONS_HISTORY'),
	
	getMoneyTransactionsHistory: createApiActions('USER_ACCOUNTS/GET_MONEY_TRANSACTIONS_HISTORY'),

	getNonTradingOrdersHistory: createApiActions('USER_ACCOUNTS/GET_NON_TRADING_ORDERS_HISTORY'),

	getAccountCharts: createApiActions('USER_ACCOUNTS/GET_ACCOUNT_CHARTS'),

	cancelOrder: createApiActions('USER_ACCOUNTS/CANCEL_ORDER'),

	getExchangeRate: createApiActions('USER_ACCOUNTS/GET_EXCHANGE_RATE'),
	activateAccount: createApiActions('USER_ACCOUNTS/ACTIVATE_ACCOUNT'),

	getLimeTraderAccountInfo: createApiActions('USER_ACCOUNTS/GET_LIME_TRADER_ACCOUNT_INFO'),

	changeMt5Password: createFormAction('USER_ACCOUNTS/CHANGE_MT5_PASSWORD'),

	changeMt4Password: createFormAction('USER_ACCOUNTS/CHANGE_MT4_PASSWORD'),

	addDemoAccount: createApiActions('USER_ACCOUNTS/ADD_DEMO_ACCOUNT'),
	resetDemoAccount: createApiActions('USER_ACCOUNTS/RESET_DEMO_ACCOUNT'),

	SHOW_REPORTS_MODAL: 'USER_ACCOUNTS/SHOW_REPORTS_MODAL',
	showReportsModal: payload => ({
		type: actions.SHOW_REPORTS_MODAL,
		payload,
	}),

	SHOW_J2TX_MT5_WITHDRAWAL_MODAL: 'USER_ACCOUNTS/SHOW_J2TX_MT5_WITHDRAWAL_MODAL',
	showJ2txMt5WithdrawalModal: payload => ({
		type: actions.SHOW_J2TX_MT5_WITHDRAWAL_MODAL,
		payload,
	}),

	SHOW_J2TX_CHANGE_CURRENCY_MODAL: 'USER_ACCOUNTS/SHOW_J2TX_CHANGE_CURRENCY_MODAL',
	showJ2txChangeCurrencyModal: payload => ({
		type: actions.SHOW_J2TX_CHANGE_CURRENCY_MODAL,
		payload,
	}),

	HIDE_USERACCOUNTS_MODAL: 'USER_ACCOUNTS/HIDE_USERACCOUNTS_MODAL',
	hideUserAccountsModal: modalId => ({
		type: actions.HIDE_USERACCOUNTS_MODAL,
		payload: modalId,
	}),

	SHOW_TRANSFER_WALLET_TO_MT5_MODAL: 'USER_ACCOUNTS/SHOW_TRANSFER_WALLET_TO_MT5_MODAL',
	showTransferWalletToMt5Modal: payload => ({
		type: actions.SHOW_TRANSFER_WALLET_TO_MT5_MODAL,
		payload,
	}),

	SHOW_TRANSFER_MT5_TO_WALLET_MODAL: 'USER_ACCOUNTS/SHOW_TRANSFER_MT5_TO_WALLET_MODAL',
	showTransferMt5ToWalletModal: payload => ({
		type: actions.SHOW_TRANSFER_MT5_TO_WALLET_MODAL,
		payload,
	}),

	
	SHOW_TRANSFER_WALLET_TO_STAKING_MODAL: 'USER_ACCOUNTS/SHOW_TRANSFER_WALLET_TO_STAKING_MODAL',
	showTransferWalletToStakingModal: payload => ({
		type: actions.SHOW_TRANSFER_WALLET_TO_STAKING_MODAL,
		payload,
	}),

	
	SHOW_TRANSFER_STAKING_TO_WALLET_MODAL: 'USER_ACCOUNTS/SHOW_TRANSFER_STAKING_TO_WALLET_MODAL',
	showTransferStakingToWalletModal: payload => ({
		type: actions.SHOW_TRANSFER_STAKING_TO_WALLET_MODAL,
		payload,
	}),

	SHOW_J2TX_WALLET_WITHDRAWAL_MODAL: 'USER_ACCOUNTS/SHOW_J2TX_WALLET_WITHDRAWAL_MODAL',
	showJ2txWithdrawalModal: payload => ({
		type: actions.SHOW_J2TX_WALLET_WITHDRAWAL_MODAL,
		payload,
	}),
};

export default actions;
