import { put, fork, takeEvery, call, select } from 'redux-saga/effects';
import { getCaptcha, validateCaptcha as validateCaptchaApi } from '@api';
import { BaseGetSagaHandler } from '@redux';

import actions from './actions';
import selectors from './selectors';

function* resetCaptchaRequestSagaHandler() {
    yield BaseGetSagaHandler({
        apiMethod: getCaptcha,
        errorText: 'Captcha reset error',
        handler: actions.reset,
    }, {})
}

function* validateCaptchaRequestSagaHandler() {
    const id = yield select(selectors.id);
    const response = yield select(selectors.value);

    yield BaseGetSagaHandler({
        apiMethod: validateCaptchaApi,
        errorText: 'Captcha validation error',
        handler: actions.validate,
    }, { payload: { id, response } });
}

function* checkValidationResult({ payload: { response: { IsValid } }}) {
    if (IsValid) {
        yield put(actions.setSuccess());
    } else {
        yield put(actions.reset.request());
    }
}

// Watchers
function* watchResetCaptchaRequest() {
	yield takeEvery(actions.reset.REQUEST, resetCaptchaRequestSagaHandler);
}

function* watchValidateCaptchaSuccess() {
    yield takeEvery(actions.validate.SUCCESS, checkValidationResult);
}

function* watchValidateCaptchaFailure() {
    yield takeEvery(actions.validate.FAILURE, resetCaptchaRequestSagaHandler);
}

function* watchValidateCaptchaRequest() {
	yield takeEvery(actions.validate.REQUEST, validateCaptchaRequestSagaHandler);
}

// Root saga
const rootSaga = [
	fork(watchResetCaptchaRequest),
	fork(watchValidateCaptchaRequest),
	fork(watchValidateCaptchaSuccess),
	fork(watchValidateCaptchaFailure),
];

export default {
	rootSaga,
};
