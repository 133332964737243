import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getIntrnationalBanksRequisites } from '@api';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	reduxForm,
	Field,
	formValueSelector,
} from 'redux-form';
import {
	TabContainer,
	FileInput,
	FormAutocompleteInput,
	FormCheckbox,
	FormInput,
	FormRadioGroup,
	Radio,
	Button,
	withTranslate,
} from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import { cabinetLog, captureError } from '@global';
import { userAccounts } from '@redux';

const getPromiseWithFunc = func => query => new Promise((resolve, reject) => {
	func(query).then((response) => {
		if (response.data.Errors && response.data.Errors[0] && response.data.Errors[0].Code === 'NeedReCall') {
			resolve([]);
			return;
		}
		cabinetLog(func.name || 'respcall', response);
		if (response.data.Errors && response.data.Errors[0] && response.data.Errors[0].Code === 'Unauthorized') {
			resolve([]);
			return;
		}
		if (Array.isArray(response.data)) {
			resolve(response.data);
			return;
		}
		resolve([]);
		window.Raven.captureMessage('response.data is not array', { extra: { text: response.data.toString().substr(0, 200) } });
	}, (error) => {
		captureError(error);
		resolve([]);
	});
});

const styles = theme => ({
	root: {
		position: 'relative',
	},
	row: {
		display: 'flex',
	},
	inputItem: {
		marginTop: '30px',
		paddingLeft: '50px',
		[theme.breakpoints.down('xs')]: {
			paddingLeft: '20px',
		},
	},
	item: {
		marginTop: '30px',
		paddingLeft: '74px',
		[theme.breakpoints.down('xs')]: {
			paddingLeft: '44px',
		},
	},
	divider: {
		marginTop: '30px',
	},
	bankAccountContainer: {
		marginTop: '-15px',
	},
	accountContainer: {
	},
	logo: {
		padding: '16px 16px 4px 16px',
		position: 'relative',
		width: '72px',
		height: '60px',
	},
	arrow: {
		paddingLeft: '24px',
		height: '36px',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.customstyles.title,
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	accountBalance: {
		width: '100%',
		textAlign: 'right',
	},
	warning: {
		padding: '12px 16px',
		textAlign: 'justify',
		fontSize: '12px',
		lineHeight: '20px',
	},
	button: {
		...theme.mixins.stickyButton,
	},
	attachFileText: {
		...theme.customstyles.body,
		color: theme.palette.text.primary,
		marginTop: '24px',
	},
	fileUploader: {
		marginTop: '16px',
	},
	preFieldLabel: {
		margin: '10px 24px 0px 0px',
		lineHeight: '21px',
		fontSize: '14px',
		color: theme.palette.text.secondary,
	}
});

const CY_BANK_LINKING_FORM_ID = 'cyBankTransferFromId';

const AddInputBox = (props) => (
	<div className={props.classes.inputItem}>
		<Field
			component={FormInput}
			name={props.name}
			label={props.title}
			placeholder={props.title}
			additionalText={props.additional}
		/>
	</div>
);


const AddAutoCompleteBox = (props) => {
	
	let autocompleteProps = {
		getOptionSelected: (option, value) => option.name === value.name,
		renderOption: (option, state) => (<React.Fragment><div><div>{option.name}</div><div>{option.swift}</div></div></React.Fragment>),
		filterOptions: (options, state) => options.filter(x =>
			x.name.toLowerCase().indexOf(state.inputValue.toLowerCase()) >= 0),
		getOptionLabel: option => option.name,
		onChange: (newSelectedOption) => {
			if (newSelectedOption) {
				props.change(props.additionalName, newSelectedOption.swift);
			}
		},
	};

	if (props.isSwift) {
		autocompleteProps = {
			getOptionSelected: (option, value) => option.swift === value.swift,
			renderOption: (option, state) => (<React.Fragment><div><div>{option.name}</div><div>{option.swift}</div></div></React.Fragment>),
			filterOptions: (options, state) => options.filter(x =>
				x.swift.toLowerCase().indexOf(state.inputValue.toLowerCase()) === 0),
			getOptionLabel: option => option.swift,
			onChange: (newSelectedOption) => {
				if (newSelectedOption) {
					props.change(props.additionalName, newSelectedOption.name);
				}
			},
		};
	}

	return (
		<div className={props.classes.inputItem}>
			<Field
				component={FormAutocompleteInput}
				name={props.name}
				label={props.title}
				autocompleteQuery={{
					minQueryLength: 3,
					maxQueryLength: 30,
					getOptions: getPromiseWithFunc(getIntrnationalBanksRequisites),
				}}
				autocompleteProps={autocompleteProps}
			/>
		</div>
	)
};

const mapStateToProps = (state, ownProps) => {
	const formSelector = formValueSelector(ownProps.form || CY_BANK_LINKING_FORM_ID);
	const currentTradeAccount = userAccounts.selectors.currentTradeAccount(state);
	const userInfo = userAccounts.selectors.userInfo(state);
	return {
		initialValues: {
			wireLink: {
				Currency: 'USD',
				TradeCode: currentTradeAccount.tradeCode,
				ClearingFirm: currentTradeAccount.clearerFirm,
				UseIntermediaryBank: false,
			},
		},
		currentTradeAccount,
		UseIntermediaryBankValue: formSelector(state, 'wireLink.UseIntermediaryBank'),
		BankAccountCurrencyValue: formSelector(state, 'wireLink.Currency'),
		currentAccount: userAccounts.selectors.currentAccount(state),
		currentClearer: userAccounts.selectors.currentClearer(state),
	};
};

class CYWithdrawalBankTransferForm extends React.Component {
	static validate(formvalues, props) {
		const values = formvalues.wireLink;
		const errors = {};
		if (!values) {
			return errors;
		}

		if (!values.Currency) {
			errors.Currency = 'required';
		}
		if (!values.PaymentRecipient) {
			errors.PaymentRecipient = 'required';
		}
		if (!values.RecipientAccount) {
			errors.RecipientAccount = 'required';
		}
		if (!values.RecipientBank) {
			errors.RecipientBank = 'required';
		}
		if (!values.RecipientBankBIK) {
			errors.RecipientBankBIK = 'required';
		}
		if (!values.RecipientBankINN) {
			errors.RecipientBankINN = 'required';
		}
		if (!values.RecipientBankSwift) {
			errors.RecipientBankSwift = 'required';
		}
		if (!values.RecipientBankAddress) {
			errors.RecipientBankAddress = 'required';
		}
		if (!values.RecipientBankCorAccount) {
			errors.RecipientBankCorAccount = 'required';
		}
		if (!values.BankCorrespondent) {
			errors.BankCorrespondent = 'required';
		}
		if (!values.BankCorrespondentSwift) {
			errors.BankCorrespondentSwift = 'required';
		}

		if (values.UseIntermediaryBank) {

			if (!values.IntermediaryBank) {
				errors.IntermediaryBank = 'required';
			}
			if (!values.IntermediaryBankSwift) {
				errors.IntermediaryBankSwift = 'required';
			}
			if (!values.IntermediaryBankAddress) {
				errors.IntermediaryBankAddress = 'required';
			}
		}

		if (!values.ConfirmAccountOwner) {
			errors.ConfirmAccountOwner = 'required';
		}
		if (!values.ConfirmDataCorrect) {
			errors.ConfirmDataCorrect = 'required';
		}
		if (!values.ConfirmReturnToCard) {
			errors.ConfirmReturnToCard = 'required';
		}

		return { wireLink: errors };
	}

	render() {
		const {
			classes,
			theme,
			handleSubmit,
			change,
			invalid,
			submitting,
			currentTradeAccount,
			BankAccountCurrencyValue,
			UseIntermediaryBankValue,
			translate,
		} = this.props;

		

		return (
			<TabContainer>
				<div>
					<p>
						<R
							id="common_enter_your_bank_account_credentials_to_link_the_account"
							defaultValue="Enter your bank account credentials to link the account."
						/>
					</p>
					<form
						onSubmit={handleSubmit}
						className={classes.root}
					>
						<div className={classes.item}>
							<p className={classes.preFieldLabel}>
								<R id="common_moneyout_currency_copy"defaultValue="Currency of your bank account" />
							</p>
							<Field
								name="wireLink.Currency"
								orientation="horizontal"
								component={FormRadioGroup}
							>
								<Radio value="USD" label="USD" />
								<Radio value="EUR" label="EUR" />
								<Radio value="CNY" label="CNY" />
								<Radio value="RUB" label="RUB" />
							</Field>
						</div>

						<AddInputBox
							classes={classes}
							name="wireLink.PaymentRecipient"
							title={<R id="common_moneyout_payment_recipient_copy" defaultValue="Recipient of payment" />}
							additional={<R id="common_orders_help_payment_recipient_ru_copy" defaultValue="Your full name as indicated in the Banking Agreement" />}
						/>
						<AddInputBox
							classes={classes}
							name="wireLink.RecipientAccount"
							title={<R id="common_beneficiary's_account" defaultValue="Beneficiary's account (or IBAN)" />}
						/>
						
						{BankAccountCurrencyValue === 'RUB' &&
							<React.Fragment>
								<AddInputBox
									classes={classes}
									name="wireLink.RecipientBank"
									title={<R id="common_moneyout_recipient_bank_copy" defaultValue="Beneficiary bank" />}
								/>
								<AddInputBox
									classes={classes}
									name="wireLink.RecipientBankBIK"
									title={<R id="common_moneyout_recipient_bank_bik_copy" defaultValue="Bank's RCBIC" />}
								/>
							</React.Fragment>
						}
						{BankAccountCurrencyValue !== 'RUB' &&
							<React.Fragment>
								<AddAutoCompleteBox
									classes={classes}
									change={change}
									name="wireLink.RecipientBank"
									additionalName="wireLink.RecipientBankSwift"
									title={<R id="common_moneyout_recipient_bank_copy" defaultValue="Beneficiary bank" />}
								/>
								<AddAutoCompleteBox
									classes={classes}
									change={change}
									name="wireLink.RecipientBankSwift"
									additionalName="wireLink.RecipientBank"
									isSwift
									title={<R id="common_moneyout_recipient_bank_swift_copy" defaultValue="Beneficiary bank SWIFT" />}
								/>
								<AddInputBox
									classes={classes}
									name="wireLink.RecipientBankAddress"
									title={<R id="common_moneyout_recipient_bank_address_copy" defaultValue="Beneficiary bank address" />}
								/>
							</React.Fragment>
						}
						<AddInputBox
							classes={classes}
							name="wireLink.RecipientBankCorAccount"
							title={<R id="common_moneyout_recipient_bank_cor_account_copy" defaultValue="Bank's Correspondent account" />}
						/>

						{BankAccountCurrencyValue === 'RUB' &&
							<AddInputBox
								classes={classes}
								name="wireLink.RecipientBankINN"
								title={<R id="common_moneyout_recipient_bank_inn" defaultValue="Bank's TIN" />}
							/>
						}

						{BankAccountCurrencyValue !== 'RUB' &&
							<React.Fragment>
								<AddAutoCompleteBox
									classes={classes}
									change={change}
									name="wireLink.BankCorrespondent"
									additionalName="wireLink.BankCorrespondentSwift"
									title={<R id="common_moneyout_bank_correspondent_copy" defaultValue="Correspondent bank" />}
								/>
								<AddAutoCompleteBox
									classes={classes}
									change={change}
									name="wireLink.BankCorrespondentSwift"
									additionalName="wireLink.BankCorrespondent"
									isSwift
									title={<R id="common_moneyout_bank_correspondent_swift_copy" defaultValue="Correspondent bank SWIFT" />}
								/>

								<div className={classes.item}>
									<Field
										component={FormCheckbox}
										name="wireLink.UseIntermediaryBank"
										label={translate('common_use-Intermediary-bank', 'Use Intermediary Bank')}
									/>
								</div>
							</React.Fragment>
						}
						{UseIntermediaryBankValue &&
							<div>
								<AddAutoCompleteBox
									classes={classes}
									change={change}
									name="wireLink.IntermediaryBank"
									additionalName="wireLink.IntermediaryBankSwift"
									title={<R id="common_moneyout_intermediary_bank_copy" defaultValue="Intermediary bank" />}
								/>
								<AddAutoCompleteBox
									classes={classes}
									change={change}
									name="wireLink.IntermediaryBankSwift"
									additionalName="wireLink.IntermediaryBank"
									isSwift
									title={<R id="common_moneyout_intermediary_bank_swift_copy" defaultValue="Intermediary bank SWIFT" />}
								/>
								<AddInputBox classes={classes} name="wireLink.IntermediaryBankAddress" title={<R id="common_moneyout_intermediary_bank_address" defaultValue="Intermediary bank address" />} />
							</div>
						}
						<div className={classes.item}>
							<Field
								component={FormCheckbox}
								name="wireLink.ConfirmAccountOwner"
								label={<R
									id="common_confirm_account_owner"
									enableHtml
									defaultValue="I confirm that the monetary funds shall be transferred to <u>my personal bank account."
								/>}
							/>
						</div>
						<div className={classes.item}>
							<Field
								component={FormCheckbox}
								name="wireLink.ConfirmDataCorrect"
								label={translate(
									'common_moneyout_confirm_data_correct',
									'I confirm that all the details specified in the order are true.'
								)}
							/>
						</div>
						<div className={classes.item}>
							<Field
								component={FormCheckbox}
								name="wireLink.ConfirmReturnToCard"
								label={
									<R
										id="common_orders_moneyout_confirm_return_to_banks_card_copy"
										defaultValue="I am notified that the funds, transferred to my brokerage account from a card, may be withdrawn only by returning to the same bank card and I request execution of this withdrawal application in accordance with this rule."
									/>}
							/>
						</div>
						<div className={classes.button}>
							<Button
								fullWidth
								type="submit"
								variant="contained"
								color="primary"
								disabled={invalid || submitting}
							>
								{submitting ?
									<CircularProgress
										style={{ color: blue[600] }}
										size={18}
									/>
									: <R id="common_btn_continue" defaultValue="Continue" />
								}
							</Button>
						</div>
					</form>
				</div>
			</TabContainer>

		);
	}
}

CYWithdrawalBankTransferForm.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	invalid: PropTypes.bool.isRequired,
	submitting: PropTypes.bool.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
	UseIntermediaryBankValue: PropTypes.bool,
};

CYWithdrawalBankTransferForm.defaultProps = {
	UseIntermediaryBankValue: false,
};

export default compose(
	withTheme,
	withStyles(styles),
	withTranslate,
	connect(mapStateToProps, null),
	reduxForm({
		form: CY_BANK_LINKING_FORM_ID,
		validate: CYWithdrawalBankTransferForm.validate,
		// onSubmit: linkedAccounts.actions.wireManualLinkingForm,
	}),
)(CYWithdrawalBankTransferForm);
